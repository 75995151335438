import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import LoadingBox from "../../Components/LoadingBox";
import { Table } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
function DetailsModal(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    setData([props.item]);
  };

  return (
    <Modal
      visible={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={900}
      footer={false}
    >
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <br />
          <br />
          <Table className="formTable" borderless>
            <thead>
              <tr>
                <th
                  style={{ backgroundColor: "#1e88e5", color: "#fff" }}
                  colSpan={2}
                  className="box-details-txt1"
                >
                  BOXES DETAILS
                </th>
              </tr>
            </thead>
            <tbody className="box-details-txt2">
              {data.map((item) => {
                return (
                  <>
                    <tr>
                      <td>Box Number</td>
                      <th>{item.box_number}</th>
                    </tr>

                    <tr>
                      <td>Iqama Number</td>
                      <th>{item.iqama_number}</th>
                    </tr>

                    <tr>
                      <td>Iqama Name</td>
                      <th>{item.iqama_name}</th>
                    </tr>

                    <tr>
                      <td>Mobile Number</td>
                      <th>{item.sender_mobile}</th>
                    </tr>

                    <tr>
                      <td>Adress</td>
                      <th>{item.address}</th>
                    </tr>

                    <tr>
                      <td>Adhar Number</td>
                      <th>{item.adhar_no}</th>
                    </tr>

                    <tr>
                      <td>Adhar Name</td>
                      <th>{item.adhar_name}</th>
                    </tr>

                    <tr>
                      <td>Adhar Adress</td>
                      <th>{item.adhar_address}</th>
                    </tr>

                    <tr>
                      <td>Mobile number</td>
                      <th>{item.reciver_mobile1}</th>
                    </tr>

                    <tr>
                      <td>Pincode</td>
                      <th>{item.pin}</th>
                    </tr>

                    <tr>
                      <td>Pincode</td>
                      <th>{item.pin}</th>
                    </tr>

                    <tr>
                      <td>District</td>
                      <th>{item.district}</th>
                    </tr>

                    <tr>
                      <td>State</td>
                      <th>{item.state}</th>
                    </tr>

                    <tr>
                      <td>Country</td>
                      <th>{item.country}</th>
                    </tr>

                    <tr>
                      <td>created At</td>
                      <th>{moment(item.createdAt).format("DD-MM-YYYY")}</th>
                    </tr>
                    <tr>
                      <td>updated At</td>
                      <th>{moment(item.updatedAt).format("DD-MM-YYYY")}</th>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </Modal>
  );
}
export default DetailsModal;
