import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { Button, Popover, Empty } from "antd";
import ReactToPrint from "react-to-print";
import { GrDocumentExcel } from "react-icons/gr";
import moment from "moment";
import ExportReport from "./ExportReport";

function ReportTable(props) {
  const ref = useRef();
  return (
    <div>
      <div ref={ref} className="p-2">
        <Table className="formTable" bordered>
          <thead style={{ backgroundColor: "#e2e2e2" }}>
            <tr>
              <td colSpan={5}>
                <div className="Report-table-txt1">
                  Report{" "}
                  {`from ${moment(props.obj.date_from).format("l")} to ${moment(
                    props.obj.date_to
                  ).format("l")}`}
                </div>
              </td>
              <td colSpan={1}>
                <div className="Reports-Datatable-box1">
                  <ReactToPrint
                    trigger={() => <Button type="primary">Print</Button>}
                    content={() => ref.current}
                  />
                  <Popover
                    trigger="click"
                    placement="bottom"
                    content={
                      <div>
                        <ExportReport data={props.data} />
                      </div>
                    }
                  >
                    <Button type="link">
                      <GrDocumentExcel size={20} />
                    </Button>
                  </Popover>
                </div>
              </td>
            </tr>
          </thead>
          <tbody className="Reports-Datatable-items">
            <tr>
              <td>Truck No</td>
              <td>From Branch</td>
              <td>To Branch</td>
              <td>Loaded Time</td>
              <td>Deportedtime</td>
              <td>Expected vehicle charge</td>
            </tr>
            {props.data && props.data.length ? (
              props.data.map((item, index) => {
                console.log("item  ===>", item);
                return (
                  <tr key={index}>
                    <td>{item.truck_no}</td>
                    <td>
                      {item.loggin_branch.name && item.loggin_branch.name}
                    </td>
                    <td>{item.to_branch.name && item.to_branch.name}</td>
                    <td>
                      {moment(item.loadded_time).format(
                        "DD - MM - YYYY HH : MM"
                      )}
                    </td>
                    <td>
                      {moment(item.deported_time).format(
                        "DD - MM - YYYY HH : MM"
                      )}
                    </td>
                    <td>{item.expected_vehicle_charge}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ReportTable;
