import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
function Pincode() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});
  const [countries, setcountries] = useState([]);

  useEffect(() => {
    loaddata(page, take);
    ContriesDropdown();
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(
        API.BASR_URL + API.PINCODE_LIST + `?order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const ContriesDropdown = () => {
    axios
      .get(API.BASR_URL + API.COUNTRIES_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) {
          setcountries(res.data);
        }
      })
      .catch(function (error) {
        message.error("Contries dropdown faild");
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const DeleteItem = (item) => {
    setIndicator(true);
    axios
      .delete(API.BASR_URL + API.PINCODE_ACTIONS + item.id, {
        headers: Headers(),
      })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <div>
      <PageHeader
        PageName={"Pincode"}
        breadScrum={"Admin / Pincode"}
        RightButton={"Add Pincode "}
        RightClcik={() => AddItem()}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            meta={meta}
            data={data}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
          {form ? (
            <FormModal
              item={item}
              mode={mode}
              visible={form}
              countries={countries}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default Pincode;
