import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  Switch,
  message,
  Select,
  notification,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import ExchangeRates from "./ExchangeRate.json";

var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
const { Option } = Select;

function FormModal(props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  console.log("++++++ ", props?.mode);

  useEffect(() => {
    if (props?.mode === "put") {
      form.setFieldsValue({
        from_date: props.item.from_date
          ? dayjs(props.item.from_date, "YYYY/MM/DD")
          : null,
        to_date: props.item.to_date
          ? dayjs(props.item.to_date, "YYYY/MM/DD")
          : null,
        sar_usd: props.item && props.item.sar_usd,
        sar_inr: props.item && props.item.sar_inr,
        inr_sar: props.item && props.item.inr_sar,
      });
    }
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      from_date: values.from_date,
      to_date: values.to_date,
      sar_usd: Number(values.sar_usd),
      sar_inr: Number(values.sar_inr),
      inr_sar: Number(values.inr_sar),
    };
    let URL =
      props.mode === "post"
        ? API.EXCHANGE_RATE
        : API.EXCHANGE_RATE + props.item.id;

    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if (response?.data?.status) {
          message.success("Success");
          props.onchange();
        } else {
          message.error(response?.data?.message);
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
        error.response.data.message &&
          error?.response?.data?.message.map((item) => {
            notification.open({
              message: "ERROR",
              description: `${item}`,
            });
          });
      });
  };

  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Exchange Rate
        </span>
      }
      width={innerwidth ? width : width / 2.5}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} layout="vertical" hideRequiredMark onFinish={onFinish}>
        <Container>
          <Row>
            <Col sm="12" xs="12">
              <Form.Item
                name="from_date"
                label="From date"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select Date"
                />
              </Form.Item>
            </Col>

            <Col sm="12" xs="12">
              <Form.Item
                name="to_date"
                label="To Date"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select Date"
                />
              </Form.Item>
            </Col>

            <Col sm="12" xs="12">
              <Form.Item
                name="sar_usd"
                label="SAR - USD"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter rate  " />
              </Form.Item>
            </Col>

            <Col sm="12" xs="12">
              <Form.Item
                name="sar_inr"
                label="SAR - INR"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter rate  " />
              </Form.Item>
            </Col>

            <Col sm="12" xs="12">
              <Form.Item
                name="inr_sar"
                label="INR - SAR"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter rate  " />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col sm="6" xs="12">
                <Button
                  style={{ width: "100%" }}
                  onClick={() => props.onClose()}
                  block
                >
                  Cancel
                </Button>
              </Col>

              <Col md="6" xs="12">
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;
