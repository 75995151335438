import { Table, Tooltip } from "antd";
import React, { useState } from "react";

const RateTable = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "Pieces",
      dataIndex: "pieces",
      key: "pieces",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Gross w.",
      dataIndex: "gross_weight",
      key: "gross_weight",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "K/L",
      dataIndex: "unit",
      key: "unit",

      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">
              {text ? text : "K"}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "rc",
      dataIndex: "rate_class",
      key: "rate_class",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">
              {text ? text : "Q"}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Item no.",
      dataIndex: "item_no",
      key: "item_no",
      render: (text, record, index) => (
        <div onClick={() => onSelectChange([record.id], [record])}>{text}</div>
      ),
    },
    {
      title: "Charge. weight",
      dataIndex: "chargeable_weight",
      key: "chargeable_weight",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Rate/Charge",
      dataIndex: "rate_charge",
      key: "rate_charge",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Nature and quantity",
      dataIndex: "nature_quantity",
      key: "nature_quantity",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-ratedesc-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-ratedesc-table-item">
              {record.nature_quantity && record.nature_quantity}
            </div>
            <div className="airwaybill-ratedesc-table-item">
              {record.dimensions &&
                record.dimensions.map((item) => {
                  return (
                    <div>{`${item.Length && item.Length} / ${
                      item.Width && item.Width
                    } / ${item.Height && item.Height} ${
                      item.unit1 && item.unit1 ? item.unit1 : "CM"
                    }  / ${item.Pieces && item.Pieces} `}</div>
                  );
                })}
            </div>
          </div>
        </Tooltip>
      ),
      width: 250,
    },
  ];
  return (
    <div>
      <Table
        rowKey="id"
        size="small"
        rowSelection={{ type: "radio", ...rowSelection }}
        columns={columns}
        dataSource={props.data}
        pagination={false}
        rowClassName={(record, index) =>
          record.id === selectedRowKeys[0]
            ? "DataTable-row-light"
            : "DataTable-row-dark"
        }
      />
      <br />
    </div>
  );
};

export default RateTable;
