import React, { useState, useRef } from "react";
import { Drawer, Form, Button, Input, InputNumber, message } from "antd";
import { Table } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
function FormModal(props) {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);

  const [trheegits, settrheegits] = useState(0);
  const [first_checkdit, setfirstcheckdit] = useState(0);
  const [last_checkdit, setlast_checkdit] = useState(0);
  const [tobillno, settobillno] = useState(0);
  const [currentSave, setCurrentSave] = useState(null);
  const [numbers, setnumbers] = useState([]);

  const airwaybillonchange = (_, values) => {
    let arr = [];
    let count = Number(values.count);
    let first_checkdit = Number(values.billno);

    if (values.prefix) settrheegits(values.prefix);
    if (first_checkdit.toString().length === 7) {
      setfirstcheckdit(first_checkdit % 7);
      if (values.count && values.billno) {
        settobillno(Number(values.count) + Number(values.billno) - 1);
        let last_checkdit = Number(
          Number(values.count) + Number(values.billno) - 1
        );
        setlast_checkdit(last_checkdit % 7);
      }
    }
  };

  const Resset = () => {
    settrheegits(0);
    setfirstcheckdit(0);
    setlast_checkdit(0);
    settobillno(0);
    setnumbers([]);
  };

  const airwaybill = (values) => {
    let arr = [];
    let count = Number(values.count);
    let first_checkdit = Number(values.billno);
    if (values.prefix) settrheegits(values.prefix);
    if (first_checkdit.toString().length === 7) {
      setfirstcheckdit(first_checkdit % 7);
      if (count.toString().length) {
        for (let index = 0; index < count; index++) {
          let billno = Number(first_checkdit) + Number(index);
          let checkdigit = Number(billno) % 7;
          let obj = {
            id: index,
            prefix: values.prefix,
            billno: billno,
            checkdigit: checkdigit,
            numbers:
              values.prefix.toString() +
              billno.toString() +
              checkdigit.toString(),
          };
          arr.push(obj);
        }
        setnumbers(arr);
        let tobillno = arr[count - 1].billno;
        let last_checkdit = Number(tobillno) % 7;
        settobillno(tobillno);
        setlast_checkdit(last_checkdit);
      }
    }
  };

  const save = (type, data) => {
    setLoading(true);
    let numberss;
    data.map((item) => {
      numberss = numberss + "||" + item.numbers;
    });
    let series_no = numberss.slice(numberss.indexOf("||") + 2).trim();
    let obj = {
      series_no: series_no,
    };
    axios({
      method: "post",
      url: API.BASR_URL + API.AIRWAY_BILLS,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
          if (type === 1) {
            let array = numbers;
            var index = array.indexOf(data[0].numbers);
            if (index !== -1) {
              array.splice(index, 1);
            }
            setnumbers(array);
          } else {
            props.onClose();
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Drawer
      placement="right"
      title={<span className="txt4">Generate Bill Serires</span>}
      width={720}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form onFinish={airwaybill} onValuesChange={airwaybillonchange}>
        <Table bordered>
          <thead style={{ backgroundColor: "#1e88e5", color: "#fff" }}>
            <tr>
              <th>
                Choose <br /> generation <br /> Mode
              </th>
              <th>
                Airline <br />
                Prefix <br />( 3 Digits)
              </th>
              <th>
                Airway Bill Number <br />( 7 Digits)
              </th>
              <th>Check Digit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>from</td>
              <td>
                <Form.Item name="prefix" style={{ margin: -5 }}>
                  <Input
                    placeholder="Enter Number"
                    style={{ width: 100 }}
                    maxLength={3}
                  />
                </Form.Item>
              </td>
              <td>
                <Form.Item
                  name="billno"
                  style={{ margin: -5 }}
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    placeholder="Enter Number"
                    style={{ width: 250 }}
                    maxLength={7}
                  />
                </Form.Item>
              </td>
              <td>{first_checkdit}</td>
            </tr>
            <tr>
              <td>To</td>
              <td>{trheegits}</td>
              <td>{tobillno}</td>
              <td>{last_checkdit}</td>
            </tr>
            <tr>
              <td>Or</td>
              <td colSpan={2}>Enter Total Number of airway bill Count</td>
              <td>
                <Form.Item
                  name="count"
                  rules={[{ required: true, message: "required" }]}
                  style={{ margin: -5 }}
                >
                  <InputNumber placeholder="Enter" style={{ width: "100%" }} />
                </Form.Item>
              </td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td></td>
              <td>
                <div className="tableAction">
                  <Button onClick={() => Resset()}>Reset</Button>
                  <div style={{ margin: 5 }}></div>
                  <Button htmlType="submit" block type="primary">
                    Generate
                  </Button>
                </div>
              </td>
            </tr>
            {numbers && numbers.length ? (
              <>
                <tr>
                  <td colSpan={4}>Serires</td>
                </tr>
                {numbers.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td colSpan={2}>{Number(item.id + 1)}</td>
                      <td>{item.numbers}</td>
                      <td>
                        <div className="tableAction">
                          <Button
                            size="small"
                            htmlType="submit"
                            type="primary"
                            loading={
                              currentSave === item.numbers ? loading : false
                            }
                            onClick={() => {
                              setCurrentSave(item.numbers);
                              save(1, [item]);
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : null}
            {numbers && numbers.length ? (
              <tr>
                <td colSpan={3}></td>
                <td>
                  <Button
                    size="large"
                    type="primary"
                    block
                    onClick={() => {
                      setCurrentSave("all");
                      save(2, numbers);
                    }}
                    loading={currentSave === "all" ? loading : false}
                  >
                    Save All
                  </Button>
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </Form>
    </Drawer>
  );
}
export default FormModal;
