import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Dropdown, Menu, Input, Popconfirm } from "antd";
import { FiRefreshCw } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { MdOpenInBrowser, MdAddCircleOutline } from "react-icons/md";
import { BsChevronDoubleDown } from "react-icons/bs";
import styles from "./styles";
const { Search } = Input;
function MenuBar(props) {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Search
              placeholder="Search"
              allowClear
              onSearch={(val) => props.onSearch(val)}
            />
          </Col>
          <Col>
            <Button
              style={styles.buttons}
              block
              icon={<MdOpenInBrowser style={styles.buttonsIcon} />}
              disabled={props.singleSelect}
              onClick={() => props.onOpen()}
            >
              Open
            </Button>
          </Col>
          <Col>
            <Popconfirm
              placement="bottomRight"
              title={"Are you sure to delete?"}
              okText="Yes"
              cancelText="No"
              onConfirm={() => props.onDelete()}
            >
              <Button
                style={styles.buttons}
                block
                icon={<AiFillDelete style={styles.buttonsIcon} />}
                disabled={props.singleSelect}
              >
                Delete
              </Button>
            </Popconfirm>
          </Col>
          <Col>
            <Dropdown
              disabled={props.singleSelect}
              overlay={
                <Menu>
                  <Menu.Item onClick={props.printripsheet}>
                    Trip Sheet
                  </Menu.Item>
                  <Menu.Item onClick={props.printtax}>Tax Invoice</Menu.Item>
                  <Menu.Item onClick={props.addExpense}>Trip Expense</Menu.Item>
                </Menu>
              }
            >
              <Button
                style={styles.buttons}
                block
                icon={<BsChevronDoubleDown style={styles.buttonsIcon} />}
              >
                Actions
              </Button>
            </Dropdown>
          </Col>
          <Col>
            <Button
              onClick={props.onRefresh}
              style={styles.buttons}
              icon={<FiRefreshCw style={styles.buttonsIcon} />}
            >
              Refresh
            </Button>
          </Col>
          <Col>
            <Button
              onClick={props.addNew}
              style={styles.buttons}
              icon={<MdAddCircleOutline style={styles.buttonsIcon} />}
              type="primary"
            >
              New Trip
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MenuBar;
