import { Row, Col } from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import { IoBriefcaseOutline } from "react-icons/io5";
import { FaCreditCard, FaMoneyBill, FaHeart } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { BsCart3 } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { useEffect, useState } from "react";
import { API, Headers } from "../../Config";
import axios from "axios";

function Collection() {
	const [data, setData] = useState([]);
	const [error, setError] = useState("");

	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		axios
			.get(API.BASR_URL + API.DASHBOARD_LIST, {
				headers: Headers(),
			})
			.then(function (response) {
				if (response.status === 200) {
					let data = response.data;
					setData(data);
				}
			});
	};

	const homeCards = [
		{
			text: "Collections",
			count: data?.collections ?? "---",
			icon: <TiTick size={30} />,
		},
		{
			text: "Boxes",
			count: data?.boxes ?? "---",
			icon: <IoBriefcaseOutline size={30} />,
		},
		{
			text: "Users",
			count: data?.users ?? "---",
			icon: <IoBriefcaseOutline size={30} />,
		},
		{
			text: "Trips",
			count: data?.trips ?? "---",
			icon: <IoBriefcaseOutline size={30} />,
		},
		{
			text: "Airway Bills",
			count: data?.airwaybills ?? "---",
			icon: <FaCreditCard size={30} />,
		},
		{
			text: "Pickup Collection Request",
			count: data?.pickupCollectionRequest ?? "---",
			icon: <FaMoneyBill size={30} />,
		},
		{ text: "Cancelled Orders", count: 0, icon: <FcCancel size={30} /> },
		{
			text: "Users Cart",
			count: 100,
			icon: <BsCart3 size={30} />,
		},
		{ text: "Users Wishlist", count: 1000, icon: <FaHeart size={25} /> },
		{ text: "Registered Users", count: 900, icon: <HiUserGroup size={30} /> },
		{ text: "Registered Admins", count: 310, icon: <HiUserGroup size={30} /> },
	];

	const CardBorderStyle = (index) => {
		const colors = [
			"#5173d4",
			"#5173d4",
			"#5173d4",
			"#5173d4",
			"#2ac18a",
			"#5173d4",
			"#db4e43",
			"#47b4c1",
			"#db4e43",
			"#2ac18a",
			"#878793",
		];
		return {
			borderLeft: `4px solid ${colors[index % colors.length]}`,
		};
	};
	const FontColor = (index) => {
		const colors = [
			"#5173d4",
			"#5173d4",
			"#5173d4",
			"#5173d4",
			"#2ac18a",
			"#5173d4",
			"#db4e43",
			"#47b4c1",
			"#db4e43",
			"#2ac18a",
			"#878793",
		];
		return {
			color: `${colors[index % colors.length]}`,
		};
	};

	return (
		<div>
			<Row>
				{data &&
					homeCards.map((card, index) => (
						<Col sm="6" xs="12" md="3" key={index}>
							<div className="home-card" style={CardBorderStyle(index)}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-evenly",
										height: "80px",
										width: "100%",
									}}>
									<div className="home-card-row">
										<div
											className="txt2 new-request-text"
											style={FontColor(index)}>
											{card.text}
										</div>
										<div className="cardIcons" style={FontColor(index)}>
											{card.icon}
										</div>
									</div>
									<div className="txt5 new-request-count">{card.count}</div>
								</div>
							</div>
						</Col>
					))}
			</Row>
		</div>
	);
}
export default Collection;
