import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { Form, DatePicker, Select, Button } from "antd";
import moment from "moment";
import { BsSearch } from "react-icons/bs";

function MenuBar(props) {
  const ref = useRef();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const onUserChange = (val) => {
    let item = props.user.filter((i) => i.id === val);
    if (item && item.length && item[0].branch) {
      ref.current?.setFieldsValue({
        branch: item[0].branch.id,
      });
    }
  };

  const onFinish = (val) => {
    try {
      let requestObj = {
        date_from: moment(val.date[0]).toISOString(),
        date_to: moment(val.date[1]).toISOString(),
        user_id: Number(val.user),
      };
      props.onFinish(requestObj);
    } catch (error) {
      console.error("Error in onFinish", error);
    }
  };
  return (
    <div>
      <Container fluid>
        <Form ref={ref} onFinish={onFinish}>
          <Row>
            <Col>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: <div>Please choose a Date</div>,
                  },
                ]}
              >
                <RangePicker
                  defaultValue={[moment(), moment()]}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="user">
                <Select
                  allowClear
                  bordered={false}
                  className="borderedSelect"
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select a User"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(val) => onUserChange(val)}
                >
                  {props.user &&
                    props.user.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{ width: "100%" }}
                htmlType="submit"
              >
                <div className="Reports-MenuBar-btn">
                  Filter <BsSearch />
                </div>
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}

MenuBar.propTypes = {
  user: PropTypes.array.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default MenuBar;
