import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import DataTable from "./DataTable";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import FormModal from "./Modal";
function Expense() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});

  useEffect(() => {
    loaddata(page, take);
    UserDropdown();
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(
        API.BASR_URL + API.EXPANCE_ENTRY + `?order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        console.log(response);

        if (response.status === 200) {
          const data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops. Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops. Something went wrong");
        setLoading(false);
        setIndicator(false);
      });
  };
  const UserDropdown = () => {
    axios

      .get(API.BASR_URL + API.USERS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) {
          setUser(res.data);
        }
      })
      .catch(function (error) {
        message.error("Contries dropdown faild");
      });
  };
  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    console.log("item,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,", item);
    setMode("put");
    setItem(item);
    setform(true);
  };

  return (
    <div>
      <PageHeader
        PageName={"Expenses"}
        breadScrum={"Admin / Expenses"}
        RightButton={"New Expenses "}
        RightClcik={() => AddItem()}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            meta={meta}
            data={data}
            update={(item) => UpdateItem(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
          {form ? (
            <FormModal
              item={item}
              mode={mode}
              visible={form}
              user={user}
              loaddata={loaddata}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
export default Expense;
