import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "../../../Assets/Images/logoNew.jpg";
import moment from "moment";

const TermsAndCondition = (props) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.Table}>
        <View style={styles.Row}>
          <View style={styles.Col1}>
            <View style={styles.LogoBox}>
              <Image src={logo} style={styles.Logo} />
            </View>
          </View>
          <View style={styles.Col2}>
            <Text style={styles.Text1}>
              HO: {props?.data?.created_users?.branch?.name}
            </Text>
            <Text style={styles.Text1}>
              PH: {props?.data?.created_users?.branch?.mobile}
            </Text>
            <Text style={styles.Text1}> VAT NO: 300602378400003</Text>
          </View>
        </View>
        <View>
          <Text style={styles.Text2}>Terms And conditions</Text>
          <View style={{ marginTop: "30px" }}>
            <Text style={styles.Text3}>
              1. Kindly check your items at the time of delivery in the presence
              of the delivery person. If there are any complaints, please report
              them to the delivery person. Otherwise, claims will not be
              accepted.
            </Text>
            <Text style={styles.Text3}>
              2. Delivery time may change based on working days and does not
              consider holidays (government or special customs holidays).
              Delivery time may also vary due to uncontrollable issues such as
              flight offloading, flight cancellations, customs holidays, customs
              delays, national holidays, strikes, poor weather conditions, etc.
            </Text>
            <Text style={styles.Text3}>
              3. We are only a forwarding, clearing, and delivery agent between
              the shipper and the consignee. In any case where we are unable to
              deliver your goods (due to loss or damage), the agency’s maximum
              liability is 20 SR per kilogram or 100 USD per shipment (whichever
              is less). Otherwise, you must pay an additional charge for
              insurance.
            </Text>
            <Text style={styles.Text3}>
              4. There is no guarantee for liquid, glass, or breakable items.
            </Text>
            <Text style={styles.Text3}>
              5. If you pay any additional charge at the time of delivery, you
              must ask for a receipt with a seal. Without this receipt, we will
              not accept claims for reimbursement. (Octroi charges are the
              customer’s responsibility.)
            </Text>
            <Text style={styles.Text3}>
              6. You need to provide details of all items at the time of
              invoicing. Otherwise, we will not accept claims for items not
              listed.
            </Text>
            <Text style={styles.Text3}>
              7. If any additional charges arise at the time of clearance (e.g.,
              duties, detaining fees, etc.), the consignee must pay these
              charges before delivery.
            </Text>

            <Text style={styles.Text3}>
              8. A copy of the sender's Iqama and the receiver's ID proof is
              required for sending a shipment. You may submit a physical copy or
              send it via WhatsApp to 0508717038.
            </Text>
            <Text style={styles.Text3}>
              9. If you place any non-allowed items in the shipment, we will not
              be responsible for them, and they will not be returned (e.g., room
              spray, Raid, mobile phones, tab, etc.).
            </Text>
            <Text style={[styles.Text3, { marginTop: "10px" }]}>
              This is a non-negotiable house airway bill. All services provided
              are subject to the terms and conditions set forth on the reverse
              side of the shipper's copy. By this airway bill, the shipper
              acknowledges that he/she has read these conditions and agrees to
              be bound by each of them. The shipper also accepts that the
              maximum liability of Sahari Intl Courier in the case of damage or
              loss is limited to USD 100, unless insured. In tendering this
              shipment, the shipper agrees that Sahari Intl Courier Service
              shall not be liable for special, incidental, or consequential
              damages arising from the carriage hereof.
            </Text>
          </View>

          <View style={styles.item1}>
            <Text>{""}</Text>
            <Text style={styles.Text1}>Date : {moment().format("lll")}</Text>
          </View>

          <View style={styles.item2}>
            <Text style={styles.Text1}>{props?.name}</Text>
            <Text>{""}</Text>
          </View>
        </View>
      </View>
    </Page>
  );
};
export default TermsAndCondition;

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  Table: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  Col1: {
    flex: 1,
  },
  LogoBox: {
    width: "100%",
    height: "90px",
    objectFit: "contain",
  },
  Logo: {
    width: "190px",
    height: "50px",
  },
  Text1: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    textTransform: "uppercase",
  },
  Text2: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
  },
  Text3: {
    fontFamily: "Montserrat",
    fontSize: "9px",
    textTransform: "uppercase",
    marginBottom: 8,
    lineHeight: 1.5,
  },
  Text4: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    textTransform: "uppercase",
  },
  DateCol: {
    alignItems: "flex-end",
    marginTop: 30,
  },
  NameAndSignCol: {
    marginTop: 10,
  },
  item1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "25px",
    marginBottom: "20px",
  },
  item2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "35px",
  },
});
