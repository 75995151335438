import React, { useState } from "react";
import { Drawer, Form, Button, Input, Switch, message, Select } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
var width = window.innerWidth;
const { Option } = Select;
var innerwidth = window.innerWidth <= 760;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      name: values.name,
      code: values.code,
      country: Number(values.country),
      port_type: values.port_type,
      active: values.active,
    };
    let URL =
      props.mode === "post"
        ? API.PORTS_ACTIONS
        : API.PORTS_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Ports
        </span>
      }
      width={innerwidth ? width : width / 2.7}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          name: props.item && props.item.name,
          code: props.item && props.item.code,
          country: props.item && props.item.country,
          port_type: props.item && props.item.port_type,
          active: props.item && props.item.active ? props.item.active : false,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="name"
                label="Port Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
              <Form.Item
                name="port_type"
                label="Port Type"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Port Type" />
                
              </Form.Item>
              <Form.Item
                label="Active"
                name="active"
                rules={[{ required: true, message: "required" }]}
                valuePropName={props.item.active ? "checked" : "uncheked"}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="code"
                label="Port Code"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Code" />
              </Form.Item>
              <Form.Item
                name="country"
                label="Country"
                rules={[{ required: true, message: "required" }]}
              >
                <Select placeholder="Select Country">
                  {props.countries.map((option) => (
                    <Option key={option.code}>{option.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {props.mode === "post" ? null : <Col sm="6" xs="12"></Col>}
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;
