import React, { useEffect, useState, useRef } from "react";
import { Form, Modal, Select, message, Button, Input } from "antd";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { API, Headers } from "../../../Config";
import styles from "../styles";

const { Option } = Select;

function FormPaymentProcessModal(props) {
  const formRef = useRef();
  const [lodaing, setLoading] = useState(false);
  const [awbsuppliers, setawbsuppliers] = useState([]);
  const [agents, setagents] = useState([]);
  const [exportbroker, setexportbroker] = useState([]);
  const [clearingagents, setclearingagents] = useState([]);
  const [deliveryagents, setdeliveryagents] = useState([]);

  useEffect(() => {
    AwbsuplliersDropdown();
    awbAgentsDropdown();
    ExportbrokerDropdown();
    ClearingAgentDropdown();
    DeliveryAgentDropdown();
  }, []);

  const AwbsuplliersDropdown = () => {
    axios
      .get(API.BASR_URL + API.AWB_SUPPLIER_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) {
          setawbsuppliers(res.data);
        }
      })
      .catch(function (error) {
        message.error("awb suppliers dropdown faild");
      });
  };

  const awbAgentsDropdown = () => {
    axios
      .get(API.BASR_URL + API.AWB_XRAY_AGENT_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setagents(res.data);
      })
      .catch(function (error) {
        message.error("agents dropdown faild");
      });
  };

  const ExportbrokerDropdown = () => {
    axios
      .get(API.BASR_URL + API.EXPORT_BROKER_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setexportbroker(res.data);
      })
      .catch(function (error) {
        message.error("Export broker dropdown faild");
      });
  };

  const ClearingAgentDropdown = () => {
    axios
      .get(API.BASR_URL + API.CLEARING_AGENT_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setclearingagents(res.data);
      })
      .catch(function (error) {
        message.error("Clearing agent dropdown faild");
      });
  };

  const DeliveryAgentDropdown = () => {
    axios
      .get(API.BASR_URL + API.DELIVERY_AGENT_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setdeliveryagents(res.data);
      })
      .catch(function (error) {
        message.error("Clearing agent dropdown faild");
      });
  };

  const onChooseAwbSuppllier = (item) => {
    const value = item;
    let itm = awbsuppliers.filter((i) => i.id === value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        awb_supplier_rate: itm[0].rate,
      });
    }
  };

  const onChooseForwdAgnt = (item) => {
    const value = item;
    let itm = agents.filter((i) => i.id === value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        forwarding_agent_rate: itm[0].rate,
      });
    }
  };

  const chooseExportBroker = (item) => {
    const value = item;
    let itm = exportbroker.filter((i) => i.id === value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        export_broker_rate: itm[0].rate,
      });
    }
  };

  const chooseclearAgnt = (item) => {
    const value = item;
    let itm = clearingagents.filter((i) => i.id === value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        clearing_agent_destination_rate: itm[0].rate,
      });
    }
  };

  const chooseDlvryAgent = (item) => {
    const value = item;
    let itm = deliveryagents.filter((i) => value);
    if (itm && itm.length) {
      formRef.current?.setFieldsValue({
        delivery_agent_destination_rate: itm[0].rate,
      });
    }
  };

  const onFinish = (values) => {
    let ExtraCharges = Object.assign({}, values.extra_charges);
    setLoading(true);
    let requestObj = {
      awb_supplier: parseInt(values.awb_supplier),
      awb_supplier_rate: parseInt(values.awb_supplier_rate),
      awb_supplier_currency: values.awb_supplier_currency
        ? values.awb_supplier_currency
        : "SAR",

      forwarding_agent: values.forwarding_agent.toString(),
      forwarding_agent_rate: parseInt(values.forwarding_agent_rate),
      forwarding_agent_currency: values.forwarding_agent_currency
        ? values.forwarding_agent_currency
        : "SAR",

      export_broker: parseInt(values.export_broker),
      export_broker_rate: parseInt(values.export_broker_rate),
      export_broker_currency: values.export_broker_currency
        ? values.export_broker_currency
        : "SAR",

      clearing_agent_destination: parseInt(values.clearing_agent_destination),
      clearing_agent_destination_rate: parseInt(
        values.clearing_agent_destination_rate
      ),
      clearing_agent_destination_currency:
        values.clearing_agent_destination_currency
          ? values.clearing_agent_destination_currency
          : "SAR",

      delivery_agent_destination: parseInt(values.delivery_agent_destination),
      delivery_agent_destination_rate: parseInt(
        values.delivery_agent_destination_rate
      ),
      delivery_agent_destination_currency:
        values.delivery_agent_destination_currency
          ? values.delivery_agent_destination_currency
          : "SAR",

      extra_charges: ExtraCharges,
    };

    let URL = API.AIRWAY_BILLS_GEN_ACTION + props.selected.id;
    axios({
      method: "put",
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onChange();
        } else {
          setLoading(false);
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      width={700}
      footer={false}
    >
      <div>
        <h5 className="PrintLabelModal-txt1">Payment process</h5>

        <Form
          ref={formRef}
          onFinish={onFinish}
          initialValues={{
            awb_supplier: props.selected && props.selected.awb_supplier,
            forwarding_agent: props.selected && props.selected.forwarding_agent,
            export_broker: props.selected && props.selected.export_broker,
            clearing_agent_destination:
              props.selected && props.selected.clearing_agent_destination,
            delivery_agent_destination:
              props.selected && props.selected.delivery_agent_destination,
          }}
        >
          <Row>
            <Input.Group compact style={{ textAlign: "end" }}>
              <Form.Item
                name="awb_supplier"
                label="AWB Supplier"
                fieldKey="awb_supplier"
              >
                <Select
                  placeholder="Choose Supplier"
                  onChange={onChooseAwbSuppllier}
                >
                  {awbsuppliers &&
                    awbsuppliers.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="awb_supplier_rate">
                <Input placeholder="Rate" />
              </Form.Item>
              <Form.Item name="awb_supplier_currency">
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue="SAR"
                >
                  <Option value="SAR">SAR</Option>
                  <Option value="INR">INR</Option>
                  <Option value="USD">USD</Option>
                </Select>
              </Form.Item>
            </Input.Group>

            <Input.Group compact style={{ textAlign: "end" }}>
              <Form.Item
                name={"forwarding_agent"}
                label="FORWARDING AGENT"
                style={styles.FormItem}
              >
                <Select placeholder="Choose Agent" onChange={onChooseForwdAgnt}>
                  {agents &&
                    agents.map((option) => (
                      <Select.Option key={option.id} value={option.rate}>
                        {option.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="forwarding_agent_rate">
                <Input placeholder="Rate" />
              </Form.Item>
              <Form.Item name="forwarding_agent_currency">
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue="SAR"
                >
                  <Option value="SAR">SAR</Option>
                  <Option value="INR">INR</Option>
                  <Option value="USD">USD</Option>
                </Select>
              </Form.Item>
            </Input.Group>

            <Input.Group compact style={{ textAlign: "end" }}>
              <Form.Item
                name={"export_broker"}
                label="EXPORT BROKER"
                style={styles.FormItem}
              >
                <Select
                  placeholder="Choose Broker"
                  onChange={chooseExportBroker}
                >
                  {exportbroker &&
                    exportbroker.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="export_broker_rate">
                <Input placeholder="Rate" />
              </Form.Item>
              <Form.Item name="export_broker_currency">
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue="SAR"
                >
                  <Option value="SAR">SAR</Option>
                  <Option value="INR">INR</Option>
                  <Option value="USD">USD</Option>
                </Select>
              </Form.Item>
            </Input.Group>

            <Input.Group compact style={{ textAlign: "end" }}>
              <Form.Item
                name={"clearing_agent_destination"}
                style={styles.FormItem}
                label="CLEARING AGENT AT DESTINATION"
              >
                <Select placeholder="Choose agent" onChange={chooseclearAgnt}>
                  {clearingagents &&
                    clearingagents.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="clearing_agent_destination_rate">
                <Input placeholder="Rate" />
              </Form.Item>
              <Form.Item name="clearing_agent_destination_cur">
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue="SAR"
                >
                  <Option value="SAR">SAR</Option>
                  <Option value="INR">INR</Option>
                  <Option value="USD">USD</Option>
                </Select>
              </Form.Item>
            </Input.Group>

            <Input.Group compact style={{ textAlign: "end" }}>
              <Form.Item
                name={"delivery_agent_destination"}
                style={styles.FormItem}
                label="DELIVERY AGENT AT DESTINATION "
              >
                <Select placeholder="Choose agent" onChange={chooseDlvryAgent}>
                  {deliveryagents &&
                    deliveryagents.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="delivery_agent_destination_rate">
                <Input placeholder="Rate" />
              </Form.Item>
              <Form.Item name="delivery_agent_destination_cur">
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue="SAR"
                >
                  <Option value="SAR">SAR</Option>
                  <Option value="INR">INR</Option>
                  <Option value="USD">USD</Option>
                </Select>
              </Form.Item>
            </Input.Group>

            <Form.List name="extra_charges" style={{}}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                        textAlign: "end",
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "charge"]}
                        label="OTHER CHARGES"
                      >
                        <Input placeholder="" />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, "rate"]}>
                        <Input placeholder="Rate" />
                      </Form.Item>
                      <Form.Item>
                        <Select
                          style={{
                            width: "100%",
                          }}
                          defaultValue="SAR"
                        >
                          <Option value="SAR">SAR</Option>
                          <Option value="INR">INR</Option>
                          <Option value="USD">USD</Option>
                        </Select>
                      </Form.Item>
                      <div style={{ marginLeft: 10, marginTop: 5 }}>
                        <Button
                          type="ghost"
                          onClick={() => remove(name)}
                          size="small"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ))}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} size="small">
                        Add field
                      </Button>
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>
          </Row>

          <Row>
            <Col sm="6"></Col>
            <Col sm="3">
              <Button onClick={() => props.onClose()} block>
                Cancel
              </Button>
            </Col>
            <Col sm="3">
              <Button type="primary" htmlType="submit" block>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default FormPaymentProcessModal;
