import React from "react";
import { Modal } from "antd";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineTextsms } from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";

function ShareBillModal(props) {
  console.log("ShareBillModal=======>", props);
  let text = `Dear Customer,

  Thank You for using our service. Please click on the below link to find the Customer Receiept of your package.
  
  https://main.d2rrsftk73rp41.amplifyapp.com/bills/${props.item.id}
  
  Regards, 
  Sahari Cargo"`;
  let link = `https://api.whatsapp.com/send?phone=${props.item.sender_phone_alt}&text=${text}`;
  let email =
    "mailto:username@example.com?subject=Subject&body=message%20goes%20here";
  return (
    <div>
      <Modal visible={props.visible} onCancel={props.onCancel} footer={false}>
        <div> Share Bill Via</div>
        <br />
        <div className="ShareBillModal-box">
          <div className="ShareBillModal-box2">
            <a href={link}>
              <div style={{ padding: 10 }}>
                <BsWhatsapp size={50} color="green" />
              </div>
            </a>
          </div>
          <div className="ShareBillModal-box2">
            <div style={{ padding: 10 }}>
              <MdOutlineTextsms size={50} color="orange" />
            </div>
          </div>
          <div className="ShareBillModal-box2">
            <a href={email}>
              <div style={{ padding: 10 }}>
                <AiOutlineMail size={50} color="blue" />
              </div>
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ShareBillModal;
