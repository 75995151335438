import { Table, Pagination, Button, Popconfirm, Tag } from "antd";

function DataTable(props) {
  console.log("ramshadaaaaaa======>", props.data);

  const columns = [
    { title: "Item Id", dataIndex: "id", key: "id" },
    // { title: "Item Type", dataIndex: "item_type", key: "item_type" },
    {
      title: "Item Name",
      dataIndex: "items",
      key: "iitems",
    },

    {
      title: "",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small" type="primary">
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;
