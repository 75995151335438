import moment from "moment";
import React from "react";
import { CSVDownload } from "react-csv";

function ExportReport(props) {
  console.log("props.record=====>", props.record);

  var arr = [];

  props.data &&
    props.data.map((item, index) => {
      let TotalIncome = 0;
      let TotalExpense = 0;
      let TotalProfit = 0;

      TotalIncome =
        item.boxes &&
        item.boxes.reduce(function (tot, arr) {
          return Number(tot) + Number(arr.total_charge);
        }, 0);

      TotalExpense =
        Number(item.awb_supplier_rate && item.awb_supplier_rate) +
        Number(item.forwarding_agent_rate && item.forwarding_agent_rate) +
        Number(item.export_broker_rate && item.export_broker_rate) +
        Number(
          item.clearing_agent_destination_rate &&
            item.clearing_agent_destination_rate
        ) +
        Number(
          item.delivery_agent_destination_rate &&
            item.delivery_agent_destination_rate
        );

      TotalProfit = (TotalIncome - TotalExpense).toFixed(2);

      let obj = {
        "Created on": moment(item.createdAt),
        "AWB Number ": `${item.airline} - ${item.awb_no}`,
        "Owner ": item.user.name && item.user.name,
        "Pcs K/L": `${item.psc} / ${item.chargable_weight}`,
        " Origin": item.orijin_airport_name,
        "Destination ": `${item.destination_airport_name}`,
        "Income ": TotalIncome,
        "Expense ": TotalExpense,
        "Profit ": TotalProfit,
      };
      arr.push(obj);
    });

  console.log(arr);
  return (
    <div>
      <CSVDownload data={arr} />
    </div>
  );
}

export default ExportReport;
