import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message, Tabs } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import SubHeader from "./SearchableTree";
// import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import SearchableTree from "./SearchableTree";
function ChartOfAccounts() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [autoAccount, setAutoAccount] = useState(true);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [level, setLevel] = useState([]);
  const [category, setCategory] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [treeview, setTreeview] = useState([]);
  const [settings, setSettings] = useState([]);

  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});

  useEffect(() => {
    loaddata(page, take);
    AccountsDropdown();
    LevelDropdown();
    CategoryDropdown();
    TreeView();
    // fetchSettings();
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(
        API.BASR_URL +
          API.CHART_OF_ACCOUNTS_ACTIONS +
          `?order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);

          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };
  // const fetchSettings = () => {
  //   axios
  //     .get(API.BASR_URL + API.CONFIGARATION_LIST + `/all/ACC`, {
  //       headers: Headers(),
  //     })
  //     .then(function (res) {
  //       if (res.status === 200) {
  //         setSettings(res.data);
  //       }
  //     })
  //     .catch(function (error) {
  //       message.error("Supplier dropdown faild");
  //     });
  // };
  const LevelDropdown = () => {
    axios
      .get(API.BASR_URL + API.ACCOUNTS_LEVEL_LIST + `/all`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setLevel(res.data);
      })
      .catch(function (error) {
        message.error("Level dropdown faild");
      });
  };
  const TreeView = () => {
    axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/tree`, {
        headers: Headers(),
      })
      .then(function (res) {
        console.log("tree", res);
        if (res.status === 200) setTreeview(res.data);
      })
      .catch(function (error) {
        console.log("tree error", error);
        message.error("Level dropdown faild--3");
      });
  };
  const CategoryDropdown = () => {
    axios
      .get(API.BASR_URL + API.ACCOUNTS_CATEGORY_LIST + `/all`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setCategory(res.data);
      })
      .catch(function (error) {
        message.error("Category dropdown faild--2");
      });
  };
  const AccountsDropdown = () => {
    axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/all`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setAccounts(res.data);
      })
      .catch(function (error) {
        message.error("Accounts dropdown faild--1");
      });
  };
  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
    AccountsDropdown();
    TreeView();
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const DeleteItem = (item) => {
    setIndicator(true);
    axios
      .delete(API.BASR_URL + API.CHART_OF_ACCOUNTS_ACTIONS + item.account_no, {
        headers: Headers(),
      })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("123Oops.Something went wrong");
        console.log(error);
      });
  };
  const items = [
    {
      key: "1",
      label: `Tree View`,
      children: [<SearchableTree treeData={treeview} />],
    },
    {
      key: "2",
      label: `Table View`,
      children: [
        <div>
          <DataTable
            // meta={meta}
            // data={data}
            treeData={treeview}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            // PageOnchange={(page, take) => pagination(page, take)}
          />
        </div>,
      ],
    },
  ];
  return (
    <div>
      <PageHeader
        PageName={"Chart off Accounts"}
        breadScrum={"Admin / Account"}
        RightButton={"New Account "}
        RightClcik={() => AddItem()}
        loader={indicator}
      />
      {loading ? <LoadingBox /> : <Tabs defaultActiveKey="1" items={items} />}{" "}
      {form ? (
        <FormModal
          item={item}
          mode={mode}
          visible={form}
          level={level}
          category={category}
          accounts={accounts}
          autoAccount={true}
          settings={settings}
          onchange={() => relaod()}
          onClose={() => setform(false)}
        />
      ) : null}
    </div>
  );
}

export default ChartOfAccounts;
