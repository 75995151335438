import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import LoadingBox from "../../Components/LoadingBox";
import { Table } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
function DetailsMoal(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    axios
      .get(API.BASR_URL + API.AIRWAY_BILLS_GEN_ACTION + props.item.id, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          setData([response.data]);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
      });
  };

  return (
    <Modal
      visible={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={700}
    >
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <br />
          <br />
          <Table bordered hover>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => {
                return (
                  <>
                    <tr>
                      <td>active</td>
                      <td>{item.active}</td>
                    </tr>
                    <tr>
                      <td>actual_weight</td>
                      <td>{item.actual_weight}</td>
                    </tr>
                    <tr>
                      <td>add_charge</td>
                      <td>{item.add_charge}</td>
                    </tr>
                    <tr>
                      <td>agent_name_on_awb</td>
                      <td>{item.agent_name_on_awb}</td>
                    </tr>
                    <tr>
                      <td>airline_rate</td>
                      <td>{item.airline_rate}</td>
                    </tr>
                    <tr>
                      <td>airlines</td>
                      <td>{item.airlines}</td>
                    </tr>
                    <tr>
                      <td>arraival_port</td>
                      <td>{item.arraival_port}</td>
                    </tr>
                    <tr>
                      <td>awb_charge</td>
                      <td>{item.awb_charge}</td>
                    </tr>
                    <tr>
                      <td>awb_supplier</td>
                      <td>{item.awb_supplier}</td>
                    </tr>
                    <tr>
                      <td>bill_number</td>
                      <td>{item.bill_number}</td>
                    </tr>
                    <tr>
                      <td>chargeable_weight</td>
                      <td>{item.chargeable_weight}</td>
                    </tr>
                    <tr>
                      <td>clearing_agent_destination</td>
                      <td>{item.clearing_agent_destination}</td>
                    </tr>
                    <tr>
                      <td>commodity</td>
                      <td>{item.commodity}</td>
                    </tr>
                    <tr>
                      <td>consignee</td>
                      <td>{item.consignee}</td>
                    </tr>
                    <tr>
                      <td>customs_broker_rate</td>
                      <td>{item.customs_broker_rate}</td>
                    </tr>
                    <tr>
                      <td>date</td>
                      <td>{item.date}</td>
                    </tr>
                    <tr>
                      <td>delivery_agent_destination</td>
                      <td>{item.delivery_agent_destination}</td>
                    </tr>
                    <tr>
                      <td>delivery_agent_rate</td>
                      <td>{item.delivery_agent_rate}</td>
                    </tr>
                    <tr>
                      <td>departure_port</td>
                      <td>{item.departure_port}</td>
                    </tr>
                    <tr>
                      <td>des_clearing_agent_rate</td>
                      <td>{item.des_clearing_agent_rate}</td>
                    </tr>
                    <tr>
                      <td>dimension</td>
                      <td>{item.dimension}</td>
                    </tr>
                    <tr>
                      <td>export_broker</td>
                      <td>{item.export_broker}</td>
                    </tr>
                    <tr>
                      <td>forwarding_agent</td>
                      <td>{item.forwarding_agent}</td>
                    </tr>
                    <tr>
                      <td>incharge_of_generation</td>
                      <td>{item.incharge_of_generation}</td>
                    </tr>
                    <tr>
                      <td>other_charge</td>
                      <td>{item.other_charge}</td>
                    </tr>
                    <tr>
                      <td>packing_chanrge</td>
                      <td>{item.packing_chanrge}</td>
                    </tr>
                    <tr>
                      <td>peices</td>
                      <td>{item.peices}</td>
                    </tr>
                    <tr>
                      <td>place_of_generation</td>
                      <td>{item.place_of_generation}</td>
                    </tr>
                    <tr>
                      <td>rate</td>
                      <td>{item.rate}</td>
                    </tr>
                    <tr>
                      <td>shipper</td>
                      <td>{item.shipper}</td>
                    </tr>
                    <tr>
                      <td>vloume_weight</td>
                      <td>{item.vloume_weight}</td>
                    </tr>
                    <tr>
                      <td>xray_agent_rate</td>
                      <td>{item.xray_agent_rate}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </Modal>
  );
}
export default DetailsMoal;
