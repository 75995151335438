import { message } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import LoadingBox from "../../Components/LoadingBox";
import { API, Headers } from "../../Config";
import PageHeader from "../../Routes/PageHeader";
import DataTable from "./DataTable";
import FormModal from "./FormModal";
import PdfRefNumModal from "./PdfRefNumModal";
import PrintRefNumberModal from "./PrintRefNumberModal";

function ReferenceNoGenScreen() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setForm] = useState(false);
  const [print, setPrint] = useState(false);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [item, setItem] = useState([]);
  const [item2, setItem2] = useState([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState({});

  const [selected, setSelected] = useState([]);
  const [pdfview, setPdfView] = useState(false);

  useEffect(() => {
    loadData(page, take);
    loadUsers();
  }, []);

  const loadData = (p, t) => {
    try {
      axios
        .get(
          API.BASR_URL +
            API.REFERENCE_NUM_ACTIONS +
            `?order=DESC&page=${p}&take=${t}`,
          { headers: Headers() }
        )
        .then(function (response) {
          console.log("=== ", response);
          if (response?.status === 200) {
            let data = response.data;
            setData(data?.data);
            setMeta(data.meta);
            setLoading(false);
            setIndicator(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          setIndicator(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const loadUsers = () => {
    try {
      axios
        .get(API.BASR_URL + API.USERS_LIST + `/list`, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setUsers(data);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const AddItem = () => {
    setForm(true);
  };

  const deleteItem = (item) => {
    setIndicator(true);
    axios
      .delete(API.BASR_URL + API.REFERENCE_NUM_ACTIONS + item.id, {
        headers: Headers(),
      })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        reload();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  const reload = () => {
    setForm(false);
    loadData(page, take);
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loadData(page, take);
  };

  const PrintRefNum = (val) => {
    console.log(val);
    setPrint(true);
    setItem(val?.numbers);
    setItem2(val);
  };

  const selectCell = (row) => {
    setSelected(row);
    console.log("rowINDEx=====>", row);
  };

  const generatePDF = (val) => {
    setPdfView(true);
    console.log("generatePDF ======>", val);
  };

  return (
    <div>
      <PageHeader
        PageName={"Reference Number"}
        breadScrum={"Admin / ReferenceNum"}
        RightButton={"New Number "}
        RightClcik={() => AddItem()}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          onSelect={(data) => selectCell(data)}
          generate={(val) => generatePDF(val)}
          pageOnChange={(page, take) => pagination(page, take)}
          print={(val) => PrintRefNum(val)}
          delete={(val) => deleteItem(val)}
        />
        // <div>Hello</div>
      )}
      {form ? (
        <FormModal
          visible={form}
          users={users}
          onClose={() => setForm(false)}
          reload={reload}
        />
      ) : null}
      {print ? (
        <PrintRefNumberModal
          visible={print}
          item={item}
          item2={item2}
          onCancel={() => setPrint(false)}
          reload={reload}
        />
      ) : null}
      {pdfview ? (
        <PdfRefNumModal
          visible={pdfview}
          onCancel={() => setPdfView(false)}
          selected={selected}
        />
      ) : null}
    </div>
  );
}

export default ReferenceNoGenScreen;
