import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider } from "antd";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import BoxGeneralDetails from "./BoxGeneralDetails";
import BoxHistory from "./BoxHistory";
import "./styles.css";

function Tracking() {
  const [boxnum, SetBoxnum] = useState(0);
  const [data, setData] = useState([]);
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(false);
  const location = useLocation();

  useEffect(() => {
    GetBoxNumber();
  }, []);

  const GetBoxNumber = () => {
    var windows = location.pathname;
    var id = Number(windows.substring(windows.lastIndexOf("/") + 1));
    SetBoxnum(id);
  };

  const onChange = (val) => {
    SetBoxnum(val.target.value);
  };

  const onSearch = () => {
    if (boxnum) {
      loadData(boxnum);
      setLoading(true);
    }
  };

  const loadData = (id) => {
    axios
      .get(API.BASR_URL + API.BOXES_LIST + `/box_status/` + id, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          console.log("boxdATA====>", data);
          console.log(data[0].airway_bill.airline);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <div className="Tracking-container">
      <div className="Tracking-box1">
        <br />
        <br />
        <div>
          <div className="detailed-tracking-saerch-box">
            <Form className="detailed-tracking-saerch-box-form">
              <Row>
                <Col sm="9" xs="9" style={{ padding: 0 }}>
                  <Input
                    placeholder="Enter Box Number"
                    style={{
                      textAlign: "center",
                      padding: 10,
                      height: 45,
                      border: 0,
                    }}
                    onChange={(val) => onChange(val)}
                    autoFocus={true}
                    defaultValue={boxnum}
                    value={boxnum}
                  />
                </Col>
                <Col sm="3" xs="3" style={{ padding: 0 }}>
                  <Button
                    block
                    type="primary"
                    style={{ height: 45, borderRadius: "0px 10px 10px 0px" }}
                    onClick={() => onSearch()}
                  >
                    Track
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>

      {loading ? (
        <LoadingBox />
      ) : (
        <div className="Tracking-box2">
          <div className="Tracking-box4">
            <div>
              <BoxGeneralDetails data={data} />
            </div>
            <br />

            <div className="Tracking-box6">
              <Divider
                style={{
                  color: "#1e88e5",
                }}
              >
                <div
                  className="Tracking-icon-box"
                  onClick={() => setDetails(!details)}
                >
                  {details ? (
                    <AiOutlineUpCircle color="#1e88e5" size={40} />
                  ) : (
                    <AiOutlineDownCircle color="#1e88e5" size={40} />
                  )}
                </div>
              </Divider>
            </div>
            {details ? (
              <div>
                <BoxHistory data={data} />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default Tracking;
