import { Table, Pagination, Button, Popconfirm, Tag } from "antd";

function DataTable(props) {
  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <Tag color={active ? "green" : "red"}>
          {active ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Agent ID",
      dataIndex: "agent_id",
      key: "agent_id",
    },
    {
      title: "Action",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;
