import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import MontserratMedium from "../../../Assets/Fonts/Montserrat-Medium.ttf";
import MontserratBold from "../../../Assets/Fonts/Montserrat-Bold.ttf";

function PdfLabel(props) {
  const numOfBox = props.selected && parseInt(props.selected.psc);
  const image = props.selected && props.selected.airlines.airline_logo;

  let cnee = props.selected.cnee && props.selected.cnee;
  let CneeName;
  if (cnee.split("  ").length > 1) {
    CneeName = cnee.split("  ")[0];
  } else {
    CneeName = cnee.split("\n")[0];
  }

  let shipper = props.selected.shipper && props.selected.shipper;
  let shipperName;
  if (shipper.split("  ").length > 1) {
    shipperName = shipper.split("  ")[0];
  } else {
    shipperName = shipper.split("\n")[0];
  }

  return (
    <Document>
      {[...Array(numOfBox)].map((e, i) => {
        return (
          <Page size="A6" wrap style={styles.Page}>
            <View style={styles.Label}>
              <View style={styles.Table}>
                <View style={styles.Row}>
                  {props.formdata.iflogo &&
                  props.selected.airlines.airline_logo ? (
                    <View style={styles.Col4}>
                      <View style={styles.LogoBox}>
                        <Image
                          source={{
                            uri: image,
                            method: "GET",
                            headers: { "Cache-Control": "no-cache" },
                          }}
                          style={styles.Logo}
                        />
                      </View>
                    </View>
                  ) : (
                    <View style={styles.Col4}>
                      <View style={styles.Border}>
                        <Text style={styles.Text1}>Airline</Text>

                        <Text style={styles.Text2}>
                          {props.formdata.airline}
                        </Text>
                      </View>
                    </View>
                  )}
                </View>
                <View style={styles.Row}>
                  <View style={styles.Col4}>
                    {props.barcode ? (
                      <View style={styles.BarcodeBox}>
                        <Image src={props.barcode} style={styles.barcodesize} />
                      </View>
                    ) : null}
                  </View>
                </View>
                <View style={styles.Row}>
                  <View style={styles.Col4}>
                    <View style={styles.Border}>
                      <Text style={styles.Text1}> Air Way Bill Number</Text>
                      <Text style={styles.Text3}>{props.formdata.awb_no}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.Row}>
                  <View
                    style={
                      props.formdata.inc_origin ? styles.Col2 : styles.Col4
                    }
                  >
                    <View style={styles.Border}>
                      <Text style={styles.Text1}> Destination</Text>
                      <Text style={styles.Text2}>
                        {props.formdata.destination_airport_name}
                      </Text>
                    </View>
                  </View>
                  {props.formdata.inc_origin ? (
                    <View style={styles.Col2}>
                      <View style={styles.Border}>
                        <Text style={styles.Text1}>Departure</Text>
                        <Text style={styles.Text2}>
                          {props.formdata.orijin_airport_name}
                        </Text>
                      </View>
                    </View>
                  ) : null}
                </View>
                <View style={styles.Row}>
                  {props.formdata.inc_transit ? (
                    <View
                      style={
                        props.formdata.inc_mawbtotal_pcs
                          ? styles.Col2
                          : styles.Col4
                      }
                    >
                      <View style={styles.Border}>
                        <Text style={styles.Text1}>Transit</Text>
                        <Text style={styles.Text2}>
                          {props.formdata.route_to}
                        </Text>
                      </View>
                    </View>
                  ) : null}
                  {props.formdata.inc_mawbtotal_pcs ? (
                    <View style={styles.Col2}>
                      <View style={styles.Border}>
                        <Text style={styles.Text1}>Total No. of pieces</Text>
                        <Text style={styles.Text2}>{props.selected.psc}</Text>
                      </View>
                    </View>
                  ) : null}
                </View>
                <View style={styles.Row}>
                  {props.formdata.inc_mawbtotal_weight ? (
                    <>
                      <View style={styles.Col2}>
                        <View style={styles.Border}>
                          <Text style={styles.Text1}>
                            Total Weight of this Shipment
                          </Text>
                          <Text style={styles.Text2}>
                            {props.selected.chargable_weight}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.Col2}>
                        <View style={styles.Border}>
                          <Text style={styles.Text1}>{`   `}</Text>
                          <Text style={styles.Text2}>{`   `}</Text>
                        </View>
                      </View>
                    </>
                  ) : null}
                </View>
                <View style={styles.Row}>
                  {props.formdata.inc_agent ? (
                    <View style={styles.Col4}>
                      <View style={styles.Border}>
                        <Text style={styles.Text1}>Agent</Text>
                        <Text style={styles.Text2}>
                          {props.formdata.agent_name_on_awb}
                        </Text>
                      </View>
                    </View>
                  ) : null}
                </View>
                <View style={styles.Row}>
                  <View style={styles.Col4}>
                    <View style={styles.Border}>
                      <View>
                        <Text style={styles.Text1}> Other Information</Text>
                        <Text style={styles.Text4}>
                          {" "}
                          {props.formdata.other_info}
                          {props.formdata.inc_shipper === "a" ? null : (
                            <>
                              {props.formdata.inc_shipper === "b" ? (
                                <Text>
                                  Shipper:
                                  {shipperName}
                                </Text>
                              ) : (
                                <Text>
                                  Shipper:
                                  {shipper}
                                </Text>
                              )}
                            </>
                          )}
                          {props.formdata.inc_consignee === "a" ? null : (
                            <>
                              {props.formdata.inc_consignee === "b" ? (
                                <Text>
                                  {"\n"} Consignee :{CneeName}
                                </Text>
                              ) : (
                                <Text>
                                  {"\n"} Consignee : {cnee}
                                </Text>
                              )}
                            </>
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
}

export default PdfLabel;

const styles = StyleSheet.create({
  Page: {},
  Label: {
    padding: 10,
    height: "140mm",
  },
  Table: {
    width: "100%",
    tableLayout: "fixed",
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "inherit",
  },
  Col4: {
    flex: 2,
  },
  Col2: {
    flex: 1,
  },

  Logo: {
    width: "100%",
  },
  LogoBox: {
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    height: 60,
  },
  Border: {
    border: "1px solid black",
  },
  Text1: {
    fontSize: 10,
    padding: 5,
  },
  Text2: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
  },
  Text3: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  Text4: {
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    padding: 3,
  },
  BarcodeBox: {
    alignItems: "center",
    justifyContent: "center",
    height: 100,
  },
  barcodesize: {
    width: "100%",
  },
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
  ],
});
