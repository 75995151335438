import { createSlice } from "@reduxjs/toolkit";
const TaxgoSlice = createSlice({
  name: "TaxgoSlice",
  initialState: {
    data: {},
  },
  reducers: {
    taxGoDatas: (state, action) => {
      state.data = action.payload;
    },
    clearTaxGoDatas: (state, action) => {
      state.data = {};
    },
  },
});

export default TaxgoSlice;

export const { taxGoDatas, clearTaxGoDatas } = TaxgoSlice.actions;
