import React, { useState } from "react";
import { Invoice } from "@axenda/zatca";
import moment from "moment";

function GenQrCode(props) {
  const [data, setData] = useState();

  const invoiceTotal = props.item && props.item.total_charge.toString();
  const date = moment().format("YYYY-MM-DDT00:00:00Z");

  const invoice = new Invoice({
    sellerName: "SAHARI CARGO",
    vatRegistrationNumber: "300602378400003",
    invoiceTimestamp: date,
    invoiceTotal: invoiceTotal,
    invoiceVatTotal: "0.00",
  });

  async function myFunction() {
    const imageData = await invoice.render();
    setData(imageData);
    return imageData;
  }

  let image = myFunction();
  return (
    <div>
      {image ? (
        <img src={data} alt="Invoice QR Code" className="print-invoice-qr" />
      ) : null}
    </div>
  );
}

export default GenQrCode;
