import React, { useRef } from "react";
import styles from "../styles";
import { Modal, Form, Input, Select, Button } from "antd";
import { Row, Col, Table } from "react-bootstrap";
import { MdRemoveCircleOutline, MdAddCircleOutline } from "react-icons/md";
import { useState } from "react";

function FormRateModal(props) {
  const formRef = useRef();
  const [dimensions, setDimensions] = useState(
    props.data && props.data.dimensions ? props.data.dimensions : [{ key: 1 }]
  );

  let TotalPieces = 0;
  let TotalCharge = 0;

  const handleTotal = (_, values) => {
    let Volume = 0;
    let TotalVolume = 0;
    let VolumetricWeight = 0;
    let TotalVolumetricWeight = 0;
    let factor = 6000;
    const rowsCopy = [...values.dimensions];
    rowsCopy.forEach((Item) => {
      Volume = Item?.Length * Item?.Width * Item?.Height * Item?.Pieces;
      TotalVolume = TotalVolume + Volume;
      VolumetricWeight =
        ((Item?.Length * Item?.Width * Item?.Height) / 6000) * Item?.Pieces;

      TotalVolumetricWeight = TotalVolumetricWeight + VolumetricWeight;
    });

    TotalPieces = rowsCopy.reduce(function (tot, arr) {
      return parseInt(tot) + parseInt(arr?.Pieces);
    }, 0);
    let GrossWeight = values.gross_weight ? Number(values.gross_weight) : 0;
    let ChargableWeight = TotalVolumetricWeight
      ? Number(TotalVolumetricWeight.toFixed(2))
      : 0;
    let Rate = Number(values.rate_charge);

    if (GrossWeight > ChargableWeight) {
      TotalCharge = GrossWeight * Rate;
    } else {
      TotalCharge = Number(ChargableWeight * Rate).toFixed(2);
    }

    formRef.current.setFieldsValue({
      volume: TotalVolume + "  " + "CM3" + "(" + TotalPieces + ")PIECES",
      vol_weight: TotalVolumetricWeight.toFixed(2) + " " + "kg",
      factor: factor + " " + "cm3/kg",
      chargeable_weight:
        GrossWeight > ChargableWeight ? GrossWeight : ChargableWeight,
      total: TotalCharge,
    });
  };

  const onFinish = (values) => {
    if (
      Number(values.pieces) === TotalPieces &&
      Number(values.chargeable_weight) <= Number(values.gross_weight)
    ) {
      props.onChange(values);
      props.onClose();
    } else if (Number(values.chargeable_weight) > Number(values.gross_weight)) {
      var agree = window.confirm(
        "VolumetricWeight is greater than Gross weight\n\n Do you want to proceed "
      );
      if (agree) {
        props.onChange(values);
        props.onClose();
      }
    } else if (Number(values.pieces) != TotalPieces) {
      alert("No of Pieces doesn't match");
    }
  };
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={false}
      width={1000}
      maskClosable={false}
    >
      <h5 className="PrintLabelModal-txt1">Add Item</h5>

      <Form
        name="basic"
        ref={formRef}
        onFinish={onFinish}
        onValuesChange={handleTotal}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          pieces: props.data && props.data.pieces,
          gross_weight: props.data && props.data.gross_weight,
          gross_weight: props.data && props.data.gross_weight,
          unit: props.data && props.data.unit,
          rate_class: props.data && props.data.rate_class,
          item_no: props.data && props.data.item_no,
          chargeable_weight: props.data && props.data.chargeable_weight,
          rate_charge: props.data && props.data.rate_charge,
          total: props.data && props.data.total,
          volume: props.data && props.data.volume,
          vol_weight: props.data && props.data.vol_weight,
          factor: props.data && props.data.factor,
          nature_quantity: props.data && props.data.nature_quantity,
          dimensions: dimensions,
        }}
      >
        <Row>
          <Col sm="5" xs="12">
            <div className="awb-form-box">
              <div className="awb-form-box-legend">Information</div>
              <br />
              <Form.Item name="pieces" label="Pieces" style={{ margin: 4 }}>
                <Input />
              </Form.Item>
              <Input.Group compact>
                <Form.Item
                  name="gross_weight"
                  label="Gross weight"
                  style={{ margin: 4, width: "calc(100% - 69px)" }}
                >
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item name="unit">
                  <Select size="" style={{ margin: 4 }} defaultValue="K">
                    <Select.Option key="K">K</Select.Option>
                    <Select.Option key="L">L</Select.Option>
                  </Select>
                </Form.Item>
              </Input.Group>

              <Form.Item
                name="rate_class"
                label="Rate class"
                style={{ margin: 4 }}
              >
                <Select defaultValue="Q">
                  <Select.Option key="M">M</Select.Option>
                  <Select.Option key="N">N</Select.Option>
                  <Select.Option key="Q">Q</Select.Option>
                  <Select.Option key="B">B</Select.Option>
                  <Select.Option key="K">K</Select.Option>
                  <Select.Option key="C">C</Select.Option>
                  <Select.Option key="R">R</Select.Option>
                  <Select.Option key="S">S</Select.Option>
                  <Select.Option key="U">U</Select.Option>
                  <Select.Option key="T">T</Select.Option>
                  <Select.Option key="E">E</Select.Option>
                  <Select.Option key="X">X</Select.Option>
                  <Select.Option key="Y">Y</Select.Option>
                  <Select.Option key="W">W</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="item_no"
                label="Item number"
                style={{ margin: 4 }}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                name="chargeable_weight"
                label="Charge. weight"
                style={{ margin: 4 }}
              >
                <Input placeholder="" disabled />
              </Form.Item>
              <Form.Item
                name="rate_charge"
                label="Rate/Charge"
                style={{ margin: 4 }}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item name="total" label="Total" style={{ margin: 4 }}>
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="Auto-calc" style={{ margin: 4 }}>
                <Select>
                  <Select.Option key="yes">Yes</Select.Option>
                </Select>
              </Form.Item>
              <br />
              <Form.Item name="volume" label="VOLUME" style={{ margin: 4 }}>
                <Input
                  placeholder=""
                  value={"100"}
                  disabled
                  style={styles.DataDisplay}
                />
              </Form.Item>
              <Form.Item
                name="vol_weight"
                label="VOLUMETRIC W."
                style={{ margin: 4 }}
              >
                <Input placeholder="" disabled style={styles.DataDisplay} />
              </Form.Item>
              <Form.Item name="factor" label="FACTOR" style={{ margin: 4 }}>
                <Input placeholder="" disabled style={styles.DataDisplay} />
              </Form.Item>
            </div>
          </Col>
          <Col sm="7" xs="12">
            <div className="awb-form-box">
              <div className="awb-form-box-legend">
                Nature and quantity of goods
              </div>
              <br />
              <Form.Item
                name="nature_quantity"
                label="Nature and quantity"
                style={{ margin: 4 }}
              >
                <Input.TextArea style={styles.FormInput} />
              </Form.Item>
              <div>Dimensions</div>
              <Form.List name="dimensions">
                {(fields, { add, remove }) => (
                  <>
                    <Table size="sm" className="formtable">
                      <tr>
                        <td style={{ fontSize: 13 }}>Length</td>
                        <td style={{ fontSize: 13 }}>Width</td>
                        <td style={{ fontSize: 13 }}>Height</td>
                        <td style={{ fontSize: 13 }}></td>
                        <td style={{ fontSize: 13 }}>Pieces</td>
                        <td style={{ fontSize: 13 }}>Weight</td>
                        <td style={{ fontSize: 13 }}></td>
                      </tr>

                      {fields.map(({ key, name, ...restField }) => (
                        <tr>
                          <td>
                            <Form.Item
                              {...restField}
                              name={[name, "Length"]}
                              style={{
                                margin: -3,
                                padding: 0,
                                marginRight: -10,
                              }}
                            >
                              <Input size="small" />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              {...restField}
                              name={[name, "Width"]}
                              style={{
                                margin: -3,
                                padding: 0,
                                marginRight: -10,
                              }}
                            >
                              <Input size="small" />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              {...restField}
                              name={[name, "Height"]}
                              style={{
                                margin: -3,
                                padding: 0,
                                marginRight: -10,
                              }}
                            >
                              <Input size="small" />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              {...restField}
                              name={[name, "unit1"]}
                              style={{ margin: -3, padding: 0 }}
                            >
                              <Select
                                size="small"
                                style={{ width: 60 }}
                                defaultValue="cm"
                              >
                                <Select.Option key="mm">mm</Select.Option>
                                <Select.Option key="cm">cm</Select.Option>
                                <Select.Option key="dm">dm</Select.Option>
                                <Select.Option key="m">m</Select.Option>
                                <Select.Option key="in">in</Select.Option>
                                <Select.Option key="ft">ft</Select.Option>
                                <Select.Option key="yd">yd</Select.Option>
                              </Select>
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              {...restField}
                              name={[name, "Pieces"]}
                              style={{
                                margin: -3,
                                padding: 0,
                                marginRight: -10,
                              }}
                            >
                              <Input
                                size="small"
                                onKeyDown={(e) => {
                                  if (e.code === "Enter" && e.key === "Enter") {
                                    e.preventDefault();
                                    add();
                                  }
                                }}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              {...restField}
                              name={[name, "Weight"]}
                              style={{
                                margin: -3,
                                padding: 0,
                                marginRight: -10,
                              }}
                            >
                              <Input size="small" />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              {...restField}
                              name={[name, "unit2"]}
                              style={{
                                margin: -3,
                                padding: 0,
                                marginRight: -10,
                              }}
                            >
                              <Select
                                size="small"
                                style={{ width: 50 }}
                                defaultValue="k"
                              >
                                <Select.Option key="k">K</Select.Option>
                                <Select.Option key="l">L</Select.Option>
                              </Select>
                            </Form.Item>
                          </td>
                          <MdRemoveCircleOutline
                            style={{ marginLeft: 10 }}
                            onClick={() => remove(name)}
                          />
                        </tr>
                      ))}
                    </Table>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<MdAddCircleOutline />}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm="8" xs="12"></Col>
          <Col sm="2" xs="12">
            <Button type="primary" htmlType="submit" block>
              Accept
            </Button>
          </Col>
          <Col sm="2" xs="12">
            <Button block onClick={props.onClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default FormRateModal;
