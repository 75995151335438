// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import moment from "moment";
// import { Drawer, Form, Input, Button, Select, message, Row, Col } from "antd";
// import { API } from "../../Config";

// const { Option } = Select;

// var width = window.innerWidth;
// var innerwidth = window.innerWidth <= 760;

// function FormModal(props) {
//   const [loading, setLoading] = useState(false);
//   const [userID, setUserID] = useState("");
//   const [note, setNote] = useState("");
//   const [head, setHead] = useState("");
//   const [amount, setAmount] = useState("");
//   const [options, setOptions] = useState([]);

//   const createItem = async (values) => {
//     const currentDate = moment();
//     const formattedDate = currentDate.format("YYYY-MM-DD HH:mm:ss");

//     const contentData = {
//       userid: Number(values.userid),
//       note: note,
//       head: head,
//       amount: Number(amount),
//       date: formattedDate,
//     };

//     try {
//       setLoading(true);
//       const response = await axios.post(
//         "http://localhost:8082/expance_entry",
//         contentData
//       );
//       message.success("Expense added successfully");
//       props.onClose();
//       props.loaddata();
//     } catch (error) {
//       message.error("An error occurred while adding the expense");
//     } finally {
//       setLoading(false);
//       setUserID("");
//       setNote("");
//       setHead("");
//       setAmount("");
//     }
//   };

//   const validateTextLength = (_, value, maxLength, fieldName) => {
//     if (value && value.length > maxLength) {
//       return Promise.reject(
//         `The ${fieldName} must not exceed ${maxLength} characters.`
//       );
//     } else {
//       return Promise.resolve();
//     }
//   };

//   useEffect(() => {
//     const fetchOptions = async () => {
//       try {
//         const response = await axios.get(
//           API.BASR_URL + API.USERS_LIST + `/list`
//         );
//         const data = response.data;
//         setOptions(data);
//       } catch (error) {
//         console.error("Error fetching options:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOptions();
//   }, []);

//   const onFinish = (values) => createItem(values);

//   return (
//     <Drawer
//       placement={props.mode === "post" ? "right" : "left"}
//       title={<span className="txt4">Add Expenses</span>}
//       width={innerwidth ? width : width / 2.7}
//       onClose={props.onClose}
//       visible={props.visible}
//       bodyStyle={{ paddingBottom: 80 }}
//     >
//       <Form layout="vertical" onFinish={onFinish}>
//         <Row style={{ display: "flex", justifyContent: "space-between" }}>
//           <Col sm={11} xs={24}>
//             <Form.Item
//               name="userid"
//               label="User"
//               rules={[
//                 { required: true, message: "User is required" },
//                 ({ getFieldValue }) => ({
//                   validator(_, value) {
//                     return validateTextLength(_, value, 8, "User ID");
//                   },
//                 }),
//               ]}
//             >
//               <Select
//                 placeholder="Select User"
//                 filterOption={(input, option) => {
//                   return option.toLowerCase().includes(input.toLowerCase());
//                 }}
//               >
//                 {options.length > 0 &&
//                   options.map((option) => {
//                     return <Option key={option.id}>{option.name}</Option>;
//                   })}
//               </Select>
//             </Form.Item>
//             <Form.Item
//               name="note"
//               label="Note"
//               rules={[
//                 ({ getFieldValue }) => ({
//                   validator(_, value) {
//                     return validateTextLength(_, value, 25, "Note");
//                   },
//                 }),
//               ]}
//             >
//               <Input
//                 showCount
//                 maxLength={25}
//                 onChange={(e) => setNote(e.target.value)}
//                 placeholder="Enter Note"
//                 type="text"
//               />
//             </Form.Item>
//           </Col>
//           <Col sm={12} xs={24}>
//             <Form.Item
//               name="head"
//               label="Expense Type"
//               rules={[
//                 { required: true, message: "Head Name is required" },
//                 ({ getFieldValue }) => ({
//                   validator(_, value) {
//                     return validateTextLength(_, value, 25, "Head Name");
//                   },
//                 }),
//               ]}
//             >
//               <Input
//                 showCount
//                 maxLength={25}
//                 onChange={(e) => setHead(e.target.value)}
//                 placeholder="Enter Expense Type"
//                 type="text"
//               />
//             </Form.Item>

//             <Form.Item
//               name="amount"
//               label="Amount"
//               rules={[
//                 ({ getFieldValue }) => ({
//                   validator(_, value) {
//                     return validateTextLength(_, value, 8, "Amount");
//                   },
//                 }),
//               ]}
//             >
//               <Input
//                 showCount
//                 maxLength={8}
//                 onChange={(e) => setAmount(e.target.value)}
//                 type="number"
//                 placeholder="Enter Amount"
//               />
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row>
//           {props.mode === "post" ? null : <Col sm={6} xs={12}></Col>}
//           <Col sm={12} xs={24}>
//             <Row style={{ display: "flex", justifyContent: "space-between" }}>
//               <Col sm={11} xs={24}>
//                 <Button onClick={props.onClose} block>
//                   Cancel
//                 </Button>
//               </Col>
//               <Col sm={11} xs={24}>
//                 <Button
//                   type="primary"
//                   htmlType="submit"
//                   block
//                   loading={loading}
//                 >
//                   Submit
//                 </Button>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </Form>
//     </Drawer>
//   );
// }

// export default FormModal;

import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  message,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
var width = window.innerWidth;
const { Option } = Select;
function FormModal(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    let requestObj = {
      userid: Number(values.userid),
      note: values.note,
      head: values.head,
      amount: Number(values.amount),
      date: values.date,
      created_by: parseInt(Auth.id),
    };

    let URL =
      props.mode === "post"
        ? API.EXPANCE_ENTRY_ACTIONS
        : API.EXPANCE_ENTRY_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  return (
    <div>
      <Drawer
        placement={props.mode === "post" ? "right" : "left"}
        title={
          <span className="txt4">
            {props.mode === "post" ? "New" : "Update"} Expance
          </span>
        }
        width={width / 2.7}
        onClose={() => props.onClose()}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          layout="horizontal"
          hideRequiredMark
          onFinish={onFinish}
          initialValues={{
            userid: props.item && props.item.userid,
            note: props.item && props.item.note,
            head: props.item && props.item.head,
            amount: props.item && props.item.amount,
            date: props.item && moment(props.item.date),
            // created_by: props.item && props.item.userid,
          }}
        >
          <Container>
            <Row>
              <Form.Item
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 16,
                }}
                name="date"
                label="Date"
                rules={[{ required: true, message: "required" }]}
              >
                <DatePicker
                  placeholder="Pick a date"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 16,
                }}
                name="userid"
                label={<div className="form-item-label">User</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  placeholder="Select User"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLocaleLowerCase()) >= 0
                  }
                >
                  {props.user.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 16,
                }}
                name="head"
                label={<div className="form-item-label">Head</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="" />
              </Form.Item>

              <Form.Item
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 16,
                }}
                name="note"
                label={<div className="form-item-label">Remarks</div>}
                rules={[{ required: false, message: "required" }]}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 16,
                }}
                name="amount"
                label={<div className="form-item-label">Amount</div>}
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber placeholder="enter amount" />
              </Form.Item>
            </Row>
          </Container>
          <Row>
            <Col sm="6" xs="12"></Col>
            <Col sm="3" xs="12">
              <Button block onClick={() => props.onClose()}>
                Cancel
              </Button>
            </Col>
            <Col sm="3" xs="12">
              <Button block type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default FormModal;
