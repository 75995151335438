import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, message } from "antd";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { uploadFile } from "react-s3";
import { API, Headers } from "../../Config";
import S3con from "../../Config/S3con";
import ImgRotateAndCrop from "./ImgRotateAndCrop";
import { b64toBlob, blobToFile } from "./FileUtil";

var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
function UploadKYCModal(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const [croppedData, setCroppedData] = useState();
  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  const config = {
    bucketName: S3con.bucketName,
    region: S3con.region,
    accessKeyId: S3con.accessKeyId,
    secretAccessKey: S3con.secretAccessKey,
  };

  const onCropAccept = (_croppedData) => {
    setCroppedData(_croppedData);
  };

  const uploadFiletoS3 = (file) => {
    uploadFile(file, config).then((data) => {
      console.log(data);
      if (props.fileType === "adhar") {
        uploadToServer(data.location, true, false, false, false);
      }
      if (props.fileType === "adhar_back") {
        uploadToServer(data.location, false, true, false, false);
      }
      if (props.fileType === "iqama") {
        uploadToServer(data.location, false, false, true, false);
      }
      if (props.fileType === "signature") {
        uploadToServer(data.location, false, false, false, true);
      }
    });
  };

  const uploadToServer = (loc, isAdhar, isAdharBack, isIqama, isSign) => {
    let requestObj = {
      airway_bill_no: props.record.airway_bill_no
        ? Number(props.record.airway_bill_no)
        : 0,
      collection_id: parseInt(props.record.collection_id),
      userid: parseInt(props.record.userid),
      size: parseInt(props.record.size),
      adhar: isAdhar ? loc : props.record.adhar,
      signature: isSign ? loc : props.record.signature,
      is_adhar_uploded: isAdhar ? isAdhar : props.record.is_adhar_uploded,
      adhar_back: isAdharBack ? loc : props.record.adhar_back,
      is_adhar_back_uploded: isAdharBack
        ? isAdharBack
        : props.record.is_adhar_back_uploded,
      iqama: isIqama ? loc : props.record.iqama,
      is_iqama_uploded: isIqama ? isIqama : props.record.is_iqama_uploded,
      status: isAdhar ? 29 : isAdharBack ? 31 : 30,
      updated_by: Number(Auth.id),
      created_from: "web",
      image_1: loc,
    };

    let BoxID = props.record && props.record.box_number;
    let URL = API.BASR_URL + API.BOXES_ACTIONS + BoxID;

    axios({
      method: "put",
      url: URL,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        setLoading(false);
        props.setStatus(false);
        props.search ? props.searchData() : props.onChange();
        props.onClose();
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setLoading(false);
        props.setStatus(false);
        console.log(error);
      });
  };

  const handleUpload = () => {
    setLoading(true);
    props.setStatus(true);

    // props.onClose();
    const filename =
      props.record && props.record.id + props.fileType + moment.now();
    const blob = b64toBlob(croppedData, "image/png");
    const file = blobToFile(blob, filename);
    uploadFiletoS3(file);
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={false}
      width={innerwidth ? width : width / 1.6}
    >
      <div className="UploadKYCModal-txt1">
        UPLOAD{" "}
        <span style={{ textTransform: "uppercase" }}>
          {props.fileType === "adhar_back" ? "ADHAR BACK" : props.fileType}
        </span>
      </div>
      <br />
      <div className="">
        {(update && props.fileType === "adhar" && props.record.adhar) ||
        (update &&
          props.fileType === "adhar_back" &&
          props.record.adhar_back) ||
        (update && props.fileType === "iqama" && props.record.iqama) ||
        (update && props.fileType === "signature" && props.record.signature) ? (
          <>
            <div className="UploadKYCModal-box1">
              <div>
                <img
                  src={
                    props.fileType === "adhar"
                      ? props.record.adhar
                      : props.fileType === "adhar_back"
                      ? props.record.adhar_back
                      : props.fileType === "iqama"
                      ? props.record.iqama
                      : props.fileType === "signature"
                      ? props.record.signature
                      : ""
                  }
                  className="UploadKYCModal-image1"
                  alt="KYC DOCUMENTS"
                />
              </div>
            </div>
            <div>
              <br />
              <Row>
                <Col sm="6" xs="6"></Col>
                <Col sm="3" xs="3">
                  <Button
                    block
                    type="primary"
                    onClick={() => setUpdate(!update)}
                    style={{ borderRadius: "5px" }}
                  >
                    Update
                  </Button>
                </Col>
                <Col sm="3" xs="3">
                  <Button block onClick={props.onClose}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <div>
            <div>
              <ImgRotateAndCrop onCropAccept={onCropAccept} />
            </div>
            <br />
            <Row>
              <Col sm="6" xs="6"></Col>
              <Col sm="3" xs="3">
                <Button
                  loading={loading}
                  type="primary"
                  block
                  onClick={() => handleUpload()}
                  style={{ borderRadius: "5px" }}
                >
                  Upload
                </Button>
              </Col>
              <Col sm="3" xs="3">
                <Button block onClick={props.onClose}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UploadKYCModal;
