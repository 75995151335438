// import React, { useEffect, useState } from "react";
// import { Table } from "antd";
// import { API, Headers } from "../../Config";
// import axios from "axios";
// import { message } from "antd";
// import LoadingBox from "../../Components/LoadingBox";
// import PageHeader from "../../Routes/PageHeader";
// const TrialBalanceTable = () => {
//   const [trialBalance, setTrialBalance] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [indicator, setIndicator] = useState(false);
//   useEffect(() => {
//     // Fetch trial balance data from API or set it manually
//     trialBalanceData();
//   }, []);
//   const trialBalanceData = () => {
//     axios
//       .get(API.BASR_URL + `chart_of_accounts/TrialBalance`, {
//         headers: Headers(),
//       })
//       .then(function (response) {
//         console.log(response);
//         if (response.status === 200) {
//           let data = response.data;
//           setTrialBalance(data);

//           message.success("Success");
//         } else {
//           message.error("Oops.Something went wrong");
//         }
//         setLoading(false);
//         setIndicator(false);
//       })
//       .catch(function (error) {
//         message.error("Oops.Something went wrong");
//         setIndicator(false);
//         setLoading(false);
//       });
//   };
//   const columns = [
//     {
//       title: "#",
//       key: "index",
//       width: "20px",
//       render: (text, record, index) => index + 1,
//     },
//     {
//       title: "Account No",
//       dataIndex: "account_no",
//       key: "account_no",
//     },
//     {
//       title: "Account Name",
//       dataIndex: "account_name",
//       key: "account_name",
//     },
//     {
//       title: "Debit",
//       dataIndex: "debit",
//       key: "debit",
//       render: (item) => <span>{Number(item).toFixed(2)}</span>,
//     },
//     {
//       title: "Credit",
//       dataIndex: "credit",
//       key: "credit",
//       render: (item) => <span> {Number(item).toFixed(2)}</span>,
//     },
//     {
//       title: "Balance",
//       dataIndex: "balance",
//       key: "balance",
//       render: (item) => <span> {Number(item).toFixed(2)}</span>,
//     },
//   ];

//   const totalDebit = trialBalance.reduce((sum, entry) => sum + entry.debit, 0);
//   const totalCredit = trialBalance.reduce(
//     (sum, entry) => sum + entry.credit,
//     0
//   );

//   const summaryRow = {
//     account_no: "",
//     account_name: "Total",
//     debit: totalDebit.toFixed(2),
//     credit: totalCredit.toFixed(2),
//     balance: totalCredit - totalDebit,
//   };

//   return (
//     <div>
//       <PageHeader
//         PageName={"Trial Balance"}
//         breadScrum={"Admin / Trial Balance"}
//         // RightButton={"New Groups "}
//         // RightClcik={() => AddItem()}
//         loader={indicator}
//       />
//       {loading ? (
//         <LoadingBox />
//       ) : (
//         <div>
//           <Table
//             columns={columns}
//             dataSource={[...trialBalance, summaryRow]}
//             pagination={false}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default TrialBalanceTable;
import React, { useEffect, useState } from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { API, Headers } from "../../Config";
import axios from "axios";
import { message } from "antd";
import LoadingBox from "../../Components/LoadingBox";
import PageHeader from "../../Routes/PageHeader";

const TrialBalanceTable = () => {
  const [trialBalance, setTrialBalance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    // Fetch trial balance data from API or set it manually
    trialBalanceData();
  }, []);

  const trialBalanceData = () => {
    axios
      .get(API.BASR_URL + `chart_of_accounts/TrialBalance`, {
        headers: Headers(),
      })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          let data = response.data;
          setTrialBalance(data);
          message.success("Success");
        } else {
          message.error("Oops. Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops. Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type="button"
            onClick={() => handleReset(clearFilters)}
            style={{ width: "calc(50% - 8px)" }}
          >
            Reset
          </button>
          <button
            type="button"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: "calc(50% - 8px)" }}
          >
            Search
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: "#",
      key: "index",
      width: "20px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Account No",
      dataIndex: "account_no",
      key: "account_no",
      ...getColumnSearchProps("account_no"),
    },

    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      ...getColumnSearchProps("account_name", "Search Account Name"),
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (item) => <span>{Number(item).toFixed(2)}</span>,
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (item) => <span> {Number(item).toFixed(2)}</span>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (item) => <span> {Number(item).toFixed(2)}</span>,
    },
  ];

  const totalDebit = trialBalance.reduce((sum, entry) => sum + entry.debit, 0);
  const totalCredit = trialBalance.reduce(
    (sum, entry) => sum + entry.credit,
    0
  );

  const summaryRow = {
    account_no: "",
    account_name: "Total",
    debit: totalDebit.toFixed(2),
    credit: totalCredit.toFixed(2),
    balance: (totalCredit - totalDebit).toFixed(2),
  };

  const handlePrint = () => {
    window.print();
  };
  const handleDownloadStatement = () => {
    axios
      .get(API.BASR_URL + "statements/trial-balance", {
        responseType: "blob", // Set the response type to blob
        headers: Headers(),
      })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "trial_balance_statement.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {
        console.error(error);
        message.error(
          "Oops. Something went wrong while downloading the statement"
        );
      });
  };
  return (
    <div>
      <PageHeader
        PageName={"Trial Balance"}
        breadScrum={"Admin / Trial Balance"}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <Button
            type="primary"
            onClick={handleDownloadStatement}
            style={{ marginBottom: 16 }}
          >
            Print
          </Button>
          <Table
            columns={columns}
            dataSource={[...trialBalance, summaryRow]}
            pagination={false}
          />
          <div style={{ marginTop: 16, fontWeight: "bold" }}>
            Total Debit: {totalDebit.toFixed(2)} | Total Credit:{" "}
            {totalCredit.toFixed(2)} | Balance:{" "}
            {(totalCredit - totalDebit).toFixed(2)}
          </div>
        </div>
      )}
    </div>
  );
};

export default TrialBalanceTable;
