const styles = {
  SucessButton: {
    background: "#BFFFD1",
    borderColor: "#BFFFD1",
    fontSize: "11px",
    borderRadius: "4px",
  },
  SucessButtonMiddle: {
    background: "#BFFFD1",
    borderColor: "#BFFFD1",
    fontSize: "11px",
    borderRadius: "4px",
    marginInline: 5,
  },
  ErrorButton: {
    background: "#FFD3D3",
    borderColor: "#FFD3D3",
    fontSize: "11px",
    borderRadius: "4px",
  },
  ErrorButtonMiddle: {
    background: "#FFD3D3",
    borderColor: "#FFD3D3",
    fontSize: "11px",
    borderRadius: "4px",
    marginInline: 5,
  },
  RotateButton: {
    background: "#24a0ed ",
    border: 0,
    color: "#fff",
    height: "auto",
    marginRight: "5px",
  },
  UpdateKYCButton: {
    background: "#BFFFD1",
    borderColor: "#BFFFD1",
    fontSize: "14px",
    borderRadius: "4px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  TransactionButtons: {
    background: "#89d1fe",
    borderColor: "#89d1fe",
    borderRadius: "4px",
    margin: 1,
  },
  PrintButton: {
    background: "#AFF1FF",
    borderColor: "#AFF1FF",
    fontSize: "14px",
    borderRadius: "4px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  OddPrintButtons: {
    background: "#F5F5F5",
    borderColor: "#F5F5F5",
  },
  EvenPrintButtons: {
    background: "#fff",
    borderColor: "#fff",
  },
  DeleteButton: {
    background: "#D9D9D9",
  },
  Editbutton: {
    background: "#D9D9D9",
    marginInline: 3,
  },
  FormItemInput: {
    borderRadius: 8,
    padding: 10,
    width: "100%",
    borderColor: "1px #f5f5f5",
    textTransform: "uppercase",
  },
};
export default styles;
