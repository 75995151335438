import React, { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Modal, message, Tooltip, notification } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import { IoIosClose } from "react-icons/io";
import { API, Headers } from "../../Config";
import styles from "./styles";
import altImage from "../../Assets/Images/altimage.png";

import { Form, Button, Input } from "antd";

import { useSelector } from "react-redux";

var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;

function KYCupdate(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      airway_bill_no: props.item.airway_bill_no
        ? Number(props.item.airway_bill_no)
        : 0,
      // airway_bill_no: 0,
      // box_id: parseInt(props.item.id),
      iqama_name: values.iqama_name,
      iqama_number: values.iqama_number,
      address: values.address,
      sender_mobile: values.sender_mobile,
      reciver_mobile1: values.reciver_mobile1,
      adhar_no: values.adhar_no,
      adhar_name: values.adhar_name,
      adhar_address: values.adhar_address,
      pin: values.pin,
      state: values.state,
      district: values.district,
      country: values.country,
      status: 26,
      updated_by: Number(Auth.id),
      created_from: "web",
    };

    axios({
      method: "put",
      url: API.BASR_URL + API.BOXES_ACTIONS + props.item.box_number,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          setLoading(false);
          props.search ? props.searchData() : props.onChange();
          props.onClose();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
        notification.open({
          message: "ERROR",
          description: `${error.response.data.message}`,
        });
      });
  };

  console.log(props.item);

  return (
    <div>
      <Modal
        title="Update Details"
        visible={props.visible}
        onCancel={() => props.onClose()}
        width={innerwidth ? width : width / 1.4}
        footer={null}
        closeIcon={<IoIosClose color="#fff" size={30} />}
      >
        <Container>
          <Row>
            <Form
              onFinish={onFinish}
              initialValues={{
                iqama_name: props.item && props.item.iqama_name,
                iqama_number: props.item && props.item.iqama_number,
                address: props.item && props.item.address,
                sender_mobile: props.item && props.item.sender_mobile,
                adhar_name: props.item && props.item.adhar_name,
                adhar_no: props.item && props.item.adhar_no,
                reciver_mobile1: props.item && props.item.reciver_mobile1,
                adhar_address: props.item && props.item.adhar_address,
                pin: props.item && props.item.pin,
                state: props.item && props.item.state,
                district: props.item && props.item.district,
                country:
                  props.item &&
                  (props.item.country && props.item.country.length > 2
                    ? props.item.country
                    : props.item.collection &&
                      props.item.collection.recivercountries &&
                      props.item.collection.recivercountries.name),

                // country:
                //   props.item.country.length > 2
                //     ? props.item && props.item.country
                //     : props.item &&
                //       props.item.collection &&
                //       props.item.collection.recivercountries &&
                //       props.item.collection.recivercountries.name,
              }}
            >
              <Row>
                <Col xs={3}>
                  <Row>
                    <div className="kyc-update-txt1">Iqama</div>
                    {props.item.adhar ? (
                      <Tooltip title="Use your Mouse scroll wheel to Zoomin & ZoomOUt">
                        <div className="kyc-update-image-holder">
                          <TransformWrapper>
                            <TransformComponent>
                              <img
                                src={props.item.iqama}
                                className="kyc-update-image"
                                alt="iqama.png"
                              />
                            </TransformComponent>
                          </TransformWrapper>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="kyc-update-avatar">
                        <img
                          src={altImage}
                          className="kyc-update-alt-image"
                          alt="kyc-documents"
                        />
                        <div className="kyc-update-txt2">No Data found...</div>
                      </div>
                    )}
                  </Row>

                  <br />
                  <Row>
                    <div className="kyc-update-txt1">Adhar</div>
                    {props.item.adhar ? (
                      <Tooltip
                        title={
                          "Use your Mouse scroll wheel to Zoomin & ZoomOUt"
                        }
                      >
                        <div className="kyc-update-image-holder">
                          <TransformWrapper>
                            <TransformComponent>
                              <img
                                src={props.item.adhar}
                                alt="adhar.png"
                                className="kyc-update-image"
                              />
                            </TransformComponent>
                          </TransformWrapper>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="kyc-update-avatar">
                        <img
                          src={altImage}
                          className="kyc-update-alt-image"
                          alt="kyc-documents"
                        />
                        <div className="kyc-update-txt2">No Data found...</div>
                      </div>
                    )}
                  </Row>

                  <br />
                  <Row>
                    <div className="kyc-update-txt1">Adhar Back</div>
                    {props.item.adhar_back ? (
                      <Tooltip
                        title={
                          "Use your Mouse scroll wheel to Zoomin & ZoomOUt"
                        }
                      >
                        <div className="kyc-update-image-holder">
                          <TransformWrapper>
                            <TransformComponent>
                              <img
                                src={props.item.adhar_back}
                                alt="adharBack.png"
                                className="kyc-update-image"
                              />
                            </TransformComponent>
                          </TransformWrapper>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="kyc-update-avatar">
                        <img
                          src={altImage}
                          className="kyc-update-alt-image"
                          alt="kyc-documents"
                        />
                        <div className="kyc-update-txt2">No Data found...</div>
                      </div>
                    )}
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col sm="6" xs="12">
                      <div className="formlabel">Iqama Number</div>
                      <Form.Item name="iqama_number" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">Iqama Name</div>
                      <Form.Item name="iqama_name" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">Address</div>
                      <Form.Item name="address" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">Mobile</div>
                      <Form.Item name="sender_mobile" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">Adhar number</div>
                      <Form.Item name="adhar_no" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">Adhar name</div>
                      <Form.Item name="adhar_name" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">Adhar Address</div>
                      <Form.Item name="adhar_address" label="">
                        <Input.TextArea
                          placeholder=""
                          style={styles.FormItemInput}
                          rows={1}
                        />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">Mobile number</div>
                      <Form.Item name="reciver_mobile1" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">PIN</div>
                      <Form.Item name="pin" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">District</div>
                      <Form.Item name="district" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">State</div>
                      <Form.Item name="state" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>

                    <Col sm="6" xs="12">
                      <div className="formlabel">Country</div>
                      <Form.Item name="country" label="">
                        <Input placeholder="" style={styles.FormItemInput} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm="6" xs="12"></Col>
                <Col sm="3" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="3" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    size=""
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </Modal>
    </div>
  );
}
export default KYCupdate;
