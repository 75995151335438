import React, { useRef } from "react";
import { Modal, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";

function PrintKYCModal(props) {
  const componentRef = useRef();
  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        width={850}
        footer={false}
      >
        <br />
        <div className="PrintDocumentModal-page" ref={componentRef}>
          <div className="PrintDocumentModal-pageHeader">
            <div>
              <h6>Box Number : {props.item.box_number}</h6>
            </div>
            <div>
              <Barcode value={props.item.box_number} height="30" />
            </div>
          </div>
          <hr />
          <div className="PrintDocumentModal-Box">
            <div>
              <img
                key={props.item.id}
                src={props.item.adhar}
                className="print-document-image"
                alt="adhar-front"
              />
            </div>
            <br />
            <div>
              <img
                key={props.item.id}
                src={props.item.adhar_back}
                className="print-document-image"
                alt="adhar-back"
              />
            </div>
            <div>
              <img
                key={props.item.id}
                src={props.item.iqama}
                className="print-document-image"
                alt="iqama-front"
              />
            </div>
          </div>
        </div>
        <br />
        <Row>
          <Col xs="6" sm="6"></Col>
          <Col xs="3" sm="3">
            <Button onClick={() => props.onCancel()} block size="large">
              Cancel
            </Button>
          </Col>
          <Col xs="3" sm="3">
            <ReactToPrint
              trigger={() => (
                <Button type="primary" size="large" block>
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default PrintKYCModal;
