import React, { useEffect, useState } from "react";
import { Button, Input, Timeline, Steps, Table, Collapse, Form } from "antd";
import { Row, Col } from "react-bootstrap";
import "./styles.css";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
// import { LuBox, LuTruck } from "react-icons/lu";
import { BsTruck } from "react-icons/bs";
import { SiHackthebox } from "react-icons/si";
import { FaBan } from "react-icons/fa";
import CustomTimeline from "./customTimeline";
import axios from "axios";
// import { PiHeadsetDuotone } from "react-icons/pi";

const DelhiveryTracking = () => {
  const [tracking, setTracking] = useState([]);
  console.log(tracking);

  //   useEffect(() => {
  //     fetchData();
  //   }, [orderId]);

  const fetchData = async (val) => {
    try {
      let res = await axios.get(
        `https://dlv-api.delhivery.com/v3/unified-tracking?wbn=${val?.orderId}`
      );
      setTracking(res?.data?.data);
      console.log("res", res.data);
    } catch (err) {
      console.error(err);
    }
  };
  const items = [
    {
      children: (
        <div>
          <span>
            <SiHackthebox size={25} />
          </span>
          <span className="ms-2"> Picked Up</span>
        </div>
      ),
    },
    {
      children: (
        <div>
          {" "}
          <span>
            <BsTruck size={25} />
          </span>
          <span className="ms-2"> In-transit 14 Oct 2023, Morning</span>
        </div>
      ),
    },
    {
      children: (
        <div>
          <span>
            {" "}
            <FaBan size={25} color="green" />
          </span>
          <span className="ms-2"> Delivered 14 Oct 2023, Afternoon</span>
          {/* <div>rishad</div> */}
        </div>
      ),
    },
    {
      children: (
        <div>
          <span>
            {" "}
            <FaBan size={25} color="red" />
          </span>
          <span className="ms-2">Out For Delivery 14 Oct 2023, Morning</span>
        </div>
      ),
    },
  ];
  const columns = [
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  const data = [
    {
      key: "1",
      city: "14 Oct 2023, Morning",
      status: "Hojai	Our executive is out for delivery",
    },
  ];
  return (
    <div>
      <div className="trackOrderInputHead">
        <Form onFinish={fetchData}>
          <Form.Item name={"orderId"}>
            <Input />
          </Form.Item>
          <div className="trackBtnHead mt-2">
            <Form.Item>
              <Button htmlType="submit">Track Order</Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <br />
      {tracking?.length ? (
        <div>
          <span>
            <strong>TRACKING ID :</strong>
          </span>
          <span className="ms-1">{tracking[0]?.awb}</span>
          <hr />
          <Row>
            <Col>
              <div className="trackBox">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "80%",
                    padding: "18px 26px 16px",
                    // padding: "15px",
                  }}
                >
                  <div>
                    <div>{tracking[0]?.deliveryDateText}</div>
                    <div className="boldTxt">{tracking[0]?.deliveryDate}</div>
                    <div className="mt-2">Payment Terms</div>
                    <div className="boldTxt">{tracking[0]?.paymentTerms}</div>
                  </div>
                  <button className="deliveredBtn mt-3">
                    {tracking[0]?.hqStatus}
                  </button>
                </div>
                {/* <div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#343435",
                    height: "20%",
                    // padding: "15px",
                    padding: "20px 26px 16px",
                    borderRadius: "0px 0px 6px 6px",
                  }}
                >
                  <div>No. of packages : {tracking[0]?.bag_count}</div>
                  <div>
                    Order details <IoIosArrowForward />
                  </div>
                </div>
                {/* </div> */}
              </div>
              <br />
              <div className="trackingDetailsBox">
                <div className="heading">Tracking details</div>
                <hr />
                <div>
                  <CustomTimeline items={items} />
                  <div className="helpBox mt-5">
                    <div
                      style={{
                        width: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <PiHeadsetDuotone size={25} /> */}
                    </div>
                    <div
                      style={{
                        width: "75%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="helpTxtBold">
                        Have a query regarding this shipment?
                      </div>
                      <div>
                        <span style={{ color: "blue", fontWeight: "bold" }}>
                          Tell us here
                        </span>{" "}
                        (or) Call us at{" "}
                        <span style={{ color: "red" }}>+918069856101</span>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </Col>
            <Col>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className="trackingDetailsBox">
                <div className="heading">Tracking details</div>
                <hr />
                <div>
                  <Steps
                    progressDot
                    current={1}
                    direction="vertical"
                    items={[
                      {
                        title: (
                          <div>
                            <span>{/* <LuBox size={25} /> */}</span>
                            <span className="ms-2 mb-3">
                              {" "}
                              <stron>Picked Up</stron>
                            </span>
                          </div>
                        ),
                      },
                      {
                        title: (
                          <div>
                            {" "}
                            <span>{/* <LuTruck size={25} /> */}</span>
                            <span className="ms-2">
                              {" "}
                              <strong>In-transit</strong> 14 Oct 2023, Morning
                            </span>
                            <Collapse
                              className="mt-3 mb-3"
                              size="small"
                              style={{ borderRadius: "6px" }}
                            >
                              <Collapse.Panel
                                header="1 update available"
                                key="1"
                              >
                                <Table
                                  columns={columns}
                                  dataSource={data}
                                  bordered={false}
                                />{" "}
                              </Collapse.Panel>
                            </Collapse>{" "}
                          </div>
                        ),
                      },
                      {
                        title: (
                          <div>
                            <span>
                              {" "}
                              <FaBan size={25} color="green" />
                            </span>
                            <span className="ms-2">
                              <strong> Delivered </strong>14 Oct 2023, Afternoon
                            </span>
                            <Collapse
                              className="mt-3 mb-3"
                              size="small"
                              style={{ borderRadius: "6px" }}
                            >
                              <Collapse.Panel
                                header="1 update available"
                                key="1"
                              >
                                <Table
                                  columns={columns}
                                  dataSource={data}
                                  bordered={false}
                                />{" "}
                              </Collapse.Panel>
                            </Collapse>{" "}
                          </div>
                        ),
                      },
                      {
                        title: (
                          <div>
                            <span>
                              {" "}
                              <FaBan size={25} color="red" />
                            </span>
                            <span className="ms-2">
                              <strong>Out For Delivery </strong>14 Oct 2023,
                              Morning
                            </span>
                            <Collapse
                              className="mt-3"
                              size="small"
                              style={{ borderRadius: "6px" }}
                            >
                              <Collapse.Panel
                                header="1 update available"
                                key="1"
                              >
                                <Table
                                  columns={columns}
                                  dataSource={data}
                                  bordered={false}
                                />{" "}
                              </Collapse.Panel>
                            </Collapse>{" "}
                            <div
                              className="mt-3"
                              style={{
                                backgroundColor: "#dbf0e6",
                                textAlign: "center",
                                borderRadius: "4px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                padding: "10px 8px",
                              }}
                            >
                              Your order was delivered on 14 Oct 2023, Afternoon
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="helpBox mt-5">
                  <div
                    style={{
                      width: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <PiHeadsetDuotone size={25} /> */}
                  </div>
                  <div
                    style={{
                      width: "75%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div className="helpTxtBold">
                      Have a query regarding this shipment?
                    </div>
                    <div>
                      <span style={{ color: "blue", fontWeight: "bold" }}>
                        Tell us here
                      </span>{" "}
                      (or) Call us at{" "}
                      <span style={{ color: "red" }}>+918069856101</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div>Hai</div>
      )}
    </div>
  );
};

export default DelhiveryTracking;
