import React, { useEffect, useState } from "react";
import { message, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import moment from "moment";
import LoadingBox from "../../Components/LoadingBox";
import { API, Headers } from "../../Config";
import { GET } from "../../Config/ApiRequests";
import PageHeader from "../../Routes/PageHeader";
import SalesDataTable from "./dataTable";
import MenuBarSales from "./menu";
import SalesExpenceTable from "./expenceTable";
import SalespaymentModal from "./paymentModal";
import "./styles.css";
const SalesReport = () => {
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [expense, setExpences] = useState();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState({});
  const [dateFrom, setDateFrom] = useState("");
  const [search, setSearch] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [expanded, setExpanded] = useState("panel1");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, panel]);
    } else {
      setExpanded(expanded.filter((item) => item !== panel));
    }
  };

  const onFinish = async (val) => {
    const date = moment(val?.date).toISOString() || "";
    setSearch(val?.search || "");
    setDateFrom(date);
    await loadUsersDatas(page, take, dateFrom, val.search);
  };

  const handleSubmit = () => {
    setSelectedRows(data);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadUsersDatas = (p, t, date) => {
    try {
      if (!date && !search) {
        setData([]);
        setMeta({});
        return;
      }
      setLoading(true);
      let URL = API.BOXES_VIA_BRANCH_BY_BOXDETAILS + `${Number(Auth.id)}`;
      let query = `?order=DESC&page=${p}&take=${t}`;
      if (date) query += `&date=${date}`;
      if (search) query += `&search=${search}`;
      axios
        .get(API.BASR_URL + URL + query, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setData(data);
            setMeta(data?.meta);
            message.success("Success");
          }
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    loadUsersDatas(page, take);
    getAllUsers();
  }, []);

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setPage(page);
    setTake(take);
    loadUsersDatas(page, take, dateFrom, search, allUsers);
  };

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const api = API.ALL_USERS;
      const users = await GET(api, null);
      if (users?.status) {
        setAllUsers(users?.data);
        setLoading(false);
      } else {
        setAllUsers([]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const AccountsDropdown = () => {
    axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/child_accounts`, {
        headers: new Headers(),
      })

      .then(function (res) {
        if (res.status === 200) setAccounts(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };

  useEffect(() => {
    AccountsDropdown();
    loadData();
  }, [search]);

  const loadData = () => {
    setLoading(true);
    let api = `${API.BASR_URL}expance_entry/byUser/${search}?order=DESC&page=1&take=30`;

    fetch(api, {
      method: "get",
      headers: Headers(),
    })
      .then((data) => data.json())
      .then((exp) => {
        setExpences(exp.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Service Error ===>>", error);
      });
  };

  const totalExpense = expense?.reduce(
    (acc, item) => acc + parseFloat(item?.amount),
    0
  );
  const totalBoxCharge = data?.data?.reduce(
    (acc, item) => acc + parseFloat(item?.total_charge),
    0
  );

  return (
    <div>
      <PageHeader PageName={"Report"} breadScrum={"Admin / Report / Sales"} />
      <MenuBarSales onFinish={(val) => onFinish(val)} allUsers={allUsers} />
      <div className="d-flex justify-content-end my-3" />
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <Accordion
            expanded={expanded.includes("panel1")}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="new-collection-form-txt1">Boxes Details</div>
            </AccordionSummary>
            <AccordionDetails>
              <SalesDataTable
                data={data?.data}
                PageOnchange={(page, take) => pagination(page, take)}
                meta={meta}
                onSelect={setSelectedRows}
                onAcounts={setAccounts}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded.includes("panel2")}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="new-collection-form-txt1">Expense Details</div>
            </AccordionSummary>
            <AccordionDetails>
              <SalesExpenceTable expense={expense} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded.includes("panel3")}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="new-collection-form-txt1">Summary Details</div>
            </AccordionSummary>
            <AccordionDetails>
              <div>Total Expense - {totalExpense}</div>
              <div>Total Box Amount - {totalBoxCharge}</div>
              <div>Total - {totalExpense + totalBoxCharge}</div>
            </AccordionDetails>
          </Accordion>
          <br />
          <Row>
            <Col sm="6" xs="6" />
            <Col sm="3" xs="3">
              <Button block size="large">
                Cancel
              </Button>
            </Col>
            <Col sm="3" xs="3">
              <Button
                loading={loading}
                block
                size="large"
                type="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Col>
          </Row>
          {isModalOpen && (
            <SalespaymentModal
              visible={isModalOpen}
              onCancel={handleCancel}
              selectedRows={selectedRows}
              accounts={accounts}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SalesReport;
