import { StyleSheet, Font } from "@react-pdf/renderer";
import MontserratRegular from "../../../Assets/Fonts/Montserrat-Regular.ttf";
import MontserratMedium from "../../../Assets/Fonts/Montserrat-Medium.ttf";
import MontserratBold from "../../../Assets/Fonts/Montserrat-Bold.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratRegular,
      fontWeight: 200,
    },
    {
      src: MontserratMedium,
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  Page: {
    textTransform: "uppercase",
    padding: 20,
  },
  Heading: {
    display: "flex",
    flexDirection: "row",
  },
  Heading2: {
    display: "flex",
    flexDirection: "column",
  },
  Row: {
    display: "flex",
    flexDirection: "row",
  },
  Col: {
    flex: 1,
  },
  Col2: {
    flex: 1,
    textAlign: "right",
  },
  Tx1: {
    flex: 1,
    textAlign: "center",
    fontSize: 15,
    fontFamily: "Montserrat",
  },
  Txt2: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: 10,
  },
  Txt3: {
    fontFamily: "Montserrat",
    fontSize: 10,
    fontWeight: "bold",
  },
  Txt4: {
    fontFamily: "Montserrat",
    fontSize: 10,
  },
  Txt5: {
    fontFamily: "Montserrat",
    fontSize: 10,
  },
  Table: {
    width: "100%",
    border: "1px solid black",
    padding: 20,
  },
});

export default styles;
