import { useState, useEffect } from "react";
import { Pagination, Table, Tag } from "antd";
import moment from "moment";

const SalesDataTable = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
    props.onSelect(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",
  };

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setSelectedRowKeys(props.data.map((row) => row.id));
      setSelectedRows(props.data);
      props.onSelect(selectedRows);
      // props.onAcounts(accounts);
    }
  }, [props.data]);

  const columns = [
    {
      title: "DATE",
      dataIndex: "createdat",
      key: "createdat",
      render: (createdat) => (
        <div className="collection-table-item">
          {moment(createdat).format("l")}
        </div>
      ),
    },
    {
      title: "BOX NO",
      dataIndex: "box_number",
      key: "box_number",
      render: (text, record) => <div>{record?.box_number}</div>,
    },

    {
      title: "WEIGHT",
      dataIndex: "chargable_weight",
      key: "chargable_weight",
      render: (text, record) => (
        <>
          <div>{record?.chargable_weight}</div>
        </>
      ),
    },
    {
      title: "RATE",
      dataIndex: "total_charge",
      key: "total_charge",
      render: (text, record) => <div>{record?.total_charge}</div>,
    },

    {
      title: "PACKING CHARGE",
      dataIndex: "packing_charge",
      key: "packing_charge",
      render: (text, record) => (
        <>
          <div>{record?.packing_charge || 0}</div>
        </>
      ),
    },

    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text, record) => (
        <>
          <div>{record?.discount || 0}</div>
        </>
      ),
    },
    {
      title: "TOTAL",
      dataIndex: "total_pay",
      key: "total_pay",
      render: (text, record) => <>{record?.total_charge}</>,
    },

    {
      title: "PAID",
      dataIndex: "id",
      key: "id",
      render: (collnId, record) => {
        const transactions = record?.collection?.ledgerTransColln?.filter(
          (transaction) => transaction?.colln_id === collnId
        );

        const crAmount = transactions?.reduce((total, e) => {
          if (e.cr_or_dr === "Cr" && !isNaN(parseFloat(e.amount))) {
            return total + parseFloat(e.amount);
          }
          return total;
        }, 0);

        return crAmount?.toFixed(2);
      },
    },
    {
      title: "BALANCE",
      dataIndex: "id",
      key: "id",
      render: (collnId, record) => {
        const transactions = record?.collection?.ledgerTransColln?.filter(
          (transaction) => transaction.colln_id === collnId
        );

        const crAmount = transactions?.reduce((total, e) => {
          if (e.cr_or_dr === "Cr" && !isNaN(parseFloat(e.amount))) {
            return total + parseFloat(e.amount);
          }
          return total;
        }, 0);

        const drAmount = transactions?.reduce((total, e) => {
          if (e.cr_or_dr === "Dr" && !isNaN(parseFloat(e.amount))) {
            return total + parseFloat(e.amount);
          }
          return total;
        }, 0);

        const balance = drAmount - crAmount;

        return <span style={{ color: "red" }}>{balance?.toFixed(2)}</span>;
      },
    },
    {
      title: "STATUS",
      dataIndex: "id",
      key: "id",
      render: (collnId, record) => {
        const transactions = record?.collection?.ledgerTransColln.filter(
          (transaction) => transaction?.colln_id === collnId
        );

        if (transactions.length === 0) {
          return (
            <Tag style={{ borderRadius: "10px" }} color="default">
              No Trans
            </Tag>
          );
        }

        const drAmount = transactions.reduce((total, e) => {
          if (e.cr_or_dr === "Dr") {
            const amount = parseFloat(e.amount) || 0;
            const roundOff = parseFloat(e.round_off) || 0;
            return total + amount + roundOff;
          }
          return total;
        }, 0);

        const crAmount = transactions.reduce((total, e) => {
          if (e.cr_or_dr === "Cr") {
            const amount = parseFloat(e.amount) || 0;
            const roundOff = parseFloat(e.round_off) || 0;
            return total + amount + roundOff;
          }
          return total;
        }, 0);

        if (drAmount === crAmount) {
          return (
            <Tag style={{ borderRadius: "10px" }} color="success">
              Complete
            </Tag>
          );
        } else if (crAmount > 0) {
          return (
            <Tag style={{ borderRadius: "10px" }} color="processing">
              Partial
            </Tag>
          );
        } else {
          return (
            <Tag style={{ borderRadius: "10px" }} color="warning">
              Not Paid
            </Tag>
          );
        }
      },
    },

    {
      title: "CREATEAD BY",
      dataIndex: "",
      key: "",
      render: (text, record) => <div>{record?.created_user?.name}</div>,
    },

    // {
    //   title: "Actions",
    //   fixed: "right",
    //   align: "center",
    //   render: (item) => (
    //     <div className="tableAction">
    //       <Button type="primary">
    //         {/* onClick={() => showModal([item])} */}
    //         Close Payment
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={columns}
        dataSource={props?.data}
        pagination={false}
        size="small"
        rowKey={(record) => record?.id}
        rowSelection={rowSelection}
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props?.meta && props.meta.page}
          total={props?.meta && props?.meta?.itemCount}
          pageSize={props?.meta && props?.meta?.take ? props?.meta?.take : 0}
          onChange={props?.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
};

export default SalesDataTable;
