import { Table, Pagination, Button, Popconfirm, Tag } from "antd";
import moment from "moment";
function DataTable(props) {
  const columns = [
    { title: "Sl No.", dataIndex: "id", key: "id" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <div className="data-table-items">{text}</div>,
    },
    {
      title: "Short Name",
      dataIndex: "short_name",
      key: "short_name",
      render: (text) => <div className="data-table-items">{text}</div>,
    },
    {
      title: "length",
      dataIndex: "length",
      key: "length",
      render: (text) => <div className="data-table-items">{text}</div>,
    },
    {
      title: "breadth",
      dataIndex: "breadth",
      key: "breadth",
      render: (text) => <div className="data-table-items">{text}</div>,
    },
    {
      title: "height",
      dataIndex: "height",
      key: "height",
      render: (text) => <div className="data-table-items">{text}</div>,
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => (
        <div className="data-table-items">
          {moment(item).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: "",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;
