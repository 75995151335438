import React, { useState } from "react";
import { Form, Input, Select, Button, message } from "antd";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import styles from "./styles";
const { Option } = Select;
function OtherDetails(props) {
  const [data, setData] = useState(props.data);

  const onFinish = (values) => {
    setData(values);
    let next = Number(props.count);
    props.next(next, values);
    message.info("Other Details added");
    props.onTabchange("4");
  };

  const OnBack = () => {
    let back = Number(props.count) - 1;
    props.OnBack(back);
    props.onTabchange("2");
  };

  const handleTotal = (_, values) => {
    props.onchange(props.count, values);
  };

  return (
    <div>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onValuesChange={handleTotal}
        initialValues={
          props.mode === "put"
            ? {
                pickup_request_id: props.data && props.data.pickup_request_id,
                agent_id: props.data && props.data.agent_id,
                agent_name: props.item && props.item.agent_name,
                referance_details: props.item && props.item.referance_details,
                instructions: props.item && props.item.instructions,
                active: true,
                created_from: "web",
              }
            : {
                pickup_request_id: props.data && props.data.pickup_request_id,
                agent_id: props.data && props.data.agent_id,
                instructions: props.data && props.data.instructions,
                agent_name: props.data && props.data.agent_name,
                referance_details: props.data && props.data.referance_details,
                instructions: props.data && props.data.instructions,
                active: true,
                created_from: "web",
              }
        }
      >
        <Row>
          <Col sm="1" xs="12"></Col>
          <Col sm="5" xs="12">
            <div className="collection-customerform-txt1">Other Details</div>
          </Col>
        </Row>

        <Row>
          <Col sm="1" xs="12"></Col>
          <Col sm="5" xs="12">
            <Form.Item
              name="agent_id"
              label="Agent"
              rules={[{ required: true, message: "required" }]}
            >
              <Select
                bordered={false}
                className="CustomerForm-Select"
                placeholder="Select Agent"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {props.agents.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.user && option.user.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col sm="5" xs="12">
            <Form.Item
              name="referance_details"
              label={
                <div className="collection-customerform-formlabel">
                  Reference Details
                </div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input style={styles.FormItemOtherDetails} />
            </Form.Item>
          </Col>
          <Col sm="1" xs="12"></Col>
        </Row>
        <Row>
          <Col sm="1" xs="12"></Col>
          <Col sm="5" xs="12">
            <Form.Item
              name="instructions"
              label={
                <div className="collection-customerform-formlabel">
                  Instructions
                </div>
              }
              rules={[{ required: false, message: "required" }]}
            >
              <Input.TextArea style={styles.FormItemOtherDetails} />
            </Form.Item>
          </Col>
          <Form.Item
            name="created_from"
            style={{ visibility: "hidden", display: "none" }}
          >
            <Input defaultValue="created_from" value="web" />
          </Form.Item>
          <Col sm="1" xs="12"></Col>
        </Row>

        <br />
        <Row className="boxformFinalBtn">
          <Col sm="3" xs="6">
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={props.loading}
              size="large"
            >
              Submit
            </Button>
          </Col>
          <Col sm="3" xs="6">
            <Button
              onClick={() => OnBack()}
              block
              size="large"
              style={styles.BackButton}
            >
              Back
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default withRouter(OtherDetails);
