import React, { useEffect, useRef, useState } from "react";
import { Button, message, Modal, Spin } from "antd";
import { Row, Col } from "react-bootstrap";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import axios from "axios";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { API, Headers } from "../../../Config";
import documentloader from "../../../Assets/Images/awbcustomsloader.gif";
import Companyseal from "../../../Assets/Images/seal.JPG";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { current } from "@reduxjs/toolkit";
import { GET, POST } from "../../../Config/ApiRequests";
import { Space, Table, Pagination } from "antd";

function PrintCustomsInvoiceModal(props) {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [Loading, setLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showExcel, setShowExcel] = useState(false);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [isLoading2, setIsLoading2] = useState(false);
  const [selectedRate, setSelectedRate] = useState({});
  const [exchangeRates, setexchangeRates] = useState([]);

  console.log("selectedRate --> ", selectedRate);

  useEffect(() => {
    loadBoxesViaAwb();
  }, []);

  useEffect(() => {
    getExchangeRates(1, 10);
  }, []);

  const getExchangeRates = async (_page, _take) => {
    try {
      setIsLoading2(true);
      let api = API.EXCHANGE_RATE_LIST;
      let obj = {
        page: _page,
        take: _take,
      };
      const excData = await POST(api, obj);
      if (excData?.status) {
        setexchangeRates(excData?.data);
        setSelectedRate(excData?.data[0]);
        setMeta(excData?.meta);
        setIsLoading2(false);
      } else {
        message.error("No data found");
        setIsLoading2(false);
      }
    } catch (err) {
      message.error("Oops.Something went");
      setIsLoading2(false);
    }
  };

  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    console.log(newSelectedRowKeys);
    console.log(rowDetails[0]);
    setSelectedRate(rowDetails[0]);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
      render: (text) => <a>{moment(text).format("ll")}</a>,
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      key: "to_date",
      render: (text) => <a>{moment(text).format("ll")}</a>,
    },
    {
      title: "SAR - USD",
      dataIndex: "sar_usd",
      key: "sar_usd",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "SAR - INR",
      dataIndex: "sar_inr",
      key: "sar_inr",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "INR - SAR",
      dataIndex: "inr_sar",
      key: "inr_sar",
      render: (text) => <a>{text}</a>,
    },
  ];

  const fetchData = async () => {
    try {
      const USD = await GetCurrancy();
      const boxes = await loadBoxesViaAwb(USD?.result);
      setShowExcel(true);
    } catch (error) {
      console.error(error);
      message.error("Select a rate");
    }
  };

  const GetCurrancy = async () => {
    if (selectedRate?.id) {
      let val = selectedRate["sar_usd"];
      console.log(val);
      return val;
    } else {
      message.error("Select a rate");
    }
  };

  const loadBoxesViaAwb = () => {
    const AWBID = props.selected && props.selected[0].id;
    let URL = API.BASR_URL + API.BOXES_VIA_AWB + AWBID;
    axios
      .get(URL, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setIsLoading(false);
        } else {
          message.error("Ooops. Something went wrong...!");
          setIsLoading(false);
        }
      });
  };

  const downloadTiff = async () => {
    setLoading(true);
    try {
      const zip = new JSZip();
      const totalPages = data.length;
      for (let page = 0; page < totalPages; page++) {
        const currentPage = data[page];
        setData([currentPage]);
        const content = componentRef.current;
        const canvas = await html2canvas(content, {
          useCORS: true,
          scrollY: window.scrollY + page * window.innerHeight,
          width: 800,
          height: 1000,
        });
        const tiffFilename = `Customs_Page${page + 1}.tif`;
        const blobPromise = new Promise((resolve) =>
          canvas.toBlob(resolve, "image/tiff")
        );
        const blob = await blobPromise;

        zip.file(tiffFilename, blob);
      }
      zip.generateAsync({ type: "blob" }).then((zipBlob) => {
        saveAs(zipBlob, "Customs_Pages.zip");
      });
    } catch (error) {
      console.error("Error capturing pages:", error);
    }

    loadBoxesViaAwb();
    setLoading(false);
  };

  return (
    <div>
      <Modal
        visible={props.visible}
        width={850}
        onCancel={props.onClose}
        footer={false}
      >
        {data.length ? (
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => componentRef.current}
          />
        ) : null}
        &nbsp;
        {data.length ? (
          <Button type="primary" onClick={downloadTiff}>
            {Loading ? <Spin /> : "Download Tiff"}
          </Button>
        ) : null}
        {!showExcel ? (
          <>
            <div className="modal-table-header">Select Rate</div>
            <div
              style={{
                height: "300px",
                width: "100%",
                overflow: "scroll",
              }}
            >
              <>
                <Table
                  rowKey="id"
                  size="small"
                  pagination={false}
                  columns={columns}
                  rowSelection={{ type: "radio", ...rowSelection }}
                  dataSource={exchangeRates}
                />
                <br />
                <Pagination
                  total={meta?.total_count}
                  showSizeChanger
                  defaultCurrent={meta?.page}
                  showTotal={(total) => `Total ${meta?.total_count}`}
                  onChange={(page, pageSize) => {
                    setPage(page);
                    setTake(pageSize);
                    getExchangeRates(page, pageSize);
                  }}
                />
              </>
            </div>
            <br />
          </>
        ) : null}
        <hr />
        <div ref={componentRef}>
          {isLoading ? (
            <div className="print-customs-box">
              <img src={documentloader} />
            </div>
          ) : (
            <>
              {data && data.length ? (
                <>
                  {data
                    .sort((a, b) => a.box_number - b.box_number)
                    .map((item) => {
                      // let totalValue = item && item.total_value_usd;

                      let totalValue =
                        item &&
                        Number(
                          Number(item.total_value) *
                            Number(selectedRate?.sar_usd)
                        ).toFixed(2);

                      let basicValue = Number(totalValue / 1.21125).toFixed(2);
                      let frieghtValue = Number(basicValue * 0.2).toFixed(2);
                      let InsuranceValue = Number(basicValue * 0.01125).toFixed(
                        2
                      );

                      const medianBoxNum = Math.floor(
                        item.boxe_item.length / 2
                      );

                      return (
                        <div>
                          <div className="print-customs-page">
                            <table className="print-customs-table">
                              <tr>
                                <td colSpan={2}>
                                  <div className="print-customsInvoice-txt1">
                                    <div> SAHARI INTERNATIONAL</div>
                                    <div>COURIER SERVICES</div>
                                  </div>
                                </td>
                                <td
                                  colSpan={2}
                                  className="print-customsInvoice-header"
                                >
                                  <div>AIRWAY BILL NO</div>
                                  <Barcode
                                    value={item.box_number}
                                    height="40"
                                    font="Montserrat-Regular"
                                    fontSize="12px"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <table
                                    style={{ fontSize: "10px" }}
                                    className="print-invoice-table"
                                  >
                                    <tr>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          DATE
                                        </div>
                                      </td>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          ORIGIN
                                        </div>
                                      </td>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          DESTINATION
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          {moment(item.createdAt).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </div>
                                      </td>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          SAUDI ARABIA
                                        </div>
                                      </td>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          {item.country}
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={2}
                                  className="print-invoice-table-td"
                                >
                                  <div className="print-customsInvoice-txt2">
                                    DECLARATION
                                  </div>
                                  <div className="print-invoice-txt5">
                                    shipper certifies that the nature of goods
                                    is as AIRWAY BILL the shipper agree to and
                                    accept the contract conditions on the
                                    reverse side here of and acknowledges that
                                    the goods described herein is accepted by
                                    SAHARI INTERNATIONAL COURIER SERVICE for
                                    clearance to said contract condition.
                                    shipper declares that goods carried under
                                    this consignmet contain no DANGEROUS GOODS,
                                    as described under the IATA dangerous goods
                                    regulation.
                                  </div>
                                </td>
                                <td
                                  colSpan={2}
                                  className="print-invoice-table-td"
                                >
                                  <table className="print-invoice-table">
                                    <tr>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          Pieces
                                        </div>
                                      </td>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          Weight
                                        </div>
                                      </td>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          Invoice Value
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          1
                                        </div>
                                      </td>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          {item.chargable_weight}
                                        </div>
                                      </td>
                                      <td className="print-invoice-table-td">
                                        <div className="print-customsInvoice-txt3">
                                          <b> ${totalValue}</b>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                  <div className="print-invoice-txt5">
                                    I <b>{item.iqama_name}</b> ID:{" "}
                                    <b>{item.iqama_number}</b>
                                    holder of Indian Passport No/Aadhaar No:
                                    this.props Do here by appointed SAHARI
                                    INTERNAIONAL COURIER SERVICES as my
                                    authorised agent to do the courier clearance
                                    in india for me and sending to{" "}
                                    <b> {item.adhar_name} </b>
                                    as a gift consignment
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <div className="print-invoice-txt4">
                                    INVOICE/PACKING LIST
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th
                                  colSpan={2}
                                  className="print-invoice-table-td"
                                >
                                  <div className="print-customsInvoice-txt4">
                                    Sender Name & Address
                                  </div>
                                </th>
                                <th
                                  colSpan={2}
                                  className="print-invoice-table-td"
                                >
                                  <div className="print-customsInvoice-txt4">
                                    Gifts For Personal Use Only
                                  </div>
                                </th>
                              </tr>
                              <tr>
                                <td
                                  colSpan={2}
                                  className="print-invoice-table-td"
                                >
                                  <div className="print-customs-txt2">
                                    {item.iqama_name} ID :{item.iqama_number}
                                  </div>

                                  <div className="print-customs-txt2">
                                    {item.address}
                                  </div>
                                  <div className="print-customs-txt2">
                                    {item.sender_mobile}
                                  </div>
                                  <div className="print-customsInvoice-txt4">
                                    <b>Consignee Name & Address</b>
                                  </div>

                                  <div className="print-customs-txt2">
                                    {item.adhar_name}
                                  </div>

                                  <div className="print-customs-txt2">
                                    {item.adhar_address}
                                  </div>
                                  <div className="print-customs-txt2">
                                    {item.district}
                                  </div>
                                  <div className="print-customs-txt2">
                                    {item.state}
                                  </div>
                                  <div className="print-customs-txt2">
                                    {item?.country?.length > 2
                                      ? item?.country
                                      : item?.collection &&
                                        item?.collection?.recivercountries &&
                                        item?.collection?.recivercountries
                                          ?.name}
                                  </div>
                                  <div className="print-customs-txt2">
                                    {item.reciver_mobile1}
                                  </div>
                                </td>
                                <td
                                  colSpan={2}
                                  className="print-invoice-table-td"
                                >
                                  <div style={{ display: "flex" }}>
                                    <div className="print-customsInvoice-pod-box">
                                      <div>P</div>
                                      <div>O</div>
                                      <div>D</div>
                                    </div>
                                    <div>
                                      <div className="print-invoice-txt5">
                                        I, the undersigned on behalf of the
                                        above sender/shipper acknowledgement the
                                        receipt of goods in good condition
                                      </div>
                                      <div className="print-customs-txt2">
                                        <b>Reciever Name :</b>
                                        {item.adhar_name}
                                      </div>
                                      <div className="print-customs-txt2">
                                        <b>Date : </b>
                                        {/* {moment(item.createdAt).format(
                                        "DD-MM-YYYY"
                                      )} */}{" "}
                                        ...
                                      </div>
                                      <div className="print-customs-txt2">
                                        <b>Time : </b>
                                        {/* {moment(item.createdAt).format(
                                        "HH:MM:SS"
                                      )} */}{" "}
                                        ...
                                      </div>
                                      <div className="print-customs-txt2">
                                        <b>Signature :</b>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={4}>
                                  <div className="print-invoice-table-row">
                                    <div className="print-invoice-table-column">
                                      <table className="print-invoice-table">
                                        <tr>
                                          <th
                                            className="print-customs-td"
                                            colSpan={1}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Sl No
                                            </div>
                                          </th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={2}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Description
                                            </div>
                                          </th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={1}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Qty
                                            </div>
                                          </th>
                                        </tr>
                                        {item.boxe_item &&
                                          item.boxe_item
                                            .slice(0, 10)
                                            .map((items, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td
                                                    className="print-invoice-table-td"
                                                    colSpan={1}
                                                  >
                                                    <div className="print-customsInvoice-txt6">
                                                      {index + 1}
                                                    </div>
                                                  </td>
                                                  <td
                                                    className="print-invoice-table-td"
                                                    colSpan={2}
                                                  >
                                                    <div className="print-customsInvoice-txt6">
                                                      {items.item}
                                                    </div>
                                                  </td>
                                                  <td
                                                    className="print-invoice-table-td"
                                                    colSpan={1}
                                                  >
                                                    <div className="print-customsInvoice-txt6">
                                                      {items.quantity}
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                      </table>
                                    </div>
                                    <div className="print-invoice-table-column">
                                      <table className="print-invoice-table">
                                        <tr>
                                          <th
                                            className="print-customs-td"
                                            colSpan={1}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              {" "}
                                              Sl No
                                            </div>
                                          </th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={2}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Description
                                            </div>
                                          </th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={1}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Qty
                                            </div>
                                          </th>
                                        </tr>
                                        {item.boxe_item &&
                                          item.boxe_item
                                            .slice(10, 15)
                                            .map((items, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td
                                                    className="print-invoice-table-td"
                                                    colSpan={1}
                                                  >
                                                    <div className="print-customsInvoice-txt6">
                                                      {index + 11}
                                                    </div>
                                                  </td>
                                                  <td
                                                    className="print-invoice-table-td"
                                                    colSpan={2}
                                                  >
                                                    <div className="print-customsInvoice-txt6">
                                                      {items.item}
                                                    </div>
                                                  </td>
                                                  <td
                                                    className="print-invoice-table-td"
                                                    colSpan={1}
                                                  >
                                                    <div className="print-customsInvoice-txt6">
                                                      {items.quantity}
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        <tr>
                                          <th colSpan={1}></th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={2}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Basic Value:
                                            </div>
                                          </th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={1}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              {basicValue}
                                            </div>
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colSpan={1}></th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={2}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Freight @ 20%:
                                            </div>
                                          </th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={1}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              {frieghtValue}
                                            </div>
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colSpan={1}></th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={2}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Insurance @ 1.125%:
                                            </div>
                                          </th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={1}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              {InsuranceValue}
                                            </div>
                                          </th>
                                        </tr>
                                        <tr>
                                          <th colSpan={1}></th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={2}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              Total Value:
                                            </div>
                                          </th>
                                          <th
                                            className="print-customs-td"
                                            colSpan={1}
                                          >
                                            <div className="print-customsInvoice-txt5">
                                              ${totalValue}
                                            </div>
                                          </th>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={4}>
                                  <div className="print-invoice-txt5">
                                    1. I.Mr/Mrs <b>{item.iqama_name}</b>
                                    ID:<b>{item.iqama_number}</b>
                                    for my family/friends, personal use as
                                    gift.not for sale. Shipper also declare that
                                    he is liable of any customs duty/fine /
                                    penalites against this shipment at origin
                                    and destination
                                  </div>
                                  <div className="print-invoice-txt5">
                                    2. Shipper certifies that the particulars on
                                    the face here of are correct and that
                                    insofar as any part of the consignemt
                                    contains Dangerous Goods if any surch part
                                    is property by name and is in proper
                                    condition for carriage by air according to
                                    the Internation Transport Association's
                                    applicable dangerous goods regulations and
                                    civil Aviation Regulation of the United Arab
                                    Emirates.
                                  </div>
                                  <div className="print-invoice-txt5">
                                    3. I/We agree that standard terms and
                                    conditions apply for this shipment and
                                    liability to terms and conditions. Warsaw
                                    Convention amy apply. I/We Service Liability
                                    shall not exceed US$100 for any shipment.
                                  </div>
                                  <div className="print-invoice-txt5">
                                    4. /The Shipper also here by authorize
                                    forward the shipment in full or part their
                                    convenience
                                  </div>
                                  <div className="print-invoice-txt5">
                                    5. The Shipper also here by authorize and
                                    their Counterpart India to clear the parcel
                                    on behalf of me and same should be delivered
                                    to the Consignee address.
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th colSpan={2}>
                                  <div className="print-customsInvoice-txt4">
                                    Customer Signature
                                  </div>
                                </th>
                                <th colSpan={2}>
                                  <div className="print-customsInvoice-txt4">
                                    Company SEAL
                                  </div>
                                </th>
                              </tr>

                              <tr>
                                <td colSpan={2} style={{ height: "14vh" }}>
                                  {item.signature ? (
                                    <div>
                                      <img
                                        src={item.signature}
                                        height="100px"
                                        width="100px"
                                      />
                                    </div>
                                  ) : null}
                                </td>
                                <td colSpan={1} style={{ height: "14vh" }}></td>
                                <td colSpan={1}>
                                  <div className="print-customs-seal">
                                    <img
                                      src={Companyseal}
                                      height="100%"
                                      width="100%"
                                    />
                                    {item.collection &&
                                    item.collection.driver_signature ? (
                                      <img
                                        src={
                                          item.collection &&
                                          item.collection.driver_signature
                                        }
                                        className="print-invoice-signature"
                                      />
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                          {medianBoxNum >= 11 ? (
                            <div
                              style={{ padding: "20px", minHeight: "300mm" }}
                            >
                              <br />
                              <table className="print-invoice-table">
                                <tr>
                                  <th className="print-customs-td">
                                    <div className="print-customsInvoice-txt5">
                                      {" "}
                                      Sl No
                                    </div>
                                  </th>
                                  <th className="print-customs-td">
                                    <div className="print-customsInvoice-txt5">
                                      Description
                                    </div>
                                  </th>
                                  <th className="print-customs-td">
                                    <div className="print-customsInvoice-txt5">
                                      Qty
                                    </div>
                                  </th>
                                  <th className="print-customs-td">
                                    <div className="print-customsInvoice-txt5">
                                      {" "}
                                      Sl No
                                    </div>
                                  </th>
                                  <th className="print-customs-td">
                                    <div className="print-customsInvoice-txt5">
                                      Description
                                    </div>
                                  </th>
                                  <th className="print-customs-td">
                                    <div className="print-customsInvoice-txt5">
                                      Qty
                                    </div>
                                  </th>
                                </tr>
                                {item.boxe_item
                                  .slice(15, 30)
                                  .map((items, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="print-customs-td">
                                          <div className="print-customsInvoice-txt6">
                                            {index + 16}
                                          </div>
                                        </td>
                                        <td className="print-customs-td">
                                          <div className="print-customsInvoice-txt6">
                                            {items.item}
                                          </div>
                                        </td>
                                        <td className="print-customs-td">
                                          <div className="print-customsInvoice-txt6">
                                            {items.quantity}
                                          </div>
                                        </td>
                                        <td className="print-customs-td">
                                          <div className="print-customsInvoice-txt6"></div>
                                        </td>
                                        <td className="print-customs-td">
                                          <div className="print-customsInvoice-txt6"></div>
                                        </td>
                                        <td className="print-customs-td">
                                          <div className="print-customsInvoice-txt6"></div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </table>
                              <br />
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="print-manifest-box1">
                  <AiOutlineCloseCircle size={50} color={"red"} />
                  <div style={{ margin: 20 }} />
                  <div>No data found.</div>
                </div>
              )}
            </>
          )}
        </div>
        {data && data.length ? (
          <Row>
            <Col sm="6"></Col>
            <Col sm="3">
              <ReactToPrint
                trigger={() => (
                  <Button type="primary" block>
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Col>
            <Col sm="3" block>
              Cancel
            </Col>
          </Row>
        ) : null}
      </Modal>
    </div>
  );
}

export default PrintCustomsInvoiceModal;
