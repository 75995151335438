import React from "react";
import moment from "moment";
import { BiCheckDouble } from "react-icons/bi";

function GeneralDetails(props) {
  return (
    <div>
      <div className="Tracking-box5">
        <div className="Tracking-txt1">Shipment Details</div>
      </div>
      <div className="Tracking-box6">
        <br />
        <br />
        <br />
        <table className="Tracking-table1">
          <tr>
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td colSpan={2}>
              <div className="Tracking-txt2">AWB NUMBER</div>
            </td>
            <td colSpan={2}>
              <div className="Tracking-txt2">DESTINATION</div>
            </td>
          </tr>
          <tr>
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td colSpan={2}>
              <div className="Tracking-txt3">
                {props.data &&
                props.data[0] &&
                props.data[0].airway_bill &&
                props.data[0].airway_bill.airline
                  ? `${props.data[0].airway_bill.airline} - ${props.data[0].airway_bill.awb_no}`
                  : "N/A"}
              </div>
            </td>
            <td colSpan={2}>
              <div className="Tracking-txt3">
                {props.data &&
                props.data[0] &&
                props.data[0].airway_bill &&
                props.data[0].airway_bill.airline
                  ? `${props.data[0].airway_bill.destination_airport_name}`
                  : "N/A"}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={1}></td>
            <td colSpan={1}>
              {" "}
              <BiCheckDouble color="#1e88e5" size={40} />
            </td>
            <td colSpan={2}>
              <div className="Tracking-txt2">STATUS</div>
            </td>
            <td colSpan={2}>
              <div className="Tracking-txt2">DATE</div>
            </td>
          </tr>
          <tr>
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td colSpan={2}>
              <div className="Tracking-txt3">
                {props.data &&
                props.data[0] &&
                props.data[0].ststuss &&
                props.data[0].ststuss.status_order === "100"
                  ? `${props.data[0].ststuss.status_head}`
                  : "N/A"}
              </div>
            </td>
            <td colSpan={2}>
              <div className="Tracking-txt3">
                {props.data && props.data[0] && props.data[0].ststuss
                  ? `${moment(props.data[0].ststuss.created_at).format("l")}`
                  : "N/A"}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td colSpan={2}>
              <div className="Tracking-txt2">TOTAL PIECES</div>
            </td>
            <td colSpan={2}>WEIGHT</td>
          </tr>
          <tr>
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td colSpan={2}>
              <div className="Tracking-txt3">
                {props.data &&
                props.data[0] &&
                props.data[0].airway_bill &&
                props.data[0].airway_bill.total_pieces
                  ? `${props.data[0].airway_bill.total_pieces}`
                  : "N/A"}
              </div>
            </td>
            <td colSpan={2}>
              <div className="Tracking-txt3">
                {props.data.weight && props.data.weight}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default GeneralDetails;
