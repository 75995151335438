import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Tabs,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useRef, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { API, Headers } from "../../Config";

const { TabPane } = Tabs;
function PaymentFormModal(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const taxGoDatas = useSelector((state) => state.TaxgoSlice);

  const formRef = useRef();
  const [ifBank, setIfBank] = useState(true);
  const [loading, setLoading] = useState(false);

  const onFinish = (val) => {
    let balanceAmount = props?.selectedRow?.ledgerTransColln
      .reduce((balance, e) => {
        const amount = parseFloat(e.amount);
        if (e.cr_or_dr === "Dr" && !isNaN(amount)) {
          return balance + amount;
        } else if (e.cr_or_dr === "Cr" && !isNaN(amount)) {
          return balance - amount;
        }
        return balance;
      }, 0)
      .toFixed(2);

    if (Number(val?.amount) > Number(balanceAmount)) {
      message.error(
        "The Payment Amount Is More Than Balance Amount. Please adjust it."
      );
    } else {
      let box = [];
      props.selectedRow?.boxes?.map((e) => {
        box.push(e.box_number);
      });
      setLoading(true);

      let obj = {
        amount: Number(val.amount),
        round_off: Number(val.round_off),
        colln_id: Number(props?.selectedRow?.id),
        account_no: val?.account_no,
        userid: Number(Auth.id),
        note: "CP Box.Nos:" + box.join("-").toString(),
        cr_or_dr: "Cr",
        payment_date: val.payment_date,
        payment_mode: val.payment_mode,
        taxGoDatas: taxGoDatas?.data,
        taxgoId: props?.selectedRow?.boxes?.map((item) => {
          return {
            saleId: item?.taxgo_new_saleid,
            invoiceNumber: item?.taxgo_sale_invoicenumber,
            amount: Number(val?.amount),
            box_number: item?.box_number,
          };
        }),
      };

      axios({
        method: "post",
        url: API.BASR_URL + API.LEDGER_TRANS_COLLN_ENTRY_ACTIONS,
        headers: Headers(),
        data: obj,
      })
        .then(function (response) {
          // updateAmountField(props?.selectedRow?.boxes);
          message.success("Payment Success");
          setLoading(false);
          props.onCancel();
          props.relaod();
        })

        .catch(function (error) {
          message.error("Oops.Something went wrong");
          console.log(error);
          setLoading(false);
        });
    }
  };

  const onValuesChange = (val) => {
    if (val.mode === "Bank") {
      setIfBank(false);
    } else {
      setIfBank(true);
    }
  };

  const columns = [
    { title: "Payment ID", dataIndex: "id", key: "id" },
    {
      title: "Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (payment_date) => (
        <div>{payment_date && moment(payment_date).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      title: "User",
      dataIndex: "userid",
      key: "userid",
      render: (userid) => {
        const user = props.users.find((item) => item.id === userid);
        return user ? user.name : "";
      },
    },
    { title: "Mode", dataIndex: "payment_mode", key: "payment_mode" },
    { title: "Amount", dataIndex: "amount", key: "amount" },

    {
      title: "Dr/Cr",
      dataIndex: "cr_or_dr",
      key: "cr_or_dr",
      render: (cr_or_dr) => (
        <span style={{ color: cr_or_dr === "Dr" ? "red" : "green" }}>
          {cr_or_dr}
        </span>
      ),
    },

    // Add more columns as needed
  ];

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        width={650}
        footer={false}
        style={{ borderRadius: 10 }}
      >
        <div className="PaymentFormModal-box1">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Make Reciept" key="1">
              <br />
              <div className="PaymentFormModal-txt1">Make Reciept</div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    padding: "10px",
                    borderRadius: "4px",
                    width: "150px",
                  }}
                >
                  <p style={{ fontWeight: "bold", margin: 0 }}>TOTAL: </p>
                  <p style={{ fontSize: "18px", margin: 0 }}>
                    {props.selectedRow.ledgerTransColln
                      .reduce((total, e) => {
                        if (e.note === "COLLN") {
                          return total + parseFloat(e.amount);
                        }
                        return total;
                      }, 0)
                      .toFixed(2)}
                  </p>
                </div>

                <div
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    padding: "10px",
                    borderRadius: "4px",
                    width: "150px",
                  }}
                >
                  <p style={{ fontWeight: "bold", margin: 0 }}>PAID: </p>
                  <p style={{ fontSize: "18px", margin: 0 }}>
                    {props.selectedRow.ledgerTransColln
                      .reduce((total, e) => {
                        if (
                          e.cr_or_dr === "PAY" &&
                          !isNaN(parseFloat(e.amount))
                        ) {
                          return (
                            total +
                            (parseFloat(e.amount) + parseInt(e.round_off))
                          );
                        }
                        return total;
                      }, 0)
                      .toFixed(2)}
                  </p>
                </div>

                <div
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: "10px",
                    borderRadius: "4px",
                    width: "150px",
                  }}
                >
                  <p style={{ fontWeight: "bold", margin: 0 }}>BALANCE: </p>
                  <p style={{ fontSize: "18px", margin: 0 }}>
                    {props.selectedRow.ledgerTransColln
                      .reduce((balance, e) => {
                        const amount = parseFloat(e.amount);
                        if (e.cr_or_dr === "Dr" && !isNaN(amount)) {
                          return balance + amount;
                        } else if (e.cr_or_dr === "Cr" && !isNaN(amount)) {
                          return balance - amount;
                        }
                        return balance;
                      }, 0)
                      .toFixed(2)}
                  </p>
                </div>
              </div>

              <br />
              <div className="">
                <Form
                  ref={formRef}
                  onFinish={onFinish}
                  onValuesChange={onValuesChange}
                  initialValues={{}}
                >
                  <div className="PaymentFormModal-txt2">Reciept Date</div>
                  <Form.Item
                    name="payment_date"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <DatePicker placeholder="Pick a date" className="w-100" />
                  </Form.Item>

                  <div className="PaymentFormModal-txt2">Account</div>
                  <Form.Item
                    name="account_no"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) => {
                        const accountNo = option.value.toLowerCase();
                        const accountName = option.children
                          .toString()
                          .toLowerCase();
                        return (
                          accountNo.includes(input.toLowerCase()) ||
                          accountName.includes(input.toLowerCase())
                        );
                      }}
                      style={{ width: "100%" }}
                      placeholder="Select Account"
                    >
                      {props.accounts.map((item, index) => (
                        <Select.Option
                          key={item.account_no}
                          value={item.account_no}
                        >
                          {item.account_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <div className="PaymentFormModal-txt2">Amount</div>
                  <Form.Item
                    name="amount"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="PaymentFormModal-txt2">Round Off</div>
                  <Form.Item
                    name="round_off"
                    rules={[{ required: false, message: "required" }]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="PaymentFormModal-txt2">Remarks</div>
                  <Form.Item name="note">
                    <Input />
                  </Form.Item>
                  <Row>
                    <Col sm="6" xs="12">
                      <Button
                        block
                        onClick={() => props.onCancel()}
                        size="large"
                      >
                        Close
                      </Button>
                    </Col>
                    <Col sm="6" xs="12">
                      <Form.Item>
                        <Button
                          type="primary"
                          block
                          size="large"
                          htmlType="submit"
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </TabPane>
            <TabPane tab="Reciept Details" key="2">
              <Table
                dataSource={props.selectedRow.ledgerTransColln}
                columns={columns}
                pagination={false}
                size="middle"
              />
            </TabPane>
          </Tabs>
        </div>
      </Modal>
    </div>
  );
}

export default PaymentFormModal;
