import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Form,
  Radio,
  Space,
  Button,
  Select,
  Checkbox,
  Input,
} from "antd";
import { Container, Row, Col, Table } from "react-bootstrap";
import { AiOutlineFilePdf, AiFillPrinter } from "react-icons/ai";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReactToPrint from "react-to-print";
import styles from "../styles";
import PdfAirwayBill from "../PrintDesign/PdfAirwayBill";
import awbpreview from "../../../Assets/Images/awbpreview.png";

function PrintAwbModal(props) {
  const componentRef = useRef();
  const [value, setValue] = useState(1);
  const [blankawbprint, setBlankAwbPrint] = useState(true);
  const [numofcopy, setNumOfCopy] = useState(1);
  const [pagesizedetails, setPageSizeDetails] = useState(true);
  const [pagedetails, setPageDetails] = useState([]);
  const [checked, setChecked] = useState(true);

  const [listItem, setListItem] = useState(props.item ? props.item : []);

  useEffect(() => {
    setListItem(props.item);
  }, [props.item]);

  const openWindowToPrint = (newarr) => {
    window.open(
      process.env.PUBLIC_URL + "/PrintAWB/AWB.html?data=" + encodeJson(newarr),
      "winname",
      "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=650"
    );
  };

  const encodeJson = (json) => {
    var stringJson = JSON.stringify(json);
    var encodedString = btoa(stringJson);
    return encodedString;
  };

  const onChange = (e) => {
    {
      e.target.value === 1 ? setBlankAwbPrint(true) : setBlankAwbPrint(false);
    }
  };
  const numOfCopies = (e) => {
    if (e.target.value === 8) {
      setNumOfCopy(8);
    } else if (e.target.value === 12) {
      setNumOfCopy(12);
    } else if (e.target.value === 0) {
      setNumOfCopy(1);
    }
  };

  const setEightCopies = () => {
    setChecked(true);
  };

  const clearNoCopies = () => {
    setChecked(false);
  };

  const setMoreCopies = (e) => {
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    setPageDetails(values);

    let obj = {
      paper_option: values.paper_option,
      paper_size: values.paper_size,
      paper_size: values.paper_size,
      copies: values.copies,
      carry_copies: values.carry_copies ? true : false,
      consignee_copies: values.consignee_copies ? true : false,
      shipper_copies: values.shipper_copies ? true : false,
      delivery_copies: values.delivery_copies ? true : false,
      agent_copy: values.agent_copy ? true : false,
      extra_copy5: values.extra_copy5 ? true : false,
      extra_copy6: values.extra_copy6 ? true : false,
      extra_copy7: values.extra_copy7 ? true : false,
    };

    let newarr = props.item.push(obj);
    console.log("newarr==", props.item);
    openWindowToPrint(props.item);
    props.onClose();
  };
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      header={false}
      footer={false}
      width={900}
    >
      <div>
        <br />
        <Container>
          <h5 className="PrintLabelModal-txt1">Print To Pdf</h5>
          <Form onFinish={onFinish}>
            {pagesizedetails ? (
              <>
                <Row>
                  <Col sm={6}>
                    <div className="PrintLabelModal-txt2">paper Option</div>
                    <br />
                    <Form.Item name="paper_option" label="Paper Type">
                      <Radio.Group
                        onChange={onChange}
                        value={value}
                        defaultValue={1}
                      >
                        <Space direction="vertical">
                          <Radio value={1}>
                            <div>
                              <div className="print-label-txt1">
                                Blank Paper
                              </div>
                              <div className="print-label-txt2">
                                print form on standard blank paper
                              </div>
                            </div>
                          </Radio>
                          <Radio value={2}>
                            <div>
                              <div className="print-label-txt1">
                                Pre-printed AWB form
                              </div>
                              <div className="print-label-txt2">
                                print content on pre printed AWB forms using a
                                dot matrix printer
                              </div>
                            </div>
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name="paper_size" label="Paper Size">
                      <Select defaultValue={"a"}>
                        <Select.Option value="a">A4</Select.Option>
                        <Select.Option value="b">A5</Select.Option>
                        <Select.Option value="c">Letter</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={6}>
                    <div className="PrintLabelModal-txt2">Copies</div>
                    <Form.Item name={"copies"}>
                      <Radio.Group
                        defaultValue={1}
                        size="small"
                        buttonStyle="solid"
                        onChange={numOfCopies}
                      >
                        <Radio.Button value={8} onClick={setEightCopies}>
                          8 Copies
                        </Radio.Button>
                        <Radio.Button value={12}>12 Copies</Radio.Button>
                        <Radio.Button value={1} onClick={clearNoCopies}>
                          Clear
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <Row>
                      <Col sm={6}>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={"carry_copies"}
                          valuePropName="checked"
                        >
                          <Checkbox checked={checked} defaultChecked={true}>
                            <div className="PrintLabelModal-txt3">
                              Original 1 (for Issuing Carrier)
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={"consignee_copies"}
                          valuePropName="checked"
                        >
                          <Checkbox checked={checked} defaultChecked>
                            <div className="PrintLabelModal-txt3">
                              Original 2 (for Consignee)
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={"shipper_copies"}
                          valuePropName="checked"
                        >
                          <Checkbox checked={checked} defaultChecked>
                            <div className="PrintLabelModal-txt3">
                              Original 3 (for Shipper)
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={"delivery_copies"}
                          valuePropName="checked"
                        >
                          <Checkbox checked={checked} defaultChecked>
                            <div className="PrintLabelModal-txt3">
                              Copy 4 (Delivery Receipt)
                            </div>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col sm={6}>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={"extra_copy5"}
                          valuePropName="checked"
                        >
                          <Checkbox checked={checked} defaultChecked>
                            <div className="PrintLabelModal-txt3">
                              Copy 5 (Extra Copy)
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={"extra_copy6"}
                          valuePropName="checked"
                        >
                          <Checkbox checked={checked} defaultChecked>
                            <div className="PrintLabelModal-txt3">
                              Copy 6 (Extra Copy)
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          style={{ margin: 0 }}
                          name={"extra_copy7"}
                          valuePropName="checked"
                        >
                          <Checkbox checked={checked} defaultChecked>
                            <div className="PrintLabelModal-txt3">
                              Copy 7 (Extra Copy)
                            </div>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          style={{ margin: 0 }}
                          name="agent_copy"
                          valuePropName="checked"
                        >
                          <Checkbox checked={checked} defaultChecked>
                            <div className="PrintLabelModal-txt3">
                              Copy 8 (For Agent)
                            </div>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <div style={{ margin: 10 }} />
                      <Form.Item>
                        <Radio.Group onChange={setMoreCopies}>
                          <Radio value={4}>
                            Print more extra copies
                            {value === 4 ? (
                              <Input style={{ width: 100, marginLeft: 10 }} />
                            ) : null}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}></Col>
                  <Col sm={2}>
                    <Button
                      htmlType="button"
                      block
                      size="large"
                      onClick={() => props.onClose()}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col sm={2}>
                    <Button htmlType="submit" type="primary" block size="large">
                      Next
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <div>
                {/* <ReactToPrint
                  trigger={() => (
                    <Button
                      style={styles.buttons}
                      icon={<AiFillPrinter style={styles.buttonsIcon} />}
                      type="primary"
                    >
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                /> */}
                <Button
                  style={styles.buttons}
                  icon={<AiFillPrinter style={styles.buttonsIcon} />}
                  type="primary"
                >
                  Print
                </Button>

                <PDFDownloadLink document={<PdfAirwayBill />}>
                  {({ blob, url, loading, error }) =>
                    loading ? null : (
                      <Button
                        style={styles.buttons}
                        icon={<AiOutlineFilePdf style={styles.buttonsIcon} />}
                      >
                        Download PDF
                      </Button>
                    )
                  }
                </PDFDownloadLink>
                <div ref={componentRef}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={awbpreview} />
                  </div>
                </div>
              </div>
            )}
            <br /> <br />
          </Form>
        </Container>
      </div>
    </Modal>
  );
}

export default PrintAwbModal;
