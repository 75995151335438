const styles = {
  buttons: {
    borderRadius: 2,
    fontSize: 14,
    fontWeight: "900",
    marginBottom: 10,
  },
  buttonsIcon: {
    fontSize: 20,
    marginRight: 10,
  },
  DatePicker: {
    width: "100%",
  },
  FormItemInput: {
    borderRadius: 8,
    border: 0,
    background: "#f5f5f5",
    padding: 10,
    width: "100%",
  },
  TableBorder: {
    fontSize: "12px",
  },
  FormAddBtn: {
    width: "10%",
    marginLeft: 15,
  },
  dimensions: {
    width: "90%",
  },
  dimensions2: {
    width: "120%",
  },
  ZeroGutter: {
    paddingRight: 0,
    paddingLeft: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  DataDisplay: {
    border: 0,
    color: "#000",
    fontWeight: 800,
    background: "#fff",
  },
  FormInput: {
    textTransform: "uppercase",
    fontSize: "12px",
  },
};
export default styles;
