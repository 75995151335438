import React, { useEffect } from "react";
import { Modal } from "antd";
import { Table } from "react-bootstrap";
import moment from "moment";
function DetailsMoal(props) {
  let TotalAmount =
    props.item.boxes &&
    props.item.boxes.reduce(function (tot, arr) {
      return Number(tot) + Number(arr?.total_charge);
    }, 0);

  return (
    <Modal
      visible={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={1200}
    >
      <div>
        <br />
        <br />
        <Table className="formTable" borderless>
          <thead>
            <tr>
              <td
                colSpan={4}
                style={{ backgroundColor: "#1e88e5", color: "#fff" }}
                className="collection-fulldetails-txt1"
              >
                Full Details
              </td>
            </tr>
          </thead>
          <tbody className="collection-fulldetails-txt2">
            <tr>
              <td colSpan={2}>TOTAL WEIGHT</td>
              <th colSpan={2}>{props.item && props.item.total_weight}</th>
            </tr>
            <tr>
              <td colSpan={2}>TOTAL AMOUNT</td>
              <th colSpan={2}>{props.item && TotalAmount}</th>
            </tr>
            <tr>
              <td colSpan={2}>instructions</td>
              <th colSpan={2}>{props.item && props.item.instructions}</th>
            </tr>
            <tr>
              <td colSpan={2}>COLLECTED BY</td>
              <th colSpan={2}>
                {props.item &&
                  props.item.collected_user &&
                  props.item.collected_user.name}
              </th>
            </tr>
            <tr>
              <td colSpan={2}>COLLECTED DATETIME :</td>
              <th colSpan={2}>
                {props.item &&
                  moment(props.item.collected_datetime).format("l")}
              </th>
            </tr>
            <tr style={{ backgroundColor: "#e3e3e3", color: "#000" }}>
              <th colSpan={2}>SENDER DETAILS</th>
              <th colSpan={2}>RECIEVER DETAILS</th>
            </tr>
            <tr>
              <td colSpan={1}> Sender Name</td>
              <th colSpan={1}>{props.item && props.item.sender_name}</th>
              <td colSpan={1}> Reciever Name </td>
              <th colSpan={1}>{props.item && props.item.reciver_name}</th>
            </tr>
            <tr>
              <td colSpan={1}> Sender Phone</td>
              <th colSpan={1}>{props.item && props.item.sender_phone}</th>
              <td colSpan={1}> Reciever Phone </td>
              <th colSpan={1}>{props.item && props.item.reciver_phone}</th>
            </tr>
            <tr>
              <td colSpan={1}>Sender Alt. Phone</td>
              <th colSpan={1}>{props.item && props.item.sender_phone_alt}</th>
              <td colSpan={1}> Reciever Alt. Phone </td>
              <th colSpan={1}>{props.item && props.item.reciver_altphone}</th>
            </tr>
            <tr>
              <td colSpan={1}> Sender Address</td>
              <th colSpan={1}>{props.item && props.item.sender_address}</th>
              <td colSpan={1}> Reciever Address </td>
              <th colSpan={1}>{props.item && props.item.reciver_address}</th>
            </tr>

            <tr>
              <td colSpan={1}> </td>
              <td colSpan={1}></td>
              <td colSpan={1}> Reciever Pincode </td>
              <th colSpan={1}>{props.item && props.item.reciver_pincode}</th>
            </tr>

            <tr>
              <td colSpan={1}> </td>
              <td colSpan={1}></td>
              <td colSpan={1}> Reciever Country </td>
              <th colSpan={1}>
                {props.item &&
                  props.item.reciver_countries &&
                  props.item.reciver_countries.name}
              </th>
            </tr>
            <tr>
              <th colSpan={4} style={{ backgroundColor: "#e3e3e3" }}>
                Box Details
              </th>
            </tr>
            <tr>
              <td colSpan={4}>
                <Table className="formTable">
                  {props.item.boxes &&
                    props.item.boxes.length &&
                    props.item.boxes.map((box, index) => {
                      return (
                        <>
                          <tr style={{ background: "#2db7f5" }}>
                            <th colSpan={7}>
                              Box {index + 1} : {box.box_number}
                            </th>
                          </tr>
                          <tr>
                            <td colSpan={1}>
                              service type : <b>{box.service_type}</b>{" "}
                            </td>
                            <td colSpan={1}>
                              size:<b>{box.size}</b>
                            </td>
                            <td colSpan={1}>
                              L :<b>{box.length}</b>
                            </td>
                            <td colSpan={1}>
                              B :<b>{box.breadth}</b>
                            </td>
                            <td colSpan={1}>
                              H:<b>{box.height}</b>
                            </td>
                            <td colSpan={1}>
                              W:<b>{box.weight}</b>
                            </td>
                            <td colSpan={1}>H:</td>
                          </tr>

                          <tr>
                            <td colSpan={1}>
                              VOLUME KG : <b>{box.volume_weight}</b>
                            </td>
                            <td colSpan={1}>
                              ACTUAL KG :<b>{box.weight}</b>
                            </td>
                            <td colSpan={1}>
                              RATE / KG:<b>{box.rate}</b>
                            </td>
                            <td colSpan={1}>
                              packing Charge : <b>{box.packing_charge}</b>
                            </td>
                            <td colSpan={1}>
                              duty :<b>{box.duty}</b>
                            </td>
                            <td colSpan={1}>
                              other_charges:<b>{box.other_charges}</b>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={1}>discount:</td>
                            <td colSpan={1}>
                              t amount:<b>{box.total_charge}</b>
                            </td>
                            <td colSpan={1}>
                              T value:<b>{box.total_value}</b>
                            </td>
                            <td colSpan={1}>
                              t value $:<b>{box.total_value_usd}</b>
                            </td>
                            <td colSpan={1}>
                              t value inr :<b>{box.total_value_inr}</b>
                            </td>

                            <td colSpan={1}></td>
                          </tr>
                          <tr>
                            <td colSpan={7}>
                              <table className="formTable">
                                <tr style={{ backgroundColor: "#e2e2e2" }}>
                                  <td colSpan={1}>Sl No</td>
                                  <td colSpan={1}>item</td>
                                  <td colSpan={1}>quantity</td>
                                  <td colSpan={1}>value</td>
                                  <td colSpan={1}>brand</td>
                                </tr>
                                {box.boxitems &&
                                  box.boxitems.length &&
                                  box.boxitems.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item?.item}</td>
                                        <td>{item?.quantity}</td>
                                        <td>{item?.value}</td>
                                        <td>{item?.brand}</td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </Table>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Modal>
  );
}
export default DetailsMoal;
