const styles = {
  FormItem: {
    padding: 0,
    margin: 0,
    textTransform: "uppercase",
  },
  Input: {
    borderRadius: 8,
    textTransform: "uppercase",
  },
  Button: {
    border: "1px solid #1e88e5",
    borderRadius: "4px",
    fontSize: "12px",
  },

  AssignedBtn: {
    border: 0,
    borderRadius: "6px",
    background: "#BFFFD1",
    fontSize: "12px",
  },
};
export default styles;
