import React, { useState } from "react";
import {
  Table,
  Pagination,
  Button,
  Tooltip,
  Input,
  ButtonPopconfirm,
  Tag,
} from "antd";
import { AiOutlineFilter, AiOutlinePrinter } from "react-icons/ai";

import moment from "moment";

function DataTable(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const OnSelectChange = (newSelectedRowkeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowkeys);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          size="small"
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ backgroundColor: "transparent" }}>
        <AiOutlineFilter />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: OnSelectChange,
  };

  const totalWeight = (record) => {
    let Weight = record.boxes.reduce(function (tot, arr) {
      return Number(Number(tot) + Number(arr.chargable_weight)).toFixed(2);
    }, 0);
    return Weight;
  };

  const columns = [
    {
      title: "TRIP NO",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <div
          onClick={() => OnSelectChange([record.id], [record])}
          className="Trip-DataTable-column"
        >
          <div className="data-table-items">{text}</div>
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),

      render: (text, record, index) => (
        <Tooltip title={record.name && record.name}>
          <div
            className="Trip-DataTable-column"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="Trip-DataTable-item">
              {record.user && record.user.name && record.user.name}
            </div>
          </div>
        </Tooltip>
      ),
    },

    {
      title: "VEHICLE NO",
      dataIndex: "truck_no",
      key: "truck_no",
      render: (text, record, index) => (
        <div
          onClick={() => OnSelectChange([record.id], [record])}
          className="Trip-DataTable-column"
        >
          <div className="data-table-items">{text}</div>
        </div>
      ),
    },
    {
      title: "DRIVER NAME",
      dataIndex: "driver_name",
      key: "driver_name",
      render: (text, record, index) => (
        <div
          onClick={() => OnSelectChange([record.id], [record])}
          className="Trip-DataTable-column"
        >
          <div className="data-table-items">{text}</div>
        </div>
      ),
    },
    {
      title: "From Branch",
      dataIndex: "loggin_branch",
      key: "loggin_branch",
      render: (text, record, index) => (
        <div
          onClick={() => OnSelectChange([record.id], [record])}
          className="Trip-DataTable-column"
        >
          <div className="data-table-items">
            {record.loggin_branch && record?.loggin_branch.name}
          </div>
        </div>
      ),
    },
    {
      title: "To Branch",
      dataIndex: "to_branch",
      key: "to_branch",
      render: (text, record, index) => (
        <div
          onClick={() => OnSelectChange([record.id], [record])}
          className="Trip-DataTable-column"
        >
          <div className="data-table-items">
            {record.to_branch && record?.to_branch.name}
          </div>
        </div>
      ),
    },

    {
      title: "T. Pcs",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        console.log(record),
        (
          <div>
            {record.boxes && record.boxes.length ? record.boxes.length : "N/A"}
          </div>
        )
      ),
    },
    {
      title: "T. Weight",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div className="data-table-items">
          {totalWeight(record) ? totalWeight(record) : "N/A"}
        </div>
      ),
    },
    {
      title: "BOOKING DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => (
        <div
          onClick={() => OnSelectChange([record.id], [record])}
          className="Trip-DataTable-column"
        >
          <div className="data-table-items">{moment(text).format("l")}</div>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        rowKey="id"
        columns={columns}
        rowSelection={{ type: "radio", ...rowSelection }}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;
