import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  InputNumber,
  Switch,
  message,
  Tooltip,
} from "antd";
import { Row, Col, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import BoxItemsForm from "./BoxItemsForm";
import { IoMdRemoveCircleOutline, IoIosAddCircle } from "react-icons/io";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { BiReset } from "react-icons/bi";
import moment from "moment";
import { clear } from "../../Redux/Slices/CollectionSlice";
function BoxForm(props) {
  const Auth = useSelector((state) => state.Auth.user);
  const dispatch = useDispatch();
  const formRef = useRef();
  const [expand, setexpand] = useState(0);
  const [data, setData] = useState([]);

  const [editrate, setEditRate] = useState(false);

  const onFinish = (values) => {
    let next = Number(props.count) + 1;
    props.next(next, data);
    props.onTabchange("2");
    message.info("Box info added");
  };

  const handleTotal = (action, values) => {
    const rowsCopy = [...values.boxs];
    let numberBoxes = values.boxs && values.boxs.length;
    let totalCargo = 0;
    let totalPacking = 0;
    let totalDuty = 0;
    let totalOther = 0;
    let totalWeight = 0;
    let L = 0;
    let B = 0;
    let H = 0;

    values.boxs.forEach((fields, index) => {
      let chargableWeight = 0;
      let ChargableRate = 0;
      let totalValue = 0;
      let totalValueINR = 0;
      fields.uuid = props.uuid && props.uuid;
      let number = `${Auth.id}${moment().unix()}`;
      let serviceCheck = fields.service_type
        ? props.servicetype.filter((i) => i.id === fields.service_type)
        : 0;

      if (
        !fields.total_value_edit &&
        fields.boxitems &&
        fields.boxitems.length
      ) {
        fields.boxitems.forEach((boxitems) => {
          let qty = boxitems ? Number(boxitems.quantity) : 0;
          let value = boxitems ? Number(boxitems.value) : 0;
          totalValue = Number(totalValue) + Number(Number(qty) * Number(value));
        });
      } else {
        totalValue = fields.total_value;
      }

      if (!fields.total_value_inr_edit) {
        fields.total_value_inr = Number(
          Number(totalValue) * Number(props.curreny.INR)
        ).toFixed(2);
      } else {
        totalValueINR = fields.total_value_inr;
        totalValue = Number(
          Number(totalValueINR) * Number(props.curreny.SAR)
        ).toFixed(2);
      }

      fields.box_number = fields.box_number ? fields.box_number : number;
      if (fields.size !== 0) {
        let BOXTYPE = fields.size
          ? props.boxtype.filter((i) => i.id === fields.size)[0]
          : null;
        L = BOXTYPE ? Number(BOXTYPE.length).toFixed(1) : 0;
        B = BOXTYPE ? Number(BOXTYPE.breadth).toFixed(1) : 0;
        H = BOXTYPE ? Number(BOXTYPE.height).toFixed(1) : 0;
      } else {
        L = fields.length ? fields.length : null;
        B = fields.breadth ? fields.breadth : null;
        H = fields.height ? fields.height : null;
      }

      let SERVICE = serviceCheck.length ? serviceCheck[0].value : 0;
      let WEIGHT = fields.weight ? Number(fields.weight).toFixed(1) : 0;
      let packing = fields.packing_charge ? fields.packing_charge : 0;
      let duty = fields.duty ? fields.duty : 0;
      let other = fields.other_charges ? fields.other_charges : 0;
      // let rate = fields.rate ? fields.rate : 0;
      let rate =
        serviceCheck && serviceCheck[0].rate_per_kg
          ? serviceCheck[0].rate_per_kg
          : fields.rate;
      if (editrate) rate = fields.rate;
      fields.rate = rate;
      // end values form form
      let volumeWeight = Number((L * B * H) / SERVICE).toFixed(1);
      if (!fields.chargable_weight_edit) {
        chargableWeight =
          Number(volumeWeight) >= Number(WEIGHT) ? volumeWeight : WEIGHT;
      } else {
        chargableWeight = fields.chargable_weight;
      }
      totalWeight = Number(
        Number(totalWeight) + Number(chargableWeight)
      ).toFixed(2);
      totalCargo = Number(totalCargo) + Number(fields.cargo_cahrge);
      totalPacking = Number(totalPacking) + Number(fields.packing_charge);
      totalDuty = Number(totalDuty) + Number(fields.duty);
      totalOther = Number(totalOther) + Number(fields.other_charges);
      ChargableRate = rate ? Number(rate) * Number(chargableWeight) : 0;
      let totalValueUSD = Number(
        Number(totalValue) * Number(props.curreny.USD)
      ).toFixed(2);

      //set fields
      fields.length = L;
      fields.breadth = B;
      fields.height = H;
      fields.volume_weight = Number(volumeWeight) ? volumeWeight : 0;
      fields.chargable_weight = chargableWeight;
      fields.total_value = totalValue;
      fields.total_value_usd = Number(totalValueUSD);

      fields.total_charge = (
        Number(packing) +
        Number(duty) +
        Number(other) +
        Number(ChargableRate)
      ).toFixed(2);
      rowsCopy.splice(index, 1, fields);
      formRef.current.setFieldsValue({ boxs: rowsCopy });

      //end set fields
    });
    //set common fields
    formRef.current.setFieldsValue({
      number_boxes: numberBoxes,
      total_weight: totalWeight,
      status: 2,
      collected_by: Auth.id,
    });

    let obj = {
      cargo_charge: totalCargo,
      packing_charge: totalPacking,
      other_charge: totalOther,
      duty: totalDuty,
      ...values,
    };
    props.onchange(props.count, obj);
    setData(obj);
  };

  const clearForm = () => {
    dispatch(clear());
    props.onClose();
  };

  return (
    <div>
      <Form
        ref={formRef}
        hideRequiredMark
        onFinish={onFinish}
        onValuesChange={handleTotal}
        initialValues={
          props.mode === "put"
            ? {
                boxs: props.item && props.item.boxes,
                service_type:
                  props.servicetype && props.servicetype.length
                    ? props.servicetype[0].id
                    : null,
                number_boxes: props.item && props.item.number_boxes,
                total_weight: props.item && props.item.total_weight,
                collected_by: props.item && props.item.collected_by,
                collected_datetime:
                  props.data && moment(props.data.collected_datetime),
              }
            : {
                boxs: props.data && props.data.boxs,
                service_type:
                  props.servicetype && props.servicetype.length
                    ? props.servicetype[0].id
                    : null,
                number_boxes: props.data && props.data.number_boxes,
                total_weight: props.data && props.data.total_weight,
                collected_by: props.data && props.data.collected_by,
                collected_datetime:
                  props.data && moment(props.data.collected_datetime),
              }
        }
      >
        <div className="boxForm-box1">
          <div className="collection-customerform-txt1">Boxes Details</div>
          <div onClick={() => clearForm()} className="boxForm-reset-icon">
            <Tooltip title={"Reset Form"}>
              <BiReset color="#009EDB" size={20} />
            </Tooltip>
          </div>
        </div>
        <Form.List
          name="boxs"
          initialValue={[
            {
              box_number: `${Auth.id}${moment().unix()}`,
            },
          ]}
        >
          {(fields, { add, remove }) => {
            return (
              <Table bordered size="sm" className="formtable">
                {fields.map((field, index) => (
                  <>
                    <thead
                      key={index}
                      className="FormBoxHeader"
                      style={{ borderTop: 10 }}
                    >
                      <tr>
                        <th colSpan={2} className="FormItemBoxHeadertxt">
                          <Form.Item
                            label={
                              <span style={{ color: "#fff" }}>
                                Box {index + 1}
                              </span>
                            }
                            {...field}
                            name={[field.name, "box_number"]}
                            fieldKey={[field.fieldKey, "box_number"]}
                            style={styles.FormItem}
                          >
                            <Input
                              size="small"
                              placeholder="Box Number"
                              readOnly
                              style={styles.FormInput2}
                            />
                          </Form.Item>
                        </th>
                        <th colSpan={2} className="">
                          {expand === index ? null : (
                            <Form.Item
                              label={
                                <span style={{ color: "#fff" }}>Weight</span>
                              }
                              {...field}
                              name={[field.name, "chargable_weight"]}
                              fieldKey={[field.fieldKey, "chargable_weight"]}
                              style={styles.FormItem}
                            >
                              <Input
                                size="small"
                                placeholder=""
                                readOnly
                                style={styles.FormInput2}
                              />
                            </Form.Item>
                          )}
                        </th>
                        <th colSpan={2} className="">
                          {expand === index ? (
                            <Form.Item
                              label={
                                <span style={{ color: "#fff" }}>
                                  Total Value
                                </span>
                              }
                              {...field}
                              name={[field.name, "total_value"]}
                              fieldKey={[field.fieldKey, "total_value"]}
                              style={styles.FormItem}
                            >
                              <InputNumber
                                placeholder="0"
                                readOnly
                                size="small"
                                style={styles.FormInput2}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              label={
                                <span style={{ color: "#fff" }}>Service</span>
                              }
                              {...field}
                              name={[field.name, "service_type"]}
                              fieldKey={[field.fieldKey, "service_type"]}
                              style={styles.FormItem}
                            >
                              <Input
                                size="small"
                                style={styles.FormInput2}
                                placeholder=""
                                readOnly
                              />
                            </Form.Item>
                          )}
                        </th>
                        <th colSpan={2} className="">
                          <Form.Item
                            label={
                              <span style={{ color: "#fff" }}>Amount</span>
                            }
                            {...field}
                            name={[field.name, "total_charge"]}
                            fieldKey={[field.fieldKey, "total_charge"]}
                            style={styles.FormItem}
                          >
                            <Input
                              size="small"
                              style={styles.FormInput2}
                              placeholder=""
                              readOnly
                            />
                          </Form.Item>
                        </th>
                        <th>
                          <Form.Item
                            {...field}
                            name={[field.name, "box_ref_no"]}
                            fieldKey={[field.fieldKey, "box_ref_no"]}
                            style={styles.FormItem3}
                          >
                            <Input
                              placeholder="reference number"
                              style={styles.FormInput3}
                            />
                          </Form.Item>
                        </th>
                        <th colSpan={1}>
                          {fields.length === 1 ? null : (
                            <div className="tableAction">
                              {expand === index ? null : (
                                <MdOutlineKeyboardArrowDown
                                  size={20}
                                  color="#fff"
                                  style={{ marginBottom: 2, marginRight: 10 }}
                                  onClick={() => setexpand(index)}
                                />
                              )}
                              <IoMdRemoveCircleOutline
                                size={20}
                                color="#fff"
                                style={{ marginBottom: 2 }}
                                onClick={() => {
                                  setexpand(index === 0 ? index : index - 1);
                                  remove(field.name);
                                }}
                              />
                            </div>
                          )}
                        </th>
                      </tr>
                    </thead>
                    {expand === index ? (
                      <tbody style={{ borderTop: 10 }}>
                        <tr>
                          <td colSpan={10}>
                            <BoxItemsForm
                              items={props.item}
                              fieldKey={field.name}
                              mode={props.mode}
                            />
                          </td>
                        </tr>
                        <tr className="FormBoxFooter">
                          <td colSpan={3}>
                            <Form.Item
                              label="Service Type"
                              {...field}
                              name={[field.name, "service_type"]}
                              fieldKey={[field.fieldKey, "service_type"]}
                              style={styles.FormItemNew}
                            >
                              <Select
                                placeholder="Service Type"
                                size="small"
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {props.servicetype.map((option) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </td>
                          <td colSpan={3}>
                            <Form.Item
                              label="size"
                              {...field}
                              name={[field.name, "size"]}
                              fieldKey={[field.fieldKey, "size"]}
                              style={styles.FormItemNew}
                            >
                              <Select
                                placeholder="Box Type"
                                size="small"
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {props.boxtype.map((option) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option.name}
                                  </Select.Option>
                                ))}
                                <Select.Option key={0} value={0}>
                                  Other
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </td>
                          <td colSpan={1}>
                            <Form.Item
                              label="L"
                              {...field}
                              name={[field.name, "length"]}
                              fieldKey={[field.fieldKey, "length"]}
                              style={styles.FormItem2New}
                            >
                              <Input
                                placeholder="0"
                                size="small"
                                style={styles.FormInput}
                              />
                            </Form.Item>
                          </td>
                          <td colSpan={1}></td>
                          <td colSpan={1}>
                            <Form.Item
                              label="B"
                              {...field}
                              name={[field.name, "breadth"]}
                              fieldKey={[field.fieldKey, "breadth"]}
                              style={styles.FormItem2New}
                            >
                              <Input
                                placeholder="0"
                                size="small"
                                style={styles.FormInput}
                              />
                            </Form.Item>
                          </td>

                          <td colSpan={1}>
                            <Form.Item
                              label="H"
                              {...field}
                              name={[field.name, "height"]}
                              fieldKey={[field.fieldKey, "height"]}
                              style={styles.FormItem2New}
                            >
                              <Input
                                placeholder="0"
                                size="small"
                                style={styles.FormInput}
                              />
                            </Form.Item>
                          </td>
                        </tr>
                        <tr className="FormBoxFooter">
                          <td colSpan={2}>
                            <Form.Item
                              label="Actual Weight"
                              {...field}
                              name={[field.name, "weight"]}
                              fieldKey={[field.fieldKey, "weight"]}
                              style={styles.FormItemNew}
                            >
                              <InputNumber
                                size="small"
                                style={styles.FormInput}
                                placeholder="0"
                              />
                            </Form.Item>
                          </td>

                          <td colSpan={2}>
                            <Form.Item
                              label={"Volume Weight"}
                              {...field}
                              name={[field.name, "volume_weight"]}
                              fieldKey={[field.fieldKey, "volume_weight"]}
                              style={styles.FormItem}
                            >
                              <InputNumber
                                placeholder="0"
                                readOnly
                                style={styles.FormInput}
                                size="small"
                              />
                            </Form.Item>
                          </td>
                          <td colSpan={4}>{``}</td>
                          <td colSpan={2}>
                            <div className="tableAction">
                              <div style={{ marginRight: 10 }}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "chargable_weight_edit"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "chargable_weight_edit",
                                  ]}
                                  style={styles.FormItem}
                                  valuePropName={
                                    [field.fieldKey, "chargable_weight_edit"]
                                      ? "checked"
                                      : "null"
                                  }
                                >
                                  <Switch size="small" />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  label={"Chargable Weight"}
                                  {...field}
                                  name={[field.name, "chargable_weight"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "chargable_weight",
                                  ]}
                                  style={styles.FormItem}
                                >
                                  <InputNumber
                                    placeholder="0"
                                    style={styles.FormInput}
                                    size="small"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr className="FormBoxFooter">
                          <td colSpan={2}>
                            <Form.Item
                              label={"Rate / KG"}
                              {...field}
                              name={[field.name, "rate"]}
                              fieldKey={[field.fieldKey, "rate"]}
                              style={styles.FormItemNew}
                            >
                              <InputNumber
                                placeholder="Rate"
                                size="small"
                                style={styles.FormInput}
                                onChange={() => setEditRate(true)}
                              />
                            </Form.Item>
                          </td>
                          <td colSpan={2}>
                            <Form.Item
                              label={"Packing Charge"}
                              {...field}
                              name={[field.name, "packing_charge"]}
                              fieldKey={[field.fieldKey, "packing_charge"]}
                              style={styles.FormItem}
                            >
                              <InputNumber
                                placeholder="Charge"
                                size="small"
                                style={styles.FormInput}
                              />
                            </Form.Item>
                          </td>
                          <td colSpan={2}>
                            <Form.Item
                              label={"Duty"}
                              {...field}
                              name={[field.name, "duty"]}
                              fieldKey={[field.fieldKey, "duty"]}
                              style={styles.FormItem}
                            >
                              <InputNumber
                                placeholder="Charge"
                                size="small"
                                style={styles.FormInput}
                              />
                            </Form.Item>
                          </td>
                          <td colSpan={2}>
                            <Form.Item
                              label={"Other"}
                              {...field}
                              name={[field.name, "other_charges"]}
                              fieldKey={[field.fieldKey, "other_charges"]}
                              style={styles.FormItem}
                            >
                              <InputNumber
                                placeholder="Charge"
                                size="small"
                                style={styles.FormInput}
                              />
                            </Form.Item>
                          </td>
                          <td colSpan={4}>{``}</td>
                        </tr>
                        <tr className="FormBoxFooter">
                          <td colSpan={2}>
                            <div className="tableAction">
                              <div style={{ marginRight: 10 }}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "total_value_edit"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "total_value_edit",
                                  ]}
                                  style={styles.FormItemNew}
                                  valuePropName={
                                    [field.fieldKey, "total_value_edit"]
                                      ? "checked"
                                      : "null"
                                  }
                                >
                                  <Switch size="small" />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  label={"Total Value"}
                                  {...field}
                                  name={[field.name, "total_value"]}
                                  fieldKey={[field.fieldKey, "total_value"]}
                                  style={styles.FormItemNew}
                                >
                                  <InputNumber
                                    placeholder="Charge"
                                    size="small"
                                    style={styles.FormInput}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </td>
                          <td colSpan={2}>
                            <Form.Item
                              label={"Total Value (USD)"}
                              {...field}
                              name={[field.name, "total_value_usd"]}
                              fieldKey={[field.fieldKey, "total_value_usd"]}
                              style={styles.FormItemNew}
                            >
                              <InputNumber
                                placeholder="Charge"
                                size="small"
                                style={styles.FormInput}
                                readOnly
                              />
                            </Form.Item>
                          </td>
                          <td colSpan={2}>
                            <div style={{ display: "flex" }}>
                              <div style={{ marginRight: 10 }}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "total_value_inr_edit"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "total_value_inr_edit",
                                  ]}
                                  style={styles.FormItemNew}
                                  valuePropName={
                                    [field.fieldKey, "total_value_inr_edit"]
                                      ? "checked"
                                      : "null"
                                  }
                                >
                                  <Switch size="small" />
                                </Form.Item>
                              </div>
                              <Form.Item
                                label={"Total Value (INR)"}
                                {...field}
                                name={[field.name, "total_value_inr"]}
                                fieldKey={[field.fieldKey, "total_value_inr"]}
                                style={styles.FormItem}
                              >
                                <InputNumber
                                  placeholder="Charge"
                                  size="small"
                                  style={styles.FormInput}
                                />
                              </Form.Item>
                            </div>
                          </td>
                          <td colSpan={4}></td>
                        </tr>
                      </tbody>
                    ) : null}
                  </>
                ))}
                <br />
                <Button
                  onClick={() => {
                    setexpand(fields.length);
                    add();
                  }}
                  style={{
                    borderRadius: "6px",
                  }}
                  type="primary"
                  icon={<IoIosAddCircle color="#fff" size={25} />}
                >
                  <span className="newBoxSpan">Add New Box</span>
                </Button>
              </Table>
            );
          }}
        </Form.List>
        <br />
        <Row>
          <Col sm="4" xs="12">
            <div className="formlabel label-red">Number of Boxes</div>
            <Form.Item name={"number_boxes"}>
              <Input
                style={styles.FormItemInput}
                placeholder="Volume Weight"
                readOnly
              />
            </Form.Item>
          </Col>
          <Col sm="4" xs="12">
            <div className="formlabel label-red">Total Chargable Weight</div>
            <Form.Item name={"total_weight"}>
              <Input
                style={styles.FormItemInput}
                placeholder="Chargable Weight"
                readOnly
              />
            </Form.Item>
          </Col>

          <Col sm="4" xs="12">
            <div className="formlabel label-red">Collected Date Time</div>
            <Form.Item
              name="collected_datetime"
              rules={[{ required: true, message: "required" }]}
            >
              <DatePicker
                style={styles.FormItemInput}
                placeholder="select date time"
              />
            </Form.Item>
          </Col>
          <Col sm="4" xs="12" style={{ visibility: "hidden", display: "none" }}>
            <div className="formlabel label-red">Collected By</div>
            <Form.Item name="collected_by">
              <Input />
            </Form.Item>
            <div className="d-none">
              <Form.Item label="status" name="status">
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row className="boxformFinalBtn">
          <Col sm="3" xs="6">
            <Button
              style={styles.NextButton}
              block
              size="large"
              htmlType="submit"
            >
              Save & Next
            </Button>
          </Col>
          <Col sm="3" xs="6">
            <Button
              onClick={() => props.onClose()}
              block
              size="large"
              style={styles.BackButton}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default withRouter(BoxForm);
