import React, { useRef } from "react";
import { Modal, Button } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import logo from "../../Assets/Images/logoNew.jpg";
import ReactToPrint from "react-to-print";
import moment from "moment";

function PrintModal(props) {
	const componentRef = useRef();
	console.log(props);
	console.log(
		props.item.airwaybill,
		"===========================props.item.airwaybill.psc"
	);

	return (
		<div>
			<Modal
				open={props.visible}
				onCancel={props.onCancel}
				width={900}
				footer={null}>
				<div ref={componentRef}>
					<div className="print-invoice-page">
						<table className="print-invoice-table">
							<tr>
								<td colSpan={2}>
									<img src={logo} width="" className="InvoiceLogo" />
								</td>
								<td colSpan={2}></td>
								<td colSpan={2} style={{ textTransform: "uppercase" }}>
									<div> LOGIN ID:{props.item.user && props.item.user.name} </div>
									<div>DATE : {moment(props.item.createdAt).format("l")}</div>
									{props.item.airwaybill &&
										props.item.airwaybill?.slice(0, 1).map((item, index) => {
											const totalPsc = props.item.airwaybill.reduce(
												(accumulator, currentItem) =>
													accumulator + parseInt(currentItem.psc, 10),
												0
											);
											const totalWeight = props.item.airwaybill.reduce(
												(accumulator, currentItem) =>
													accumulator + currentItem.chargable_weight,
												0
											);
											return (
												<>
													<div>TOTAL AWB No : {props.item.airwaybill.length}</div>
													<div>TOTAL PCS : {totalPsc}</div>
													<div>TOTAL WEIGHT : {totalWeight}</div>
												</>
											);
										})}
								</td>
							</tr>

							<tr>
								<td colSpan={6}>
									<hr />
								</td>
							</tr>
							<tr>
								<td colSpan={6}>
									<table className="print-invoice-table">
										<tr>
											<th className="print-invoice-table-td" colSpan={2}>
												<div className="print-customsInvoice-txt5">AWB No</div>
											</th>
											<th className="print-invoice-table-td" colSpan={2}>
												<div className="print-customsInvoice-txt5">Pieces</div>
											</th>
											<th className="print-invoice-table-td" colSpan={2}>
												<div className="print-customsInvoice-txt5">Kilo</div>
											</th>
											<th className="print-invoice-table-td" colSpan={2}>
												<div className="print-customsInvoice-txt5">Destination Airport</div>
											</th>
										</tr>
										{props.item.airwaybill &&
											props.item.airwaybill.map((item, index) => {
												return (
													<tr>
														<td className="print-invoice-table-td" colSpan={2}>
															<div className="print-customsInvoice-txt5">
																{`${item?.airline} - ${item.awb_no}`}
															</div>
														</td>
														<td className="print-invoice-table-td" colSpan={2}>
															<div className="print-customsInvoice-txt5">{item.psc}</div>
														</td>
														<td className="print-invoice-table-td" colSpan={2}>
															<div className="print-customsInvoice-txt5">
																{item.chargable_weight}
															</div>
														</td>
														<td className="print-invoice-table-td" colSpan={2}>
															<div className="print-customsInvoice-txt5">
																{item.destination_airport_name}
															</div>
														</td>
													</tr>
												);
											})}
									</table>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<Row>
					<Col sm="6"></Col>
					<Col sm="3">
						<Button onClick={() => props.onCancel()} block size="large">
							Cancel
						</Button>
					</Col>
					<Col sm="3">
						<ReactToPrint
							trigger={() => (
								<Button type="primary" size="large" block>
									Print
								</Button>
							)}
							content={() => componentRef.current}
						/>
					</Col>
				</Row>
			</Modal>
		</div>
	);
}

export default PrintModal;
