import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button, message, Checkbox, Card } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ImAirplane } from "react-icons/im";
import { BsBox } from "react-icons/bs";
import { GrCheckboxSelected } from "react-icons/gr";
import { AiOutlineSearch } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import { API, Headers } from "../../Config";
import PageHeader from "../../Routes/PageHeader";
import LoadingBox from "../../Components/LoadingBox";
import { Select, DatePicker, Input } from "antd";
import moment from "moment";
import UnAssignedModal from "./component/UnAssignAWBModal";
const { RangePicker } = DatePicker;

function AssignBoxesToAwbBill() {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [data, setData] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [boxes2, setBoxes2] = useState([]);
  const [tobeassigned, setToBeAssigned] = useState([]);
  const [hover, setHover] = useState(false);
  const [items, setItems] = useState(boxes);
  const [filter, setFilter] = useState(false);
  const [assignedModalShow, setUnAssignedModalShow] = useState(false);
  const [selectedAwb, setSelectedAwb] = useState("");
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [service, setServices] = useState([]);
  const { Option } = Select;

  const Auth = useSelector((state) => state.Auth.user);

  useEffect(() => {
    loaddata();
    listUnAssigned();
    loadServiceType();
  }, []);

  const loaddata = () => {
    setLoading(true);
    axios
      .get(API.BASR_URL + API.AIRWAY_BILLS_GEN + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const listUnAssigned = () => {
    setLoading2(true);
    axios
      .get(API.BASR_URL + API.BOXES_LIST + "/UnAssigned", {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setBoxes(data);
          setBoxes2(data);
          setLoading2(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const loadServiceType = () => {
    axios
      .get(API.BASR_URL + API.SERVICE_TYPE_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setServices(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const reload = () => {
    loaddata();
    setToBeAssigned([]);
  };

  const selectAwb = (id) => {
    console.log("selectAwb ========>", id);
    setSelectedAwb(id);
  };

  const selectBoxes = (boxes) => {
    console.log("boxes ====>", boxes);
    let boxIds = [];
    boxes.map((item, index) => {
      console.log(item[0].box_number);
      let id = item[0].box_number;
      boxIds.push(id);
    });
    setSelectedBoxes(boxIds);
  };

  const onSearchBox = (val) => {
    setLoading2(true);
    axios
      .get(API.BASR_URL + API.BOXES_VIA_SEARCH + val, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          if (data.length <= 0) {
            message.error(`Boxss ${val} Not Found`);
          }
          setBoxes(data);
          setLoading2(false);
        }
      })
      .catch(function (error) {
        message.error(`Boxss ${val} Not Found`);
        console.log(error);
        setLoading2(false);
      });
  };

  const onChooseServiceType = (value) => {
    setLoading2(true);
    let arr = boxes2;
    const newArr = arr.filter((data) => data?.service?.name === value);
    setBoxes(newArr);
    setTimeout(() => {
      setLoading2(false);
    }, 500);
  };

  const onChooseDate = (date) => {
    let fromDate = moment(date[0]).toISOString();
    let toDate = moment(date[1]).toISOString();
    console.log("fromDate =========>", fromDate);
    console.log("toDate =========>", toDate);
    setLoading2(true);
    axios
      .get(
        API.BASR_URL + API.BOXES_LIST + `/listUnAssigned/${toDate}/${fromDate}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setBoxes(data);
          console.log(response);
          setLoading2(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error("Something went wrong ........");
        setLoading(false);
      });
  };

  const onFinish = () => {
    let requestObj = {
      airway_bill_no: selectedAwb,
      boxIds: selectedBoxes,
      updated_user: Number(Auth.id),
      status: 7,
      created_from: "web",
    };

    console.log(requestObj);

    axios({
      method: "post",
      url: API.BASR_URL + API.BOXES_LIST + `/assignBoxesToAwb`,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          reload();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  const Column = ({ children, className, title, length }) => {
    const [, drop] = useDrop({
      accept: "box",
      drop: () => ({ name: title }),
    });
    return (
      <div ref={drop} className={`${className} AssignBox-box8  `}>
        {`${title} (${length})`}
        {children}
      </div>
    );
  };

  const MovableItem = (item) => {
    var arr = tobeassigned;
    const [{ isDragging }, drag] = useDrag({
      item: {
        index: item.item.box_number,
      },
      type: "box",
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();

        if (dropResult && dropResult.name === "Assigned") {
          // console.log("dropResult ===>", dropResult);
          let box = boxes.filter((i) => i.box_number === item.index);
          arr.push(box);

          let checkIndex = boxes.findIndex((i) => i.box_number === item.index);
          if (checkIndex >= 0) {
            boxes.splice(checkIndex, 1);
          }
          forceUpdate();
        } else {
          //   setIsFirstColumn(false);
        }
        setToBeAssigned(arr);
        selectBoxes(tobeassigned);
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.4 : 1;

    return (
      <div className="movable-item" ref={drag} style={{ opacity }}>
        <div className="AssignBox-box2">
          <div className="AssignBox-box3">
            <BsBox color="#9c27b0" size={40} />
          </div>
          <div className="AssignBox-box4"></div>
          <div className="AssignBox-box5">
            <div className="AssignBox-box6">
              <div className="AssignBox-txt2">BOX No :</div>
              <div className="AssignBox-txt3">
                {item.columnName === "UnAssigned"
                  ? item.item.box_number && item.item.box_number
                  : item.item[0]?.box_number && item.item[0]?.box_number}
              </div>
            </div>
            <div className="AssignBox-box6">
              <div className="AssignBox-txt2">Service :</div>
              <div className="AssignBox-txt3">
                {item.columnName === "UnAssigned"
                  ? item.item.service && item.item.service.name
                  : item.item[0]?.service.name && item.item[0]?.service.name}
              </div>
            </div>
            <div className="AssignBox-box6">
              <div className="AssignBox-txt2">Weight :</div>
              <div className="AssignBox-txt3">
                {item.columnName === "UnAssigned"
                  ? item.item.chargable_weight
                  : item.item[0]?.chargable_weight &&
                    item.item[0]?.chargable_weight}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const returnItemsForColumn = (columnName) => {
    var arr = columnName === "UnAssigned" ? boxes : tobeassigned;
    return arr.map((item, index) => (
      <MovableItem
        key={item.box_number}
        item={item}
        setItems={items}
        columnName={columnName}
      />
    ));
  };

  return (
    <div>
      <PageHeader
        PageName={"Assign Boxes To AWB"}
        breadScrum={"Admin /Assign Boxes To AWB"}
        loader={indicator}
        RightButton={"Assign"}
        RightClcik={() => onFinish()}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <Container fluid>
          <DndProvider backend={HTML5Backend}>
            <Card style={{ borderRadius: 10 }}>
              <div className="AssignBox-box9">
                <Button
                  style={{
                    borderRadius: 4,
                    fontSize: 12,
                  }}
                  onClick={() => {
                    setUnAssignedModalShow(true);
                  }}
                >
                  Remove From AWB
                </Button>
                &nbsp; &nbsp;
                {filter ? (
                  <div className="AssignBox-box10">
                    <RangePicker
                      style={{ borderRadius: 6, marginRight: 10 }}
                      onChange={onChooseDate}
                    />

                    <Select
                      showSearch
                      className="AssignBox-borderedSelect"
                      bordered={false}
                      placeholder="Select Service Type"
                      optionFilterProp="children"
                      onChange={onChooseServiceType}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ borderRadius: 6, marginRight: 10 }}
                    >
                      {service.map((option) => (
                        <Option key={option.id} value={option.name}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                    <div style={{ margin: 5 }} />
                  </div>
                ) : null}
                <div className="AssignBox-box10">
                  <Input
                    placeholder="Search Box"
                    onPressEnter={(val) => onSearchBox(val.target.value)}
                    prefix={<AiOutlineSearch color="#999" />}
                    style={{ borderRadius: 6 }}
                  />
                  <div style={{ margin: 5 }} />
                  <div
                    className="AssignBox-box11"
                    onClick={() => setFilter(!filter)}
                  >
                    <FaFilter />
                  </div>
                </div>
              </div>
              <br />

              <Row>
                <Col sm="4" xs="4">
                  <div className="AssignBox-box1">
                    <div className="AssignBox-txt4">Airway Bills</div>
                    {data
                      .sort((a, b) => b.id - a.id)
                      .map((item, index) => {
                        if (item.is_locked === false) {
                          return (
                            <>
                              <div className="AssignBox-box2">
                                <div className="AssignBox-box3">
                                  <ImAirplane
                                    color={
                                      item.is_locked
                                        ? "rgb(200,200,200)"
                                        : "#1e88e5"
                                    }
                                    size={40}
                                    key={index}
                                  />
                                </div>
                                <div className="AssignBox-box4"></div>

                                <div className="AssignBox-box7">
                                  <div className="AssignBox-box5">
                                    <div className="AssignBox-txt1">{`${item.airline}-${item.awb_no} `}</div>
                                    <div className="AssignBox-txt5">
                                      {`${
                                        item.orijin_airport_name &&
                                        item.orijin_airport_name
                                      } - ${
                                        item.destination_airport_name &&
                                        item.destination_airport_name
                                      }`}
                                    </div>
                                  </div>
                                  <div>
                                    <Checkbox
                                      onClick={() => selectAwb(item.id)}
                                      value={item.id}
                                      checked={item.id == selectedAwb}
                                      disabled={item.is_locked}
                                    ></Checkbox>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                </Col>
                <Col sm="4" xs="4">
                  <Column
                    title="Assigned"
                    className="column first-column"
                    length={tobeassigned.length}
                  >
                    {returnItemsForColumn("Assigned")}
                  </Column>
                </Col>
                <Col sm="4" xs="4">
                  {loading2 ? (
                    <LoadingBox />
                  ) : (
                    <Column
                      title="UnAssigned"
                      className="column second-column"
                      length={boxes.length}
                    >
                      {returnItemsForColumn("UnAssigned")}
                    </Column>
                  )}
                </Col>
              </Row>
            </Card>
          </DndProvider>
          {assignedModalShow ? (
            <UnAssignedModal
              visible={assignedModalShow}
              onClose={() => setUnAssignedModalShow(false)}
            />
          ) : null}
        </Container>
      )}
    </div>
  );
}

export default AssignBoxesToAwbBill;
