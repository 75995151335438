import React, { useState, useEffect } from "react";
import { Modal, message, Form, Input } from "antd";
import LoadingBox from "../../Components/LoadingBox";
import { Row, Col } from "react-bootstrap";
import styles from "./styles";
import "./style.css";

var width = window.innerWidth;

function CashRcptModal(props) {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      title={<span className="newFormModalTitle">Cash reciept</span>}
      visible={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={width / 2.1}
    >
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <br />
          <Form>
            <Row>
              <Col xs="6">
                <div className="transaction-label">Transaction id</div>
              </Col>
              <Col xs="6">
                <Form.Item name={"total_weight"}>
                  <Input
                    style={styles.FormItemInput}
                    placeholder={props.item.collection_uuid}
                    readOnly
                  />
                </Form.Item>
              </Col>
              <Col xs="6">
                <div className="transaction-label">Box Number</div>
              </Col>
              <Col xs="6">
                <Form.Item name={"total_weight"}>
                  <Input
                    style={styles.FormItemInput}
                    placeholder={props.item.box_number}
                    readOnly
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Modal>
  );
}
export default CashRcptModal;
