const style = {
  loginForm: {
    marginBottom: "9px",
  },
  loginFormItem: {
    borderRadius: 8,
    border: "1px solid #1e88e5",
  },
  Input: {
    borderRadius: "8px",
  },
};
export default style;
