import React, { useState, useEffect, useRef } from "react";
import { Modal, message, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";

import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import "./style.css";
import GenQrCode from "./GenQrCode";
import logo from "../../Assets/Images/logoNew.jpg";

import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfTaxInvoice from "./PdfBills/PdfTaxInvoice";

function PrintTaxInvoiceModal(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const componentRef = useRef();

  let requestData = {
    is_tax_invoice_print: true,
  };
  const isPrinted = () => {
    axios({
      method: "put",
      url:
        API.BASR_URL +
        API.BOXES_ACTIONS +
        "print/" +
        props.item.box_number +
        "/is_tax_invoice_print",
      headers: Headers(),
      data: requestData,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onClose();
          props.reload();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Modal
      visible={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={900}
      footer={null}
    >
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <div>
            {/* <Row>
              <Col sm="3" xs="3">
                <ReactToPrint
                  trigger={() => (
                    <Button
                      type="primary"
                      block
                      onClickCapture={() => {
                        isPrinted();
                      }}
                    >
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              </Col>
              <Col sm="3" xs="3">
                <PDFDownloadLink
                  document={<PdfTaxInvoice data={data} />}
                  fileName={"TaxInvoice.pdf"}
                >
                  {({ blob, url, loading, error }) => {
                    return loading ? null : <Button block>Download</Button>;
                  }}
                </PDFDownloadLink>
              </Col>
              <Col sm="6" xs="6"></Col>
            </Row> */}
          </div>
          <br />
          <div ref={componentRef} className="print-taxInvoice-modal">
            <table className="print-taxInvoice-table">
              <tr>
                <td colSpan="2">
                  <img src={logo} width="" className="InvoiceLogo" />
                </td>
                <td colSpan="3">
                  <div className="print-taxInvoice-table-headertxt">
                    <div
                      className="print-invoice-table-headertxt1"
                      style={{ fontSize: "13px", textTransform: "uppercase" }}
                    >
                      HO:
                      {props.item.created_user && props.item.created_user.branch
                        ? `${
                            (props.item.created_user.branch?.name,
                            props.item.created_user.branch?.office_address)
                          }`
                        : null}
                    </div>
                    <div className="print-invoice-table-headertxt1">
                      PH:
                      {props.item.created_user && props.item.created_user.branch
                        ? `${props.item.created_user.branch?.mobile}`
                        : null}
                    </div>
                    <div className="print-invoice-table-headertxt1">
                      VAT NO: 300602378400003
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="5">
                  <div className="print-invoice-txt4">TAX INVOICE</div>
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <div className="print-invoice-txt1">SENDER INFO</div>
                  <div className="print-invoice-txt2">
                    {props.item.collection && props.item.collection.sender_name}
                  </div>
                  <div className="print-invoice-txt2">
                    {props.item.collection &&
                      props.item.collection.sender_address}
                  </div>
                  <div className="print-invoice-txt2">
                    {props.item.collection &&
                      props.item.collection.sender_phone}
                  </div>
                  <hr />
                  <div className="print-invoice-txt1">RECIEVER INFO</div>
                  <div className="print-invoice-txt2">
                    {props.item.collection &&
                      props.item.collection.reciver_name}
                  </div>
                  <div className="print-invoice-txt2">
                    {props.item.collection &&
                      props.item.collection.reciver_address}
                  </div>
                  <div className="print-invoice-txt2">
                    {props.item.collection &&
                      props.item.collection.reciver_phone}
                    ,
                    {props.item.collection &&
                      props.item.collection.reciver_altphone}
                  </div>
                  <div className="print-invoice-txt2">
                    {props.item.collection &&
                      props.item.collection.reciver_pincode}
                    ,{""}
                    {props.item.collection &&
                      props.item.collection.reciver_city}
                  </div>
                  <div className="print-invoice-txt2">
                    {props.item.collection &&
                      props.item.collection.reciver_distrcit}
                    ,{""}
                    {props.item.collection &&
                      props.item.collection.reciver_state}
                    ,
                    {props.item.collection &&
                      props.item.collection.recivercountries &&
                      props.item.collection.recivercountries.name}
                  </div>
                </td>
                <td colSpan={2} style={{ display: "flex" }}>
                  <div className="bordered">
                    <GenQrCode item={props.item} />
                  </div>
                </td>
                <td colSpan={2}>
                  <div className="print-invoice-txt3">
                    INVOICE NO:<strong>INV{moment().unix()}</strong>
                  </div>

                  <div className="print-invoice-txt3">
                    INVOICE DATE:
                    <strong>
                      {props.item?.createdAt &&
                        moment(props.item?.createdAt).format("DD-MM-YYYY")}
                    </strong>
                  </div>

                  <div className="print-invoice-txt3">
                    BOOKED STAFF:{" "}
                    <strong>
                      {props.item.created_user && props.item.created_user.name}
                    </strong>
                  </div>
                  <div className="print-invoice-txt3">
                    BOOKED BRANCH:{" "}
                    <strong>
                      {props.item.created_user &&
                        props.item.created_user.branch &&
                        props.item.created_user.branch.name}
                    </strong>
                  </div>

                  <div className="print-invoice-txt3">
                    BOOKED DATE:
                    <strong>
                      {props.item?.createdAt &&
                        moment(props.item?.createdAt).format("DD-MM-YYYY")}
                    </strong>
                  </div>

                  <div className="print-invoice-txt3">
                    REFRENCE:{" "}
                    <strong>
                      {props.item.box_ref_no
                        ? props.item.box_ref_no
                        : props.item.referance_details
                        ? props.item.referance_details
                        : ""}
                    </strong>
                  </div>
                  <div className="print-invoice-txt3">
                    BOX SIZE:{" "}
                    <strong>
                      {props.item.boxType && props.item.boxType.name}{" "}
                      {props.item &&
                        `${props.item.length} x ${props.item.breadth} x ${props.item.height}`}
                    </strong>
                  </div>

                  <div className="print-invoice-txt3">
                    CHARGABLE WEIGHT:{" "}
                    <strong>{props.item && props.item.chargable_weight}</strong>
                  </div>

                  <div className="print-invoice-txt3">
                    RATE PER KILO:{" "}
                    <strong>{props.item && props.item.rate}</strong>
                  </div>

                  <div className="print-invoice-txt3">
                    PACKING CHARGE:{" "}
                    <strong>{props.item && props.item.packing_charge}</strong>
                  </div>

                  <div className="print-invoice-txt3">
                    DUTY: <strong>{props.item && props.item.duty}</strong>
                  </div>

                  <div className="print-invoice-txt3">
                    OTHER:{" "}
                    <strong>{props.item && props.item.other_charges}</strong>
                  </div>

                  <div className="print-invoice-txt3">
                    AMOUNT:{" "}
                    <strong>{props.item && props.item.total_charge}</strong>
                  </div>
                  <div className="print-invoice-txt3">
                    VAT: <strong>0</strong>
                  </div>
                  <div className="print-invoice-txt3">
                    TOTAL:{" "}
                    <strong>{props.item && props.item.total_charge}</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="borderRight">
                    <Barcode
                      value={props.item && props.item.box_number}
                      height="60"
                      fontSize="12px"
                      font="Montserrat-Regular"
                    />
                  </div>
                </td>
                <td colSpan="1"></td>
                <td colSpan="2">
                  <span className="print-taxInvoice-txt3">
                    {props.item.service && props.item.service.name}
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <Row>
            <Col sm="6"></Col>
            <Col sm="3">
              <Button onClick={() => props.onClose()} block size="large">
                Cancel
              </Button>
            </Col>
            <Col sm="3">
              <ReactToPrint
                trigger={() => (
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClickCapture={() => {
                      isPrinted();
                    }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
export default PrintTaxInvoiceModal;
