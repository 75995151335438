import React, { useEffect, useState } from "react";
import axios from "axios";
import { API, Headers } from "../../Config";
import PageHeader from "../../Routes/PageHeader";
import LoadingBox from "../../Components/LoadingBox";
import ReportTable from "./ReportTable";
import MenuBar from "./MenuBar";

function ExpenceReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [indicator, setIndicator] = useState(false);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [branch, setBranch] = useState([]);
  const [obj, setObj] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    loadUsers();
    loadBranches();
  }, []);

  const loadUsers = () => {
    axios
      .get(API.BASR_URL + API.USERS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setUser(data);
        }
      });
  };

  const loadBranches = () => {
    axios
      .get(
        `http://localhost:8082/expance_entry/report/5%2F12%2F2023/5%2F12%2F2023/5`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setBranch(data);
        }
      });
  };

  const onFinish = (val) => {
    setIsLoading(true);
    let requestObj = val;
    setObj(requestObj);
    console.log(requestObj);

    axios({
      method: "get",
      url: `http://localhost:8082/expance_entry/report/5%2F12%2F2023/5%2F12%2F2023/${selectedUserId}`,
      data: requestObj,
      headers: Headers(),
    })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          console.log(data);
          setData(data);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
    setSelectedUserId(requestObj.user_id);
  };
  return (
    <div>
      <PageHeader PageName={"Report"} breadScrum={"Admin / Report/ Export"} />
      <MenuBar user={user} branches={branch} onFinish={onFinish} />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <ReportTable data={data} obj={obj} selectedUserId={selectedUserId} />
      )}
    </div>
  );
}

export default ExpenceReport;
