const API = {
  // BASR_URL: "http://localhost:8085/",
  BASR_URL: "https://sahari-test-server.bairuhatech.com/",
  TAXGO_BASE_URL: "https://taxgov2-server.taxgoglobal.com/taxgov2/",
  LOGIN: "users/login",
  SIGNUP: "users/publicregister",
  REGISTER: "users/register",

  //statuses
  STATUS_LIST: "status", ///GET
  STATUS_ACTION: "status/", //PUT, POST , DELETE

  //Countriess
  COUNTRIES_LIST: "country", //GET ?order=ASC&page=1&take=10
  COUNTRIES_ACTIONS: "country/", //PUT, POST , DELETE

  //Export Broker
  EXPORT_BROKER_LIST: "exportBroker", //GET ?order=ASC&page=1&take=10
  EXPORT_BROKER_ACTIONS: "exportBroker/", //PUT, POST , DELETE

  //staffs
  STAFFS_LIST: "staffs", //GET ?order=ASC&page=1&take=10
  STAFFS_ACTION: "staffs/", //PUT, POST , DELETE

  //agents
  AGENTS_LIST: "agents", //GET ?order=ASC&page=1&take=10
  AGENTS_ACTION: "agents/", //PUT, POST , DELETE

  //Delivery Agent
  DELIVERY_AGENT_LIST: "deliveryAgent", //GET ?order=ASC&page=1&take=10
  DELIVERY_AGENT_ACTIONS: "deliveryAgent/", //PUT, POST , DELETE

  //Delivery STATUS UPDATE
  DELIVERY_STATUS_UPDATE: "boxesNew/updateDelivery", //POST
  DELIVERY_STATUS_UPDATE2: "boxesNew/updateDelivery2", //POST

  //awb xary agents
  AWB_XRAY_AGENT_LIST: "awbXrayAgent", //GET ?order=ASC&page=1&take=10
  AWB_XRAY_AGENT_ACTIONS: "awbXrayAgent/", //PUT, POST , DELETE

  //clearing agents
  CLEARING_AGENT_LIST: "clearingAgent", //GET ?order=ASC&page=1&take=10
  CLEARING_AGENT_ACTIONS: "clearingAgent/", //PUT, POST , DELETE

  //customers
  CUSTOMERS_LIST: "customers", //GET ?order=ASC&page=1&take=10
  CUSTOMERS_ACTION: "customers/", //PUT, POST , DELETE

  //drivers
  DRIVERS_LIST: "drivers", //GET ?order=ASC&page=1&take=10
  DRIVERS_ACTION: "drivers/", //PUT, POST , DELETE

  //airway bills
  AIRWAY_BILLS: "airwaybillseries", //GET ?order=ASC&page=1&take=10
  AIRWAY_BILLS_ACTION: "airwaybillseries/", //PUT, POST , DELETE

  //airway bill generation
  AIRWAY_BILLS_GEN: "airwaybills", //GET ?order=ASC&page=1&take=10
  AIRWAY_BILLS_GEN_ACTION: "airwaybills/", //PUT, POST , DELETE
  AIRWAY_BILLS_SEARCH: "airwaybills/like/", //Search data

  //Ports
  PORTS_LIST: "ports", //GET ?order=ASC&page=1&take=10
  PORTS_ACTIONS: "ports/", //PUT, POST , DELETE

  //Airline
  AIRLINE_LIST: "airlines", //GET ?order=ASC&page=1&take=10
  AIRLINE_ACTIONS: "airlines/", //PUT, POST , DELETE

  //Expenses
  EXPENSES_LIST: "expense", //GET ?order=ASC&page=1&take=10
  EXPENSES_ACTIONS: "expense/", //PUT, POST , DELETE

  //Branches
  BRANCHES_LIST: "branches", //GET ?order=ASC&page=1&take=10
  BRANCHES_ACTION: "branches/", //PUT, POST , DELETE

  //Transactions
  TRANSACTIONS_LIST: "transactions", //GET ?order=ASC&page=1&take=10
  TRANSACTIONS_ACTION: "transactions/", //PUT, POST , DELETE

  //Senders
  SENDERS_LIST: "senders", //GET ?order=ASC&page=1&take=10
  SENDERS_ACTION: "senders/", //PUT, POST , DELETE

  //Recivers
  RECIVERS_LIST: "receivers", //GET ?order=ASC&page=1&take=10
  RECIVERS_ACTION: "receivers/", //PUT, POST , DELETE

  //boxes
  BOXES_LIST: "boxesNew", //GET ?order=ASC&page=1&take=10
  BOXES_ACTIONS: "boxesNew/", //PUT, POST , DELETE
  BOXES_VIA_AWB: "boxesNew/byAwbNo/", //GET BOXES VIA AIRWAY BILL
  BOXES_VIA_TRIP: "boxesNew/byTrip/", //GET BOXES VIA TRIP
  BOXES_VIA_BRANCH_ID: "boxesNew/getStock", //GET BOXES VIA BRANCH_ID
  BOXES_VIA_BRANCH_BY_BOXDETAILS: "boxesNew/getboxDetailsByUser/",
  BOXES_VIA_SEARCH: "boxesNew/searchByBoxNo/",
  AWB_BOX_REMOVE: "boxesNew/unassignFromAwb", //POST
  TRIP_BOX_REMOVE: "boxesNew/unassignFromTrip", //POST
  BOXUPDATEPAYMENT: "boxesNew/updateStaffCustomerPaid", //PUT

  //documents

  //trip
  TRIP_LIST: "trip", //GET ?order=ASC&page=1&take=10
  TRIP_ACTIONS: "trip/", //PUT, POST , DELETE

  //pickups request
  PICKUP_COLLECTION_REQUEST_LIST: "PickupCollectionRequest", //GET ?order=ASC&page=1&take=10
  PICKUP_COLLECTION_REQUEST_ACTIONS: "PickupCollectionRequest/", //PUT, POST , DELETE

  //awb suppliers
  AWB_SUPPLIER_LIST: "awbSupplier", //GET ?order=ASC&page=1&take=10
  AWB_SUPPLIER_ACTIONS: "awbSupplier/", //PUT, POST , DELETE

  //service types
  SERVICE_TYPE_LIST: "serviceType", //GET ?order=ASC&page=1&take=10
  SERVICE_TYPE_ACTIONS: "serviceType/", //PUT, POST , DELETE

  //box items
  BOXE_ITEMS_LIST: "boxeItems", //GET ?order=ASC&page=1&take=10
  BOXE_ITEMS_ACTIONS: "boxeItems/", //PUT, POST , DELETE

  //grop list
  GROUPS_LIST: "groups", //GET ?order=ASC&page=1&take=10
  GROUPS_ACTIONS: "groups/", //PUT, POST , DELETE

  //users
  USERS_LIST: "users", //GET ?order=ASC&page=1&take=10
  USERS_ACTIONS: "users/", //PUT, POST , DELETE
  SEARCH_USER: "users/search", // POSTpickupCollectionsNew
  ALL_USERS: "users/allUsers", // POST
  ONE_USER: "users/user/",
  UPDATE_ACCESS: "users/update_access",
  NEW_USER_UPDATE: "users/user-update-new/",

  //modules
  LIST_MODULES: "modules/list",

  //roles
  ROLE_LIST: "role", //GET ?order=ASC&page=1&take=10
  ROLE_ACTIONS: "role/", //PUT, POST , DELETE

  //Collections
  PICKUPCOLLECTIONS_LIST: "pickupCollectionsNew", //GET ?order=ASC&page=1&take=10
  PICKUPCOLLECTIONS_ACTIONS: "pickupCollectionsNew/", //PUT, POST , DELETE
  PICKUPCOLLECTIONS_ACTIONS_BY_ID: "pickupCollectionsNew/by_user/", //PUT, POST , DELETE
  PICKUPCOLLECTIONS_LIST_SEARCH: "pickupCollectionsNew/phone/list", // POST

  //items
  ITEMS_LIST: "itemsRequest", //GET ?order=ASC&page=1&take=10
  ITEMS_ACTIONS: "itemsRequest/", //PUT, POST , DELETE

  //box type
  BOXTYPE_LIST: "boxType", //GET ?order=ASC&page=1&take=10
  BOXTYPE_ACTIONS: "boxType/", //PUT, POST , DELETE

  //Issuing Carry Agent
  ISSUING_CARRY_AGENT_LIST: "CarrierAgent", //GET ?order=ASC&page=1&take=10
  ISSUING_CARRY_AGENT_ACTIONS: "CarrierAgent/", //PUT, POST , DELETE

  //Reference Number
  REFERENCE_NUM_LIST: "referance",
  REFERENCE_NUM_ACTIONS: "referance/",

  //Pincode
  PINCODE_LIST: "pincode", //GET ?order=ASC&page=1&take=10
  PINCODE_ACTIONS: "pincode/", //PUT, POST , DELETE
  PINCODE_SEARCH: "pincode/search_by_all/", //SEARCH BY ANY

  //Third Party Recievers
  THIRD_PARTY_SENDERS: "ThirdPartySenders", //GET ?order=ASC&page=1&take=10
  THIRD_PARTY_ACTIONS: "ThirdPartySenders/", //PUT, POST , DELETE
  //ThirdPartytrans
  THIRD_PARTY_TRANS: "ThirdPartytrans", //GET ?order=ASC&page=1&take=10
  THIRD_PARTY_TRANS_ACTIONS: "ThirdPartytrans/", //PUT, POST , DELETE
  //expance Entry
  EXPANCE_ENTRY: "expance_entry", //GET ?order=ASC&page=1&take=10
  EXPANCE_ENTRY_ACTIONS: "expance_entry/", //PUT, POST , DELETE
  //ledger_transaction_colln
  LEDGER_TRANS_COLLN_ENTRY: "CollectionPayment", //GET ?order=ASC&page=1&take=10
  LEDGER_TRANS_COLLN_ENTRY_ACTIONS: "CollectionPayment/", //PUT, POST , DELETE

  ACCOUNTS_CATEGORY_LIST: "accountCategory", //GET ?order=ASC&page=1&take=10
  ACCOUNTS_CATEGORY_ACTIONS: "accountCategory/", //PUT, POST , DELETE

  //get invoiceNo
  ACCOUNTS_LEVEL_LIST: "levels", //GET ?order=ASC&page=1&take=10
  ACCOUNTS_LEVEL_ACTIONS: "levels/", //PUT, POST , DELETE
  //  chart_of_accounts
  CHART_OF_ACCOUNTS_LIST: "chart_of_accounts", //GET ?order=ASC&page=1&take=10
  CHART_OF_ACCOUNTS_ACTIONS: "chart_of_accounts/", //PUT, POST , DELETE
  //  voucher
  VOUCHER_LIST: "voucher", //GET ?order=ASC&page=1&take=10
  VOUCHER_ACTIONS: "voucher/", //PUT, POST , DELETE

  AWB_PAYMENT_LIST: "AwbPayment", //GET ?order=ASC&page=1&take=10
  AWB_PAYMENT_ACTIONS: "AwbPayment/", //PUT, POST , DELETE

  DASHBOARD_LIST: "pickupCollectionsNew/collection/allCounts", //GET

  //exchange_rate
  GET_EXCH_RATE: "exchange_rates", //GET
  EXCHANGE_RATE: "exchange_rates/", //GET
  EXCHANGE_RATE_LIST: "exchange_rates/list", //GET

  //update password
  UPDATEPASSWORD: "users/admin/updatePassword",

  // reset password
  RESET_PASSWORD: "users/admin/",

  // TaxGoo
  GET_SALES_BY_ID: "public_api/getSalesInvoice/",
  CLOSE_TRANSACTION_PAYMENT_TAXGO: "public_api/close_payment",

  // allSuppliearList

  ALL_SUPPLIERLIST: "agents/allSupplierlist",
};
export default API;
