import React, { useEffect, useState } from "react";
import "./styles.css";

import axios from "axios";
import { message, Tabs } from "antd";
import { useSelector } from "react-redux";
import PageHeader from "../../Routes/PageHeader";
import MenuBar from "./MenuBar";
import DataTable from "./DataTables/DataTable";
import DataTable2 from "./DataTables/DataTable2";
import DataTable3 from "./DataTables/DataTable3";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import PrintLabelModal from "./PrintModal/PrintLabelModal";
import PrintAwbModal from "./PrintModal/PrintAwbModal";
import PrintManifestModal from "./PrintModal/PrintManifestModal";
import PrintManifestModal2 from "./PrintModal/PrintManifestModal2";
import PrintManifestModalC from "./PrintModal/PrintManifestModal-C";
import PrintDocumentModal from "./PrintModal/PrintDocumentModal";
import PrintDeliveryListModal from "./PrintModal/PrintDeliveryListModal";
import PrintCustomsInvoiceModal from "./PrintModal/PrintCustomsInvoiceModal";
import PrintTaxInvoiceScreenModal from "./PrintModal/PrintTaxInvoiceScreenModal";
import FormModal from "./FormModal/FormModal";
import FormPaymentProcessModal from "./FormModal/FormPaymentProcessModal";
import FormInfoBranchModal from "./FormModal/FormInfoBranchModal";
import DeliveryFileModal from "./FormModal/DeliveryFileModal";
import DeliveryFileModal2 from "./FormModal/DeliveryFileModal2";
import PrintCustomsInvoiceModalB from "./PrintModal/PrintCustomsInvoiceModalB";
import PrintCustomInvoicePdf from "./PrintModal/PrintCustomInvoicePdf";
import PrintManifestModalD from "./PrintModal/PrintManifestModal-D";

function AirwayBillProScreen() {
  const Auth = useSelector((state) => state.Auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(20);
  const [meta, setMeta] = useState({});
  const [printlabel, setPrintlabel] = useState(false);
  const [printAwb, setPrintAwb] = useState(false);
  const [printmanifest, SetPrintManifest] = useState(false);
  const [printmanifest2, SetPrintManifest2] = useState(false);
  const [printmanifestC, SetPrintManifestC] = useState(false);
  const [printmanifestD, SetPrintManifestD] = useState(false);
  const [printdocs, setPrintDocs] = useState(false);
  const [printdeliverylist, setPrintdeliverylist] = useState(false);
  const [printcustoms, setPrintcustoms] = useState(false);
  const [printcustomsB, setPrintcustomsB] = useState(false);
  const [printPdf, setPrintPdf] = useState(false);
  const [taxScreen, setTaxScreen] = useState(false);
  const [form, setForm] = useState(false);
  const [paymentprocessform, setPaymentProcessform] = useState(false);
  const [branchModal, setBranchModal] = useState(false);
  const [branches, setBranches] = useState([]);
  const [mode, setMode] = useState("post");

  const [outbound, setOutbound] = useState([]);
  const [inbound, setInbound] = useState([]);
  const [pending, setPending] = useState([]);

  const [deliveryModal1, setDeliveryModal1] = useState(false);
  const [deliveryModal2, setDeliveryModal2] = useState(false);

  const { TabPane } = Tabs;

  useEffect(() => {
    loadData(page, take);
    loadBranches();
  }, []);

  const loadData = (p, t) => {
    try {
      axios
        .get(
          API.BASR_URL +
            API.AIRWAY_BILLS_GEN +
            `?order=DESC&page=${p}&take=${t}`,
          {
            headers: Headers(),
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setData(data.data);
            filterAwb(data.data);
            setMeta(data.meta);
            setIsLoading(false);
            setIndicator(false);

            message.success("success");
          } else {
            message.error("Oops.Something went");
            setIsLoading(false);
            setIndicator(false);
          }
        });
    } catch (err) {
      console.log("loadData ==>", err);
    }
  };

  const loadBranches = () => {
    try {
      axios
        .get(API.BASR_URL + `branches/list`, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setBranches(data);
          } else {
            message.error("Oops.Something went");
            setIsLoading(false);
            setIndicator(false);
          }
        });
    } catch (err) {
      console.log("loadData ==>", err);
    }
  };

  const filterAwb = (data) => {
    let arr = data;
    let arr2 = data;
    let arr3 = data;
    const OutBoundTrip = arr.filter(
      (data) => data.users && data.users?.branch?.id === Auth.branch_id
    );
    const InboundTrip = arr2.filter(
      (data) =>
        data.received_users &&
        data?.received_users?.branch?.id === Auth.branch_id
    );
    const PendingTrip = arr3.filter((data) => {
      return (
        data?.to_branch_id &&
        data?.to_branch_id === Auth?.branch_id &&
        data?.awb_status === "start"
      );
    });

    setOutbound(OutBoundTrip);
    setInbound(InboundTrip);
    setPending(PendingTrip);

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const reload = () => {
    setIndicator(true);
    setIsLoading(true);
    loadData(page, take);
    setForm(false);
    setPaymentProcessform(false);
  };

  const refresh = () => {
    setIndicator(true);
    loadData(page, take);
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loadData(page, take);
  };

  const selectCell = (row) => {
    setSelected(row);
  };

  const DeleteItem = () => {
    setIndicator(true);
    const AWBID = selected && selected[0].id;
    axios
      .delete(API.BASR_URL + API.AIRWAY_BILLS_GEN_ACTION + AWBID, {
        headers: Headers(),
      })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        reload();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  const AddNew = () => {
    setSelected([]);
    setForm(true);
    setMode("New");
  };

  const UpdateItem = () => {
    setMode("put");
    setForm(true);
  };

  const CopyItem = () => {
    setMode("Copy");
    setForm(true);
  };

  //Menubar Search
  const SearchAwb = (val) => {
    // setIsLoading(true);
    setIndicator(true);
    axios
      .get(API.BASR_URL + API.AIRWAY_BILLS_GEN + "/awb_no/" + val, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          filterAwb(data);
          setIsLoading(false);
          setIndicator(false);
        } else {
          message.error("Oops.Something went");
          setIsLoading(false);
          setIndicator(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const selectDuration = (val) => {
    setIsLoading(true);
    setIndicator(true);
    axios
      .get(
        API.BASR_URL +
          API.AIRWAY_BILLS_GEN +
          "/by_date/" +
          val.key +
          `?order=ASC&page=1&take=10`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setIsLoading(false);
          setIndicator(false);
        } else {
          message.error("Oops.Something went");
          setIsLoading(false);
          setIndicator(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <PageHeader
        PageName={"Airway Bills"}
        breadScrum={"Admin / Airway Bills"}
        RightButton={"Generate AWB"}
        RightClcik={() => AddNew()}
        loader={indicator}
      />
      <MenuBar
        singleSelect={selected.length ? false : true}
        onSearch={(val) => SearchAwb(val)}
        onDatePicker={(val) => selectDuration(val)}
        onOpen={() => UpdateItem(selected)}
        onDelete={() => DeleteItem()}
        onCopy={() => CopyItem(selected)}
        onAction={() => console.log("DataTable ==> onSelect")}
        onRefresh={() => refresh()}
        printlabel={() => setPrintlabel(true)}
        printAwb={() => setPrintAwb(true)}
        printmanifest={() => SetPrintManifest(true)}
        printmanifest2={() => SetPrintManifest2(true)}
        printmanifestC={() => SetPrintManifestC(true)}
        printmanifestD={() => SetPrintManifestD(true)}
        printdocs={() => setPrintDocs(true)}
        printdeliverylist={() => setPrintdeliverylist(true)}
        printcustoms={() => setPrintcustoms(true)}
        printcustomsB={() => setPrintcustomsB(true)}
        printPdf={() => setPrintPdf(true)}
        taxScreen={() => setTaxScreen(true)}
        paymentprocessform={() => setPaymentProcessform(true)}
        informBranch={() => setBranchModal(true)}
        delivery1={() => setDeliveryModal1(true)}
        delivery2={() => setDeliveryModal2(true)}
      />
      <br />
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Tabs>
          <TabPane tab={"Inbound Awb"} key="1">
            <DataTable
              data={inbound}
              meta={meta}
              onSelect={(data) => selectCell(data)}
              update={(item) => UpdateItem(item)}
              delete={(item) => DeleteItem(item)}
              PageOnchange={(page, take) => pagination(page, take)}
            />
          </TabPane>
          <TabPane tab={"Outbound Awb"} key="2">
            <DataTable2
              data={outbound}
              meta={meta}
              onSelect={(data) => selectCell(data)}
              update={(item) => UpdateItem(item)}
              delete={(item) => DeleteItem(item)}
              PageOnchange={(page, take) => pagination(page, take)}
            />
          </TabPane>
          <TabPane tab={"Pending Awb"} key="3">
            <DataTable3
              data={pending}
              meta={meta}
              onSelect={(data) => selectCell(data)}
              update={(item) => UpdateItem(item)}
              delete={(item) => DeleteItem(item)}
              PageOnchange={(page, take) => pagination(page, take)}
            />
          </TabPane>{" "}
        </Tabs>

        // <DataTable
        //   data={data}
        //   meta={meta}
        //   selected={selected}
        //   onSelect={(data) => selectCell(data)}
        //   PageOnchange={(page, take) => pagination(page, take)}
        // />
      )}
      {printlabel ? (
        <PrintLabelModal
          selected={selected[0]}
          visible={printlabel}
          onClose={() => setPrintlabel(false)}
        />
      ) : null}
      {printAwb ? (
        <PrintAwbModal
          item={selected}
          visible={printAwb}
          onClose={() => setPrintAwb(false)}
        />
      ) : null}
      {printmanifest ? (
        <PrintManifestModal
          selected={selected}
          visible={printmanifest}
          onClose={() => SetPrintManifest(false)}
        />
      ) : null}
      {printmanifest2 ? (
        <PrintManifestModal2
          selected={selected}
          visible={printmanifest2}
          onClose={() => SetPrintManifest2(false)}
        />
      ) : null}
      {printmanifestC ? (
        <PrintManifestModalC
          selected={selected}
          visible={printmanifestC}
          onClose={() => SetPrintManifestC(false)}
        />
      ) : null}
      {printmanifestD ? (
        <PrintManifestModalD
          selected={selected}
          visible={printmanifestD}
          onClose={() => SetPrintManifestD(false)}
        />
      ) : null}
      {printdocs ? (
        <PrintDocumentModal
          selected={selected}
          visible={printdocs}
          onClose={() => setPrintDocs(false)}
        />
      ) : null}
      {printdeliverylist ? (
        <PrintDeliveryListModal
          selected={selected[0]}
          visible={printdeliverylist}
          onClose={() => setPrintdeliverylist(false)}
        />
      ) : null}
      {printcustoms ? (
        <PrintCustomsInvoiceModal
          visible={printcustoms}
          selected={selected}
          onClose={() => setPrintcustoms(false)}
        />
      ) : null}
      {printcustomsB ? (
        <PrintCustomsInvoiceModalB
          visible={printcustomsB}
          selected={selected}
          onClose={() => setPrintcustomsB(false)}
        />
      ) : null}
      {printPdf ? (
        <PrintCustomInvoicePdf
          visible={printPdf}
          selected={selected}
          onClose={() => setPrintPdf(false)}
        />
      ) : null}
      {form ? (
        <FormModal
          mode={mode}
          selected={selected[0]}
          visible={form}
          onRefresh={() => reload()}
          onChange={(da) => reload()}
          onClose={() => setForm(false)}
        />
      ) : null}
      {paymentprocessform ? (
        <FormPaymentProcessModal
          selected={selected[0]}
          visible={paymentprocessform}
          onChange={() => reload()}
          onClose={() => setPaymentProcessform(false)}
        />
      ) : null}
      {taxScreen ? (
        <PrintTaxInvoiceScreenModal
          selected={selected[0]}
          visible={taxScreen}
          onCancel={() => setTaxScreen(false)}
        />
      ) : null}
      {branchModal ? (
        <FormInfoBranchModal
          visible={branchModal}
          selected={selected[0]}
          branches={branches}
          onCancel={() => setBranchModal(false)}
        />
      ) : null}
      {deliveryModal1 ? (
        <DeliveryFileModal
          open={deliveryModal1}
          selected={selected[0]}
          close={() => setDeliveryModal1(false)}
        />
      ) : null}
      {deliveryModal2 ? (
        <DeliveryFileModal2
          open={deliveryModal2}
          selected={selected[0]}
          close={() => setDeliveryModal2(false)}
        />
      ) : null}
    </div>
  );
}
export default AirwayBillProScreen;
