import React, { useState, useEffect } from "react";
import LoadingBox from "../../Components/LoadingBox";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { API, Headers } from "../../Config";
import { message, Modal, Form, Select, notification, Pagination } from "antd";
import { GET, POST } from "../../Config/ApiRequests";

function ExchangeRates() {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});
  const [countries, setcountries] = useState([]);
  const [branches, setbranches] = useState([]);
  const [role, setRole] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    getExchangeRates();
    // ContriesDropdown();
    // BranchesDropdown();
    // RoleDropdown();
    // AccountsDropdown();
  }, []);

  useEffect(() => {
    getExchangeRates();
  }, [page, take]);

  // const getExchangeRates = async () => {
  //   try {
  //     setLoading(true);
  //     let api = API.GET_EXCH_RATE;
  //     const excData = await GET(api, null);
  //     if (excData?.status) {
  //       setData(excData?.data);
  //       setMeta(excData.meta);
  //       setLoading(false);
  //     } else {
  //       message.error("No data found");
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     message.error("Oops.Something went");
  //     setLoading(false);
  //   }
  // };

  const getExchangeRates = async () => {
    try {
      setLoading(true);
      let api = API.EXCHANGE_RATE_LIST;
      let obj = {
        page: page,
        take: take,
      };
      const excData = await POST(api, obj);
      if (excData?.status) {
        setData(excData?.data);
        setMeta(excData.meta);
        setLoading(false);
      } else {
        message.error("No data found");
        setLoading(false);
      }
    } catch (err) {
      message.error("Oops.Something went");
      setLoading(false);
    }
  };

  const relaod = () => {
    getExchangeRates();
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const accountHandleClick = (val) => {
    setIsModalVisible(true);
    setUserID(val);
    console.log(val);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const DeleteItem = (item) => {
    console.log(item);
    setIndicator(true);
    axios({
      url: API.BASR_URL + API.EXCHANGE_RATE + item.id,
      method: "delete",
      headers: Headers(),
      data: {
        active: false,
      },
    })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  const modalContent = (
    <div>
      <Form>
        <Form.Item name="account_no">
          <Select
            size="small"
            placeholder="Select account"
            showSearch
            allowClear
            style={{ borderRadius: "0", width: "300px" }}
            onChange={(selectedValue) => {
              // Check if selectedValue is defined and not null
              if (selectedValue !== undefined && selectedValue !== null) {
                console.log(selectedValue); // Log the selected value
                setSelectedAccount(selectedValue); // Set the selected value in your component's state
              }
            }}
            filterOption={(input, option) => {
              const accountNo = option.value.toLowerCase();
              const accountName = option.children.toString().toLowerCase();
              return (
                accountNo.includes(input.toLowerCase()) ||
                accountName.includes(input.toLowerCase())
              );
            }}
          >
            {accounts.map((item, index) => (
              <Select.Option key={item.account_no} value={item.account_no}>
                {item.account_no} - {item.account_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* Add your form inputs or other content here */}
      </Form>
    </div>
  );

  return (
    <div>
      <PageHeader
        PageName={"Exchange Rates"}
        breadScrum={"Admin / ExchangeRates"}
        RightButton={"Add New"}
        RightClcik={() => AddItem()}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            accountHandleClick={(val) => accountHandleClick(val)}
            meta={meta}
            data={data}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            // PageOnchange={(page, take) => pagination(page, take)}
            onPageChange={(page, pageSize) => {
              setLoading(true);
              setPage(page);
              setTake(pageSize);
            }}
          />

          {form ? (
            <FormModal
              item={item}
              mode={mode}
              visible={form}
              countries={countries}
              branches={branches}
              role={role}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default ExchangeRates;
