import React, { useEffect, useRef, useState } from "react";
import { Button, message, Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import { API, Headers } from "../../Config";
import documentloader from "../../Assets/Images/awbcustomsloader.gif";
import logo from "../../Assets/Images/logoNew.jpg";
import GenQrCode from "../Boxes/GenQrCode";
import { BlobProvider } from "@react-pdf/renderer";
import PdfTaxInvoice from "./PdfTaxInvoice";
function PrintTaxInvoice(props) {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    loadBoxesViaTrip();
  }, []);
  const loadBoxesViaTrip = () => {
    const TripID = props.selected && props.selected.id;
    let URL = API.BASR_URL + API.BOXES_VIA_TRIP + TripID;
    axios
      .get(URL, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setIsLoading(false);
        } else {
          message.error("Ooops. Something went wrong...!");
          setIsLoading(false);
        }
      });
  };

  const download = (url) => {
    window.open(url);
  };

  return (
    <div>
      <Modal
        width={900}
        visible={props.visible}
        onCancel={props.onCancel}
        footer={false}
      >
        <div>
          <div>
            <ReactToPrint
              trigger={() => <Button type="primary">Print</Button>}
              content={() => componentRef.current}
            />
            <BlobProvider
              document={<PdfTaxInvoice data={data} selected={props.selected} />}
              fileName={"TaxInvoice.pdf"}
            >
              {({ blob, url, loading, error }) => {
                return loading ? null : (
                  <Button onClick={() => download(url)}>Download</Button>
                );
              }}
            </BlobProvider>
          </div>{" "}
          <br />
          <div ref={componentRef}>
            {isLoading ? (
              <div className="print-customs-box">
                <img src={documentloader} />
              </div>
            ) : (
              <>
                {data &&
                  data.map((item, index) => {
                    console.log("PrintTaxInvoice ====>", item);
                    return (
                      <div className="trip-print-taxInvoice-modal ">
                        <table className="print-taxInvoice-table">
                          <tr>
                            <td colSpan="2">
                              <img
                                src={logo}
                                width=""
                                className="InvoiceLogo"
                              />
                            </td>
                            <td colSpan="3">
                              <div className="print-taxInvoice-table-headertxt">
                                <div
                                  className="print-invoice-table-headertxt1"
                                  style={{ fontSize: "13px" }}
                                >
                                  HO:
                                  {item.created_user && item.created_user.branch
                                    ? `${
                                        (item.created_user.branch?.name,
                                        item.created_user.branch
                                          ?.office_address)
                                      }`
                                    : null}
                                </div>
                                <div className="print-invoice-table-headertxt1">
                                  PH:
                                  {item.created_user && item.created_user.branch
                                    ? `${item.created_user.branch?.mobile}`
                                    : null}
                                </div>
                                <div className="print-invoice-table-headertxt1">
                                  VAT NO: 300602378400003
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="5">
                              <div className="print-invoice-txt4">
                                TAX INVOICE
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="2">
                              <div className="print-invoice-txt1">
                                SENDER INFO
                              </div>
                              <div className="print-invoice-txt2">
                                {item.collection && item.collection.sender_name}
                              </div>
                              <div className="print-invoice-txt2">
                                {item.collection &&
                                  item.collection.sender_address}
                              </div>
                              <div className="print-invoice-txt2">
                                {item.collection &&
                                  item.collection.sender_phone}
                              </div>
                              <hr />
                              <div className="print-invoice-txt1">
                                RECIEVER INFO
                              </div>
                              <div className="print-invoice-txt2">
                                {item.collection &&
                                  item.collection.reciver_name}
                              </div>
                              <div className="print-invoice-txt2">
                                {item.collection &&
                                  item.collection.reciver_address}
                              </div>
                              <div className="print-invoice-txt2">
                                {item.collection &&
                                  item.collection.reciver_phone}
                                ,
                                {item.collection &&
                                  item.collection.reciver_altphone}
                              </div>
                              <div className="print-invoice-txt2">
                                {item.collection &&
                                  item.collection.reciver_pincode}
                                ,{""}
                                {item.collection &&
                                  item.collection.reciver_city}
                              </div>
                              <div className="print-invoice-txt2">
                                {item.collection &&
                                  item.collection.reciver_distrcit}
                                ,{""}
                                {item.collection &&
                                  item.collection.reciver_state}
                                ,
                                {/* {item.collection.recivercountries.name &&
                              item.collection.recivercountries.name} */}
                              </div>
                            </td>
                            <td colSpan={2} style={{ display: "flex" }}>
                              <div className="bordered">
                                <GenQrCode item={item} />
                              </div>
                            </td>
                            <td colSpan={2}>
                              <div className="print-invoice-txt3">
                                INVOICE NO:<strong>INV{moment().unix()}</strong>
                              </div>

                              <div className="print-invoice-txt3">
                                INVOICE DATE:
                                <strong>
                                  {item && moment().format("DD-MM-YYYY")}
                                </strong>
                              </div>

                              <div className="print-invoice-txt3">
                                BOOKED STAFF:{" "}
                                <strong>
                                  {item.created_user && item.created_user.name}
                                </strong>
                              </div>
                              <div className="print-invoice-txt3">
                                BOOKED BRANCH:{" "}
                                <strong>
                                  {item.created_user &&
                                    item.created_user.branch &&
                                    item.created_user.branch.name}
                                </strong>
                              </div>

                              <div className="print-invoice-txt3">
                                BOOKED DATE:
                                <strong>
                                  {item &&
                                    moment(item.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                </strong>
                              </div>

                              <div className="print-invoice-txt3">
                                REFRENCE:{" "}
                                <strong>
                                  {item.collection.referance_details &&
                                    item.collection.referance_details}
                                </strong>
                              </div>
                              <div className="print-invoice-txt3">
                                BOX SIZE:{" "}
                                <strong>
                                  {item.boxType && item.boxType.name}{" "}
                                  {item &&
                                    `${item.length} x ${item.breadth} x ${item.height}`}
                                </strong>
                              </div>

                              <div className="print-invoice-txt3">
                                CHARGABLE WEIGHT:{" "}
                                <strong>{item && item.chargable_weight}</strong>
                              </div>

                              <div className="print-invoice-txt3">
                                RATE PER KILO:{" "}
                                <strong>{item && item.rate}</strong>
                              </div>

                              <div className="print-invoice-txt3">
                                PACKING CHARGE:{" "}
                                <strong>{item && item.packing_charge}</strong>
                              </div>

                              <div className="print-invoice-txt3">
                                DUTY: <strong>{item && item.duty}</strong>
                              </div>

                              <div className="print-invoice-txt3">
                                OTHER:{" "}
                                <strong>{item && item.other_charges}</strong>
                              </div>

                              <div className="print-invoice-txt3">
                                AMOUNT:{" "}
                                <strong>{item && item.total_charge}</strong>
                              </div>
                              <div className="print-invoice-txt3">
                                VAT: <strong>0</strong>
                              </div>
                              <div className="print-invoice-txt3">
                                TOTAL:{" "}
                                <strong>{item && item.total_charge}</strong>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={5}>
                              <hr />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <div className="borderRight">
                                <Barcode
                                  value={item && item.box_number}
                                  height="60"
                                  fontSize="12px"
                                  font="Montserrat-Regular"
                                />
                              </div>
                            </td>
                            <td colSpan="1"></td>
                            <td colSpan="2">
                              <span className="print-taxInvoice-txt3">
                                {item.service && item.service.name}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
          <Row>
            <Col sm="6"></Col>
            <Col sm="3">
              <Button onClick={() => props.onCancel()} block size="large">
                Cancel
              </Button>
            </Col>
            <Col sm="3">
              <ReactToPrint
                trigger={() => (
                  <Button type="primary" size="large" block>
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default PrintTaxInvoice;
