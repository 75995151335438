import React, { useEffect, useState } from "react";
import { Modal, Input, message, Button } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import { API, Headers } from "../../../../Config";
import DataTable from "./DataTable";
const { Search } = Input;
function FormAwbGenModal(props) {
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(true);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [selected, setSelected] = useState([]);
  const [searchdata, setSearchdata] = useState([]);

  useEffect(() => {
    loadData(page, take);
  }, []);

  const loadData = (p, t) => {
    axios
      .get(API.BASR_URL + API.AIRWAY_BILLS + `?order=ASC&page=${p}&take=${t}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("success");
          setLoading(false);
        } else {
          message.error("Ooops..");
          setLoading(false);
        }
        setIndicator(false);
      });
  };

  const selectCell = (row) => {
    setSelected(row);
  };

  const Submit = () => {
    props.onChange(selected);
    props.onClose();
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setLoading(true);
    setPage(page);
    setTake(take);
    loadData(page, take);
  };

  const onSearch = (val) => {
    axios
      .get(API.BASR_URL + API.AIRWAY_BILLS_ACTION + `series_no/${val}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
        }
      });
  };

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onClose}
        footer={false}
        width={800}
      >
        <h5 className="PrintLabelModal-txt1">AWB NUMBERS</h5>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div></div>
          <Search
            placeholder=""
            allowClear
            style={{ width: "30%" }}
            onSearch={(val) => onSearch(val)}
          />
        </div>
        <br />

        <DataTable
          data={data}
          meta={meta}
          loading={loading}
          selected={selected}
          onSelect={(data) => selectCell(data)}
          PageOnchange={(page, take) => pagination(page, take)}
        />

        <br />

        <Row>
          <Col sm="6"></Col>
          <Col sm="3">
            <Button type="primary" block onClick={() => Submit()}>
              Select
            </Button>
          </Col>
          <Col sm="3">
            <Button onClick={props.onClose} block>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default FormAwbGenModal;
