import React from "react";
import { Button, Modal, Spin } from "antd";
import axios from "axios";
import { API, Headers } from "../../Config";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import MontserratBold from "../../Assets/Fonts/Montserrat-Bold.ttf";
import PdfLoader from "../../Assets/Images/pdfpreview.gif";
var JsBarcode = require("jsbarcode");

function PdfRefNumModal(props) {
  console.log("PdfRefNumModal=======>", props?.data[0]);
  return (
    <Document>
      {props.data &&
        props.data.map((item, index) => {
          let barcodedata = item && item;
          console.log("++++++ barcodedata +++++++ ", barcodedata);
          let canvas;
          canvas = document.createElement("canvas");
          console.log("++++++ canvas +++++++ ", canvas);
          JsBarcode(canvas, barcodedata, {
            width: 6,
            displayValue: false,
            height: 150,
          });
          const barcode = canvas.toDataURL();
          return (
            <Page size="A6">
              <View style={styles.Container}>
                <View style={styles.Table}>
                  <View style={styles.Box1}>
                    <View style={styles.BarcodeBox}>
                      <Image src={barcode} style={styles.Barcode} />
                    </View>
                  </View>
                  <View style={styles.Box1}>
                    <View>
                      <Text style={styles.Text1}>{item && item}</Text>
                    </View>
                  </View>
                  {/* <View style={styles.Box2}>
                    <View>
                      <Text>{"  "}</Text>
                    </View>
                  </View> */}
                </View>
              </View>
            </Page>
          );
        })}
    </Document>
  );
}

export default PdfRefNumModal;

const styles = StyleSheet.create({
  Container: {
    maxHeight: "101.6mm",
    maxWidth: "101.6mm",
    padding: 5,
  },
  Table: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  Box1: {
    height: "20.11mm",
    textAlign: "center",
  },
  Box2: {
    height: "25.4mm",
  },
  Text1: {
    fontFamily: "Montserrat",
    fontSize: "30px",
    marginTop: 10,
  },
  BarcodeBox: {},
  Barcode: {
    paddingHorizontal: 20,
    marginTop: 10,
  },
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratBold,
    },
  ],
});
