import React from "react";
import { CSVDownload } from "react-csv";
import moment from "moment";

function ExportReport(props) {
  var arr = [];
  {
    props.data &&
      props.data.map((item, index) => {
        console.log("first=====>", item);

        let obj = {
          "DATE ": moment(item.createdat),
          "BOX NUMBER": item.box_number,
          "	BILL NO": item.box_number,
          "SERVICE ": item.service && item.service.name,
          "BRANCH ":
            item.created_user &&
            item.created_user.branch &&
            item.created_user.branch.name,
          "USER ": item.created_user && item.created_user.name,
          "AGENT ": item.agents && item.agents.user.name,
          "KILO ": item.chargable_weight && item.chargable_weight,
          "AMOUNT ": item.total_charge && item.total_charge,
          "SEND ADRESS ": `${
            item.collection.sender_name && item.collection.sender_name
          }, ${
            item.collection.sender_address && item.collection.sender_address
          }`,
          " CNEE ADRESS ": `${
            item.collection.reciver_name && item.collection.reciver_name
          }, ${
            item.collection.reciver_address && item.collection.reciver_address
          }`,
          "BOX SIZE ":
            item.boxType && item.boxType.name ? item.boxType.name : "N/A",
        };
        arr.push(obj);
      });
  }

  return (
    <div>
      <CSVDownload data={arr} />
    </div>
  );
}

export default ExportReport;
