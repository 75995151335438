import React from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import styles from "../styles";
function FormOtherChargeModal(props) {
  const onFinish = (value) => {
    props.onChange(value);
    props.onClose();
  };
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={false}
      width={400}
    >
      <h5 className="PrintLabelModal-txt1">Add Item</h5>

      <Form
        onFinish={onFinish}
        initialValues={{
          description: props.data && props.data.description,
          amount: props.data && props.data.amount,
          entitlement: props.data && props.data.entitlement,
        }}
      >
        <Form.Item name={"description"}>
          <Input placeholder="DESCRIPTION" style={styles.FormInput} />
        </Form.Item>
        <Form.Item name={"amount"}>
          <Input placeholder="AMOUNT" />
        </Form.Item>
        <Form.Item name={"entitlement"}>
          <Select placeholder="ENTITLEMENT">
            <Select.Option value={"A"} key="a">
              DUE AGENT
            </Select.Option>
            <Select.Option value={"C"} key="c">
              DUE CARRIER
            </Select.Option>
          </Select>
        </Form.Item>
        <Row>
          <Col sm="4" xs="12"></Col>
          <Col sm="4" xs="12">
            <Button type="primary" htmlType="submit" block>
              Accept
            </Button>
          </Col>
          <Col sm="4" xs="12">
            <Button block onClick={props.onClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default FormOtherChargeModal;
