import React, { useRef } from "react";
import { Button, Popover, Table } from "antd";
import ReactToPrint from "react-to-print";
import { GrDocumentExcel } from "react-icons/gr";
import moment from "moment";
import ExportReport from "./ExportReport";

function ReportTable(props) {
  const ref = useRef();

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <div>{record.id}</div>,
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text, record, index) => <div>{record.userid}</div>,
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => <div>{moment(text).format("l")}</div>,
    },
    {
      title: "Expense Type",
      dataIndex: "user",
      key: "expenseType",
      render: (text, record, index) => (
        <div className="boxes-table-item">{record.head}</div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "total_charge",
      key: "amount",
      render: (text, record, index) => <div>{record.amount}</div>,
    },
  ];
  return (
    <div>
      <div ref={ref} className="p-2">
        <div
          style={{
            backgroundColor: "#e2e2e2",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "98%",
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div className="Report-table-txt1">
                Report{" "}
                {`from ${moment(props.obj.date_from).format("l")} to ${moment(
                  props.obj.date_to
                ).format("l")}`}
              </div>
            </div>
            <div>
              <div className="Reports-Datatable-box1">
                <ReactToPrint
                  trigger={() => <Button type="primary">Print</Button>}
                  content={() => ref.current}
                />
                <Popover
                  trigger="click"
                  placement="bottom"
                  content={
                    <div>
                      <ExportReport data={props.data} />
                    </div>
                  }
                >
                  <Button type="link">
                    <GrDocumentExcel size={20} />
                  </Button>
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <Table columns={columns} dataSource={props.data} pagination={false} />
      </div>
    </div>
  );
}
export default ReportTable;
