import { message } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import PageHeader from "../../Routes/PageHeader";
import DataTable from "./DataTable";

function VerifyUserScreen() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);

  useEffect(() => {
    loadData(page, take);
  }, []);

  const loadData = (p, t) => {
    axios
      .get(API.BASR_URL + API.USERS_LIST + `/false/list`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  let requestObj = {
    active: true,
  };

  const verifyUser = (id) => {
    axios({
      method: "put",
      url: API.BASR_URL + API.USERS_ACTIONS + id,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        if (response.status === 200) {
          message.success("User Verified");
          loadData(page, take);
        }
      })
      .catch(function (error) {
        console.log(error);
        loadData(page, take);
      });
  };

  return (
    <div>
      <PageHeader
        PageName={"Verify Users"}
        breadScrum={"Admin /Verify Users"}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <DataTable data={data} verify={(id) => verifyUser(id)} />
      )}
    </div>
  );
}

export default VerifyUserScreen;
