import React, { useState, useEffect, useRef } from "react";
import { Modal, message, Button } from "antd";
import LoadingBox from "../../Components/LoadingBox";
import { Table, Col, Row, Container } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import moment from "moment";
import logo from "../../Assets/Images/logoNew.jpg";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import GenQrCode from "./GenQrCode";
import "./style.css";
import Companyseal from "../../Assets/Images/seal.JPG";

function PrintCustomerInvoiceModal2(props) {
  const [loading, setLoading] = useState(false);
  const [boxItems, setBoxItems] = useState([]);
  const componentRef = useRef();

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    setBoxItems(props.item.boxe_item);
    console.log(props.item);
  };

  console.log(props.item);

  const medianBoxNum = Math.floor(boxItems && boxItems.length / 2);

  let requestData = {
    is_customer_invoice_print: true,
  };
  const isPrinted = () => {
    axios({
      method: "put",
      url:
        API.BASR_URL +
        API.BOXES_ACTIONS +
        "print/" +
        props.item.box_number +
        "/is_customer_invoice_print",
      headers: Headers(),
      data: requestData,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onClose();
          props.reload();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Modal
      visible={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={900}
      footer={null}
    >
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <div ref={componentRef}>
            <div className="print-invoice-page">
              <table className="print-invoice-table">
                <tr>
                  <td colSpan={2}>
                    <img src={logo} width="" className="InvoiceLogo" />
                  </td>
                  <td></td>
                  <td colSpan={2}>
                    <div className="print-invoice-table-headertxt">
                      <div
                        className="print-invoice-table-headertxt1"
                        style={{ fontSize: "10px", textTransform: "uppercase" }}
                      >
                        HO:
                        {props.item.created_user &&
                        props.item.created_user.branch
                          ? `${
                              (props.item.created_user.branch?.name,
                              props.item.created_user.branch?.office_address)
                            }`
                          : null}
                      </div>
                      <div
                        className="print-invoice-table-headertxt1"
                        style={{ fontSize: "10px" }}
                      >
                        PH:
                        {props.item.created_user &&
                        props.item.created_user.branch
                          ? `${props.item.created_user.branch?.mobile}`
                          : null}
                      </div>
                      <div
                        className="print-invoice-table-headertxt1"
                        style={{ fontSize: "10px" }}
                      >
                        VAT NO: 300602378400003
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div className="print-invoice-txt4">CUSTOMER INVOICE</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <div className="print-invoice-txt1">SENDER INFO</div>
                    <div className="print-invoice-txt2">
                      {props.item.collection &&
                        props.item.collection.sender_name}
                    </div>
                    <div className="print-invoice-txt2">
                      {props.item.collection &&
                        props.item.collection.sender_address}
                    </div>
                    <div className="print-invoice-txt2">
                      {props.item.collection &&
                        props.item.collection.sender_phone}
                    </div>
                    <hr />
                    <div className="print-invoice-txt1">RECIEVER INFO</div>
                    <div className="print-invoice-txt2">
                      {props.item.collection &&
                        props.item.collection.reciver_name}
                    </div>

                    <div className="print-invoice-txt2">
                      {props.item.collection &&
                        props.item.collection.reciver_address}
                    </div>
                    <div className="print-invoice-txt2">
                      {props.item.collection &&
                        props.item.collection.reciver_phone}
                      ,
                      {props.item.collection &&
                        props.item.collection.reciver_altphone}
                    </div>
                    <div className="print-invoice-txt2">
                      {props.item.collection &&
                        props.item.collection.reciver_pincode}
                      ,{""}
                      {props.item.collection &&
                        props.item.collection.reciver_city}
                      <div className="print-invoices-text3">
                        {props.item.collection?.reciver_post &&
                          props.item.collection?.reciver_post}
                      </div>
                    </div>
                    <div className="print-invoice-txt2">
                      {props.item.collection &&
                        props.item.collection.reciver_distrcit}
                      ,{""}
                      {props.item.collection &&
                        props.item.collection.reciver_state}
                      ,
                      {props.item.collection &&
                        props.item.collection.recivercountries &&
                        props.item.collection.recivercountries.name}
                    </div>
                  </td>
                  <td colSpan={2}>
                    <div className="print-invoice-txt3">
                      INVOICE NO:<strong>INV{moment().unix()}</strong>
                    </div>

                    <div className="print-invoice-txt3">
                      INVOICE DATE:
                      <strong>
                        {props.item?.createdAt &&
                          moment(props.item?.createdAt).format("DD-MM-YYYY")}
                      </strong>
                    </div>

                    <div className="print-invoice-txt3">
                      BOOKED STAFF:{" "}
                      <strong>
                        {props.item.created_user &&
                          props.item.created_user.name}
                      </strong>
                    </div>
                    <div className="print-invoice-txt3">
                      BOOKED BRANCH:{" "}
                      <strong>
                        {props.item.created_user &&
                          props.item.created_user.branch &&
                          props.item.created_user.branch.name}
                      </strong>
                    </div>

                    <div className="print-invoice-txt3">
                      BOOKED DATE:
                      <strong>
                        {props.item?.createdAt &&
                          moment(props.item?.createdAt).format("DD-MM-YYYY")}
                      </strong>
                    </div>

                    <div
                      className="print-invoice-txt3"
                      style={{ background: "#f2f2f2" }}
                    >
                      REFRENCE:{" "}
                      <strong>
                        {props.item.box_ref_no
                          ? props.item.box_ref_no
                          : props.item.referance_details
                          ? props.item.referance_details
                          : ""}
                      </strong>
                    </div>
                    <div className="print-invoice-txt3">
                      BOX SIZE:{" "}
                      <strong>
                        {props.item.boxType && props.item.boxType.name}{" "}
                        {props.item &&
                          `${props.item.length} x ${props.item.breadth} x ${props.item.height}`}
                      </strong>
                    </div>

                    <div className="print-invoice-txt3">
                      CHARGABLE WEIGHT:{" "}
                      <strong>
                        {props.item && props.item.chargable_weight}
                      </strong>
                    </div>

                    <div className="print-invoice-txt3">
                      RATE PER KILO:{" "}
                      <strong>{props.item && props.item.rate}</strong>
                    </div>

                    <div className="print-invoice-txt3">
                      PACKING CHARGE:{" "}
                      <strong>{props.item && props.item.packing_charge}</strong>
                    </div>

                    <div className="print-invoice-txt3">
                      DUTY: <strong>{props.item && props.item.duty}</strong>
                    </div>

                    <div className="print-invoice-txt3">
                      OTHER:{" "}
                      <strong>{props.item && props.item.other_charges}</strong>
                    </div>

                    <div className="print-invoice-txt3">
                      AMOUNT:{" "}
                      <strong>{props.item && props.item.total_charge}</strong>
                    </div>
                    <div className="print-invoice-txt3">
                      VAT: <strong>0</strong>
                    </div>
                    <div className="print-invoice-txt3">
                      TOTAL:{" "}
                      <strong>{props.item && props.item.total_charge}</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <hr />
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <Barcode
                      value={props.item && props.item.box_number}
                      height="60"
                      fontSize="12px"
                      font="Montserrat-Regular"
                    />
                  </td>

                  <td colSpan={1}>
                    <div
                      className="service"
                      style={{
                        fontSize: "28px",
                        fontStyle: "bold",
                        fontWeight: "800",
                        fontFamily: "Poppins-Bold",
                      }}
                    >
                      {props.item.service &&
                        props.item.service.name &&
                        props.item.service.name}
                    </div>
                  </td>

                  <td colSpan={1}>
                    <div
                      className="bordered"
                      style={{
                        textAlign: "flex-end",
                        border: "none",
                      }}
                    >
                      <GenQrCode item={props.item} />
                    </div>
                  </td>

                  <td colSpan={1}>
                    <div
                      className="bordered"
                      style={{
                        textAlign: "flex-end",
                        border: "none",
                      }}
                    >
                      <div className="text2">
                        Booking No:{" "}
                        <b>{props.item && props?.item?.collection_id}</b>
                      </div>
                      <div className="text2">
                        Total Pcs:{" "}
                        <b>
                          {/* {props?.data && props?.data?.collection?.sender_name} */}
                          {props.item && props?.item?.collection?.boxes?.length}
                        </b>
                      </div>

                      {props.item && props?.item?.box_ref_no !== "" ? (
                        <div className="text2">
                          Ref No: <b>{props.item && props?.item?.box_ref_no}</b>
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div className="print-invoice-txt4">PACKING LIST</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div className="print-invoice-table-row">
                      <div className="print-invoice-table-column">
                        <table className="print-invoice-table">
                          <tr>
                            <th className="print-invoice-table-td" colSpan={1}>
                              <div className="print-customsInvoice-txt5">
                                S No
                              </div>
                            </th>
                            <th className="print-invoice-table-td" colSpan={2}>
                              <div className="print-customsInvoice-txt5">
                                Items Name
                              </div>
                            </th>
                            <th className="print-invoice-table-td" colSpan={1}>
                              <div className="print-customsInvoice-txt5">
                                Qty
                              </div>
                            </th>
                          </tr>
                          {boxItems &&
                            boxItems.slice(0, 10).map((items, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={1}
                                  >
                                    <div className="print-customsInvoice-txt6">
                                      {index + 1}
                                    </div>
                                  </td>
                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={2}
                                  >
                                    <div className="print-customsInvoice-txt6">
                                      {items.item}
                                    </div>
                                  </td>
                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={1}
                                  >
                                    <div className="print-customsInvoice-txt6">
                                      {items.quantity}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </table>
                      </div>
                      <div className="print-invoice-table-column">
                        <table className="print-invoice-table">
                          <tr>
                            <th className="print-invoice-table-td" colSpan={1}>
                              <div className="print-customsInvoice-txt5">
                                S No
                              </div>
                            </th>
                            <th className="print-invoice-table-td" colSpan={2}>
                              <div className="print-customsInvoice-txt5">
                                Items Name
                              </div>
                            </th>
                            <th className="print-invoice-table-td" colSpan={1}>
                              <div className="print-customsInvoice-txt5">
                                Qty
                              </div>
                            </th>
                          </tr>
                          {boxItems &&
                            boxItems.slice(10, 20).map((items, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={1}
                                  >
                                    <div className="print-customsInvoice-txt6">
                                      {index + 11}
                                    </div>
                                  </td>
                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={2}
                                  >
                                    <div className="print-customsInvoice-txt6">
                                      {items.item}
                                    </div>
                                  </td>
                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={1}
                                  >
                                    <div className="print-customsInvoice-txt6">
                                      {items.quantity}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div className="print-invoice-txt5">
                      1. I.Mr/Mrs{" "}
                      <strong className="print-customsInvoice-txt7">
                        {props.item.collection &&
                          props.item.collection.sender_name}
                      </strong>{" "}
                      for my family/friends, personal use as gift.not for sale.
                      Shipper also declare that he is liable of any customs
                      duty/fine / penalites against this shipment at origin and
                      destination
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div className="print-invoice-txt5">
                      2. Shipper certifies that the particulars on the face here
                      of are correct and that insofar as any part of the
                      consignemt contains Dangerous Goods if any surch part is
                      property by name and is in proper condition for carriage
                      by air according to the Internation Transport
                      Association's applicable dangerous goods regulations and
                      civil Aviation Regulation of the United Arab Emirates.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div className="print-invoice-txt5">
                      3. I/We agree that standard terms and conditions apply for
                      this shipment and liability to terms and conditions.
                      Warsaw Convention amy apply. I/We Service Liability shall
                      not exceed US$100 for any shipment.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div className="print-invoice-txt5">
                      4. /The Shipper also here by authorize forward the
                      shipment in full or part their convenience
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <div className="print-invoice-txt5">
                      5. The Shipper also here by authorize and their
                      Counterpart India to clear the parcel on behalf of me and
                      same should be delivered to the Consignee address.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="print-invoice-txt1">
                    Sender Signature{" "}
                  </td>
                  <td colSpan={1}></td>
                  <td colSpan={2} className="print-invoice-txt1">
                    For Sahari International courier
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {props.item.signature ? (
                      <div>
                        <img
                          src={props.item.signature}
                          className="print-invoice-signature"
                        />
                      </div>
                    ) : null}
                  </td>
                  <td colSpan={1}></td>
                  <td colSpan={2}>
                    <div
                      className="print-customs-seal"
                      style={{
                        display: "flex",
                      }}
                    >
                      <img src={Companyseal} height="100%" width="100%" />
                      {props.item.collection &&
                      props.item.collection.driver_signature ? (
                        <img
                          src={
                            props.item.collection &&
                            props.item.collection.driver_signature
                          }
                          className="print-invoice-signature"
                        />
                      ) : null}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            {medianBoxNum >= 11 ? (
              <div style={{ padding: 10 }}>
                <br />
                <div className="print-invoice-table-row">
                  <div className="print-invoice-table-column">
                    <table className="print-invoice-table">
                      <tr>
                        <th className="print-invoice-table-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">S No</div>
                        </th>
                        <th className="print-invoice-table-td" colSpan={2}>
                          <div className="print-customsInvoice-txt5">
                            Items Name
                          </div>
                        </th>
                        <th className="print-invoice-table-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">Qty</div>
                        </th>
                      </tr>
                      {boxItems &&
                        boxItems.slice(20).map((items, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {index + 21}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={2}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {items.item}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {items.quantity}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  <div className="print-invoice-table-column">
                    <table className="print-invoice-table">
                      <tr>
                        <th className="print-invoice-table-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">S No</div>
                        </th>
                        <th className="print-invoice-table-td" colSpan={2}>
                          <div className="print-customsInvoice-txt5">
                            Items Name
                          </div>
                        </th>
                        <th className="print-invoice-table-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">Qty</div>
                        </th>
                      </tr>
                      {boxItems &&
                        boxItems.slice(20).map((items, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {index + 36}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={2}
                              >
                                <div className="print-customsInvoice-txt6"></div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6"></div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </div>
                <br />
              </div>
            ) : null}
          </div>

          <Row>
            <Col sm="6"></Col>
            <Col sm="3">
              <Button onClick={() => props.onClose()} block size="large">
                Cancel
              </Button>
            </Col>
            <Col sm="3">
              <ReactToPrint
                trigger={() => (
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClickCapture={() => {
                      isPrinted();
                    }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
}
export default PrintCustomerInvoiceModal2;
