import { Button, Form, Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import API from "../../Config/API";
import { login } from "../../Redux/Slices/AuthSlice";
import RegisterModal from "./RegisterModal";
import { taxGoDatas } from "../../Redux/Slices/TaxgoSlice";
import style from "./style";
import "./styles.css";
function LoginScreen(props) {
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [register, setregister] = useState(false);

  const tokenTaxgo = useSelector((state) => state.TaxgoSlice);

  console.log("tokenTaxgo===========>123");
  console.log(tokenTaxgo);
  console.log("tokenTaxgo===========>123");

  const onFinish = async (value) => {
    setLoading(true);
    let obj = {
      email: value.username,
      password: value.password,
    };
    axios
      .post(API.BASR_URL + API.LOGIN, obj, {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        setLoading(false);
        if (response.status === 200) {
          dispatch(login(response.data));
          dispatch(taxGoDatas(response?.data?.authTaxgoID));
          if (response.data.active) {
            message.success("loggined successfully.");
            props.history.push("/auth/home");
          } else {
            message.error("User not verified");
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        setError(error?.response?.data?.message);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <div>
      {/* <Header /> */}
      <Container fluid>
        <Row>
          <Col sm={6} xs={12} className="bg-Col">
            <Container>
              <div className="Register-box">
                <div className="Register-box2">
                  <Row>
                    <Col sm={4} xs={12}></Col>
                    <Col sm={8} xs={12}>
                      <div className="sahari-Login-Screen-Logo"></div>
                      <br />
                      <div className="Register-box3">
                        <div className="Register-box-txt1">
                          DISCOVER NEW WAYS TO TAKE YOURBUSINESS FURTHER
                        </div>
                      </div>
                      <br />
                      <div className="Register-box-txt2">
                        eaque ipsa quae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta sunt explicabo.
                      </div>
                      <br />
                      <Button
                        type="primary"
                        onClick={() => setregister(true)}
                        style={{ boxShadow: "0px 7px 30px rgba(0,0,0,0.3)" }}
                      >
                        Register Now
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </Col>
          <Col sm={6} xs={12}>
            {/* <Row> */}
            {/* <Col sm={4} xs={12}></Col> */}
            {/* <Col sm={7} xs={12}> */}
            <div className="Login-box">
              <div className="Login-box2">
                <br />
                <br />
                <Form name="basic" onFinish={onFinish}>
                  <Form.Item
                    style={style.loginForm}
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input placeholder="username / Email" />
                  </Form.Item>

                  <Form.Item
                    style={style.loginForm}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                  {error ? (
                    <div className="error"> &#33; &nbsp; {error}</div>
                  ) : null}
                  {/* <br /> */}
                  <Form.Item style={style.loginForm}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={loading}
                      size="large"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
                {/* <br /> */}

                <div className="Login-txt">
                  If you don't have a Reply account yet, here's how to create
                  one in under a minute of your time.
                </div>
              </div>
            </div>
            {/* </Col> */}
            {/* </Row> */}
            <div style={{ textAlign: "center" }} className="Login-txt-2">
              info@sahariics.com
            </div>
            <div className="Login-txt-3" style={{ textAlign: "center" }}>
              Privacy Policy
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
      {register ? (
        <RegisterModal visible={register} onClose={() => setregister(false)} />
      ) : null}
    </div>
  );
}
export default LoginScreen;
