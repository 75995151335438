import React, { useState } from "react";
import { Table, Tooltip, Pagination } from "antd";
function DataTable(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-contactfinder-table-column"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-contactfinder-table-column"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: " Other details",
      dataIndex: "address",
      key: "address",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-contactfinder-table-column"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="">{text}</div>
          </div>
        </Tooltip>
      ),
    },
  ];
  return (
    <div>
      <Table
        rowKey="id"
        size="small"
        rowSelection={{ type: "radio", ...rowSelection }}
        loading={props.loading}
        columns={columns}
        dataSource={props.data}
        pagination={false}
      />
      <div align="right">
        <Pagination size="small" onChange={props.PageOnchange} />
      </div>
    </div>
  );
}

export default DataTable;
