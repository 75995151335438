// import { Transfer } from "antd";
// import { useEffect, useState } from "react";
// const FormModel = (props) => {
import React, { useState, useEffect, useRef } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  message,
  Select,
  Transfer,
  InputNumber,
  Space,
  DatePicker,
} from "antd";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import moment from "moment";
import axios from "axios";
var width = window.innerWidth;
const { Option } = Select;
function FormModal(props) {
  console.log("props.item.box_number", props.item.box_numbers);
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredMockData, setFilteredMockData] = useState([]);

  useEffect(() => {
    if (props.item && props.item.box_numbers) {
      setTargetKeys([...props.item.box_numbers]);
      console.log("props.item.targetKeys", targetKeys);
    }
  }, [props.item]);

  const handleChange = (newTargetKeys) => {
    console.log("TotalPieces", newTargetKeys);
    setTargetKeys([...newTargetKeys]);
    CalcTotalPieces(newTargetKeys);
  };
  const CalcTotalPieces = (newTargetKeys) => {
    let weight = 0;
    let TotalPieces = 0;
    // TotalPieces = targetKeys.length;
    console.log("TotalPieces", targetKeys);

    newTargetKeys.forEach((e) => {
      props.unAssigned.forEach((ee) => {
        if (Number(e) === Number(ee.key)) {
          weight += Number(ee.weight);
          TotalPieces += 1;
        }
      });
    });

    formRef.current?.setFieldsValue({
      total_pcs: TotalPieces,
      total_weight: weight,
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredData = mockData.filter((item) =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredMockData(filteredData);
  };
  const onFinish = (values) => {
    setLoading(true);
    let requestObj = {
      third_party_id: Number(values.third_party_id),
      total_pcs: Number(values.total_pcs),
      total_weight: Number(values.total_weight),
      date: values.date,
      total_amount: values.total_amount ?? 0,
      box_numbers: targetKeys,
      login_id: parseInt(Auth.id),
      is_amt_update: values.total_amount >= 0 ? true : false,
    };

    let URL =
      props.mode === "post"
        ? API.THIRD_PARTY_TRANS_ACTIONS
        : API.THIRD_PARTY_TRANS_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: requestObj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <div>
      <Drawer
        placement={props.mode === "post" ? "right" : "left"}
        title={
          <span className="txt4">
            {props.mode === "post" ? "New" : "Update"} Transaction
          </span>
        }
        width={width / 2.5}
        onClose={() => props.onClose()}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          layout="horizontal"
          hideRequiredMark
          onFinish={onFinish}
          ref={formRef}
          // onValuesChange={handleChange}
          initialValues={
            props.mode == "post"
              ? {
                  total_amount: 0,
                }
              : {
                  third_party_id: props.item && props.item.third_party_id,
                  date: props.item && moment(props.item.date),
                  total_pcs: props.item && props.item.total_pcs,
                  total_weight: props.item && props.item.total_weight,
                  total_amount: props.item && props.item.total_amount,
                }
          }
        >
          <Container>
            <Row>
              <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true, message: "required" }]}
              >
                <DatePicker
                  placeholder="Pick a date"
                  style={{ width: "70%" }}
                />
              </Form.Item>
              <Form.Item
                name="third_party_id"
                label="Third Party"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  placeholder="Select Country"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLocaleLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                  {props.party.map((option) => (
                    <Option key={option.id}>{option.name}</Option>
                  ))}
                </Select>
              </Form.Item>

              {/* <Transfer
                dataSource={props.unAssigned}
                showSearch
                // filterOption={filterOption}
                targetKeys={targetKeys}
                onChange={handleChange}
                onSearch={handleSearch}
                render={(item) => item.title}
                listStyle={{ height: 300 }}
              /> */}
              {/* <Transfer
                dataSource={props.unAssigned}
                showSearch
                // filterOption={filterOption}
                targetKeys={targetKeys}
                onChange={handleChange}
                onSearch={handleSearch}
                render={(item) => item.title}
                listStyle={{ height: 300 }}
              /> */}
              <Select
                mode="multiple"
                showSearch
                value={targetKeys}
                onChange={handleChange}
                onSearch={handleSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: "100%" }}
              >
                {props.unAssigned.map((item) => (
                  <Option key={item.key} value={item.key}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </Row>
            <Row style={{ paddingTop: "15px" }}>
              <Form.Item
                name="total_pcs"
                label="PCS"
                rules={[{ required: true, message: "Required" }]}
                labelCol={{ span: 8 }} // Adjust the span value as per your requirement
                wrapperCol={{ span: 16 }} // Adjust the span value as per your requirement
                style={{ width: "100%", padding: "0px" }}
              >
                <InputNumber
                  style={{ width: "100%", padding: "0px" }}
                  placeholder=""
                />
              </Form.Item>
              <Form.Item
                name="total_weight"
                label="Weight"
                rules={[{ required: true, message: "Required" }]}
                labelCol={{ span: 8 }} // Adjust the span value as per your requirement
                wrapperCol={{ span: 16 }} // Adjust the span value as per your requirement
                style={{ width: "100%", padding: "0px" }}
              >
                <InputNumber
                  style={{ width: "100%", padding: "0px" }}
                  placeholder=""
                />
              </Form.Item>
              <Form.Item
                name="total_amount"
                label="Amount"
                rules={[{ required: false, message: "Required" }]}
                labelCol={{ span: 8 }} // Adjust the span value as per your requirement
                wrapperCol={{ span: 16 }} // Adjust the span value as per your requirement
                style={{ width: "100%", padding: "0px" }}
              >
                <InputNumber
                  style={{ width: "100%", padding: "0px" }}
                  placeholder=""
                  defaultValue={0} // Set the default value to 0
                />
              </Form.Item>
            </Row>
          </Container>
          <Row>
            <Col sm="6" xs="12"></Col>
            <Col sm="3" xs="12">
              <Button block onClick={() => props.onClose()}>
                Cancel
              </Button>
            </Col>
            <Col sm="3" xs="12">
              <Button block type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
export default FormModal;
