import React, { useState } from "react";
import { Modal, Form, Input, DatePicker, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import ExcelTaxInvoice from "../ExportExcel/ExcelTaxInvoice";
import PdfTaxInvoice from "../PrintDesign/PdfTaxInvoice";

function PrintTaxInvoiceScreenModal(props) {
  const [formdata, setFormdata] = useState([]);
  const [excel, setExcel] = useState(false);
  const [pdf, setPdf] = useState(false);
  const onFinish = (val) => {
    setFormdata(val);
  };

  return (
    <div>
      <Modal visible={props.visible} onCancel={props.onCancel} footer={false}>
        <br />
        <div>
          <h6>Tax Invoice</h6>
        </div>
        <br />
        <Form onFinish={onFinish}>
          <Form.Item name="date" label="Date">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="rate" label="Rate">
            <Input />
          </Form.Item>
          <Row>
            <Col xs="6" sm="6"></Col>
            <Col xs="3" sm="3">
              <Button
                type="primary"
                block
                htmlType="submit"
                onClick={() => setExcel(true)}
              >
                {" "}
                Excel
              </Button>
            </Col>
            <Col xs="3" sm="3">
              <Button
                type="primary"
                block
                htmlType="submit"
                onClick={() => setPdf(true)}
              >
                {" "}
                Pdf
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      {excel && formdata.rate ? (
        <ExcelTaxInvoice
          visible={excel}
          onCancel={() => setExcel(false)}
          selected={props.selected}
          formdata={formdata}
        />
      ) : null}
      {pdf ? (
        <PdfTaxInvoice
          visible={pdf}
          onCancel={() => setPdf(false)}
          selected={props.selected}
          formdata={formdata}
        />
      ) : null}
    </div>
  );
}

export default PrintTaxInvoiceScreenModal;
