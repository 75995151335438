import React, { useRef } from "react";
import { Button, DatePicker, Form, Select } from "antd";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

function MenuBarSales(props) {
  const ref = useRef();
  const onFinish = (val) => {
    const date = val.date ? moment(val.date).toISOString() : null;

    let requestObj = {
      date,
      user_id: Number(val?.user),
      agent_id: Number(val?.agent),
      port: Number(val?.service_type),
      search: val?.search,
    };
    props.onFinish(requestObj);
  };

  return (
    <Container fluid>
      <Form ref={ref} onFinish={onFinish}>
        <Row>
          <Col md={3}>
            <Form.Item name="date">
              <DatePicker className="w-100" />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item name="search">
              <Select
                className="borderedSelect w-100"
                bordered={false}
                allowClear
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={props?.allUsers?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
                showSearch
                placeholder="Search by name..."
              />
            </Form.Item>
          </Col>
          <Col />

          <Col className="d-flex justify-content-end">
            <Button type="primary" className="w-50" htmlType="submit">
              <div className="Reports-MenuBar-btn">
                Filter <BsSearch />
              </div>
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default MenuBarSales;
