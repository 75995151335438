import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { Button, Popover, Empty } from "antd";
import ReactToPrint from "react-to-print";
import { GrDocumentExcel } from "react-icons/gr";
import moment from "moment";
import "./styles.css";
import ExportReport from "./ExportReport";

function Datatable(props) {
  console.log(props);
  const ref = useRef();
  return (
    <div>
      <div ref={ref} className="p-2">
        <Table className="formTable" bordered>
          <thead style={{ backgroundColor: "#e2e2e2" }}>
            <tr>
              <td colSpan={11}>
                <div className="Report-table-txt1">
                  Report{" "}
                  {` from ${moment(props.obj.date_from).format(
                    "DD/MM/YYYY"
                  )} to ${moment(props.obj.date_to).format("DD/MM/YYYY")}`}
                </div>
              </td>
              <td colSpan={2}>
                <div className="Reports-Datatable-box1">
                  <ReactToPrint
                    trigger={() => <Button type="primary">Print</Button>}
                    content={() => ref.current}
                  />
                  <Popover
                    trigger="click"
                    placement="bottom"
                    content={
                      <div>
                        <ExportReport data={props.data} />
                      </div>
                    }
                  >
                    <Button type="link">
                      <GrDocumentExcel size={20} />
                    </Button>
                  </Popover>
                </div>
              </td>
            </tr>
          </thead>
          <tbody className="Reports-Datatable-items">
            <tr>
              <th>DATE</th>
              <th colSpan={2}>BOX NUMBER</th>
              <th>SERVICE</th>
              <th>BRANCH</th>
              <th>USER</th>
              <th>AGENT</th>
              <th>KILO</th>
              <th>AMOUNT</th>
              <th>PAID/UNPAID</th>
              <th>SEND ADRESS</th>
              <th>CNEE ADRESS</th>
              <th>BOX SIZE</th>
            </tr>
            {props.data && props.data.length ? (
              props.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{moment(item.createdat).format("l")}</td>
                    <td colSpan={2}>{item.box_number}</td>
                    <td>{item.service && item.service.name}</td>
                    <td>
                      {item.created_user &&
                      item.created_user.branch &&
                      item.created_user.branch.name
                        ? item.created_user.branch.name
                        : "N/A"}
                    </td>
                    <td>
                      <div className="Reports-Datatable-col">
                        <div className="Reports-Datatable-txt1">
                          {item.created_user && item.created_user.name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="Reports-Datatable-col">
                        <div className="Reports-Datatable-txt1">
                          {item.collection.agent.user.name &&
                            item.collection.agent.user.name}
                        </div>
                      </div>
                    </td>
                    <td>{item.chargable_weight && item.chargable_weight}</td>
                    <td>{item.total_charge && item.total_charge}</td>
                    <td>PAID</td>
                    <td>
                      <div className="Reports-Datatable-col">
                        <div className="Reports-Datatable-txt1">
                          {`${
                            item.collection.sender_name &&
                            item.collection.sender_name
                          }, ${
                            item.collection.sender_address &&
                            item.collection.sender_address
                          }`}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="Reports-Datatable-col">
                        <div className="Reports-Datatable-txt1">
                          {`${
                            item.collection.reciver_name &&
                            item.collection.reciver_name
                          }, ${
                            item.collection.reciver_address &&
                            item.collection.reciver_address
                          }`}
                        </div>
                      </div>
                    </td>
                    <td>
                      {item.boxType && item.boxType.name
                        ? item.boxType.name
                        : "N/A"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={13}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default Datatable;
