import React, { useEffect, useState } from "react";
import { Modal, Table, Input, message, Button, Tooltip } from "antd";
import moment from "moment";

function SearchModal(props) {
  const columns = [
    {
      title: "Date",
      dataIndex: "collected_datetime",
      key: "collected_datetime",
      render: (text) => (
        <div className="collection-table-item">{moment(text).format("l")}</div>
      ),
    },
    {
      title: "Sender Name",
      dataIndex: "sender_name",
      key: "sender_name",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      title: "Sender Phone",
      dataIndex: "sender_phone",
      key: "sender_phone",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      title: "Reciver Name",
      dataIndex: "reciver_name",
      key: "reciver_name",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      title: "Reciver Phone",
      dataIndex: "reciver_phone",
      key: "reciver_phone",
      render: (text) => <div className="collection-table-item">{text}</div>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (item) => (
        <div>
          <Button size="small" onClick={() => props.select(item)}>
            Fetch
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={false}
      width={900}
    >
      <br />
      <div>
        <Table columns={columns} dataSource={props.data} size="small" />
      </div>
    </Modal>
  );
}

export default SearchModal;
