import React, { useEffect, useState } from "react";
import PageHeader from "../../Routes/PageHeader";

import { Tabs } from "antd";
import Excel1 from "./excel1";
import Excel2 from "./excel2";

function Delivery() {
  const [fileData, setFileData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [meta, setMeta] = useState({ page: 1, take: 10, itemCount: 0 });

  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    const { page, take } = meta;
    const start = (page - 1) * take;
    const end = start + take;
    setPaginatedData(fileData.slice(start, end));
  }, [fileData, meta]);

  const items = [
    {
      key: "1",
      label: "Received Box Bulk Update",
      children: <Excel1 />,
    },
    {
      key: "2",
      label: "Bulk Update Box's Forward",
      children: <Excel2 />,
    },
  ];

  return (
    <div>
      <PageHeader PageName={"Delivery"} breadScrum={"Admin / Delivery"} />
      <br />
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
}

export default Delivery;
