import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Input,
  Select,
  message,
  Modal,
  InputNumber,
  Switch,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, CurrancyRate, Headers } from "../../Config";
import axios from "axios";
import { useSelector } from "react-redux";
const { Option } = Select;
function FormModal(props) {
  let account_level = 5;
  let auto_account = "Auto";

  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const [currentlevel, setCurrentlevel] = useState(0);
  const [parentAcc, setparentAcc] = useState([]);

  const formRef = useRef();
  useEffect(() => {
    if (props.mode === "put") {
      setparentAcc(props.accounts);
    }
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      account_no: values.account_no,
      account_name: values.account_name,
      category_id: Number(values.category_id),
      parent_account_id: values.parent_account_id,
      level_id: Number(values.level_id),
      opening_balance: Number(values.opening_balance),
      created_by: Number(Auth.id),
      enabled: values.enabled,
      acc_type: values.acc_type,
    };

    let URL =
      props.mode === "post"
        ? API.CHART_OF_ACCOUNTS_ACTIONS
        : API.CHART_OF_ACCOUNTS_ACTIONS + props.item.account_no;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        // message.error("Oops.Something went wrong");
        console.log(error);
        message.error(error.response.data.message);
      });
  };

  const onCalculation = (_, values) => {
    setCurrentlevel(values.level_id);
    let value = values.level_id - 1;
    let value2 = values.category_id;

    const filteredArr = props.accounts.filter((item) => {
      return item.category_id == value2 && item.level_id == value;
    });

    setparentAcc(filteredArr);
  };

  // const enableChange = (e) => {
  //   e.preventDefult();
  //   console.log("enableChange", e);
  // };
  const onValueLevel = (e) => {
    e.preventDefult();
    setCurrentlevel(e);
  };

  return (
    <Modal
      open={props.visible}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Account
        </span>
      }
      visible={props.visible}
      onCancel={props.onClose}
      width={600}
      footer={false}
    >
      <Form
        // layout="vertical"
        name="basic"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        ref={formRef}
        hideRequiredMark
        onFinish={onFinish}
        onValuesChange={onCalculation}
        initialValues={
          props.mode === "post"
            ? {
                enabled: props.item.enabled === true,
                opening_balance: 0,
              }
            : {
                category_id: props.item && props.item.category_id.toString(),
                // account_no: props.item && props.item.account_no,
                account_name: props.item && props.item.account_name,
                parent_account_id: props.item && props.item.parent_account_id,
                level_id: props.item && props.item.level_id,
                opening_balance: props.item && props.item.opening_balance,
                created_by: props.item && props.item.created_by,
                enabled: props.item && props.item.enabled,
                acc_type: props.item && props.item.acc_type,
              }
        }
      >
        <Container style={{ alignContent: "center" }}>
          <Row>
            {/* <Col sm="6" xs="12"> */}
            <Form.Item name="category_id" label="Category">
              <Select
                // showSearch
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
                style={{ width: "100%" }}
                placeholder="Select Account"
              >
                {props.category.map((item, index) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.category}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
              name="category_id"
              label="Category"
              rules={[{ required: false, message: "required" }]}
            >
              <Select
                // bordered={false}
                // className="CustomerForm-Select"
                placeholder="Select Category"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }

                // style={{ margin: 4, textTransform: "uppercase" }}
              >
                {props.category.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.category}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              name="category_id"
              label="Category"
              rules={[{ required: true, message: "required" }]}
            >
              <Select
                placeholder="Select Country"
                showSearch
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLocaleLowerCase()) >= 0
                }
                style={{ width: "100%" }}
              >
                {props.category.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.category}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item
              name="level_id"
              label="Level"
              rules={[{ required: false, message: "required" }]}
            >
              <Select
                // bordered={false}
                // className="CustomerForm-Select"
                placeholder="Select Level"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onValuesChange={onValueLevel}
                // style={{ margin: 4, textTransform: "uppercase" }}
              >
                {props.level.slice(0, account_level).map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.level}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {currentlevel == 1 || props.item.level_id == 1 ? null : (
              <Form.Item
                name="parent_account_id"
                label="Parent Account"
                rules={[{ required: false, message: "required" }]}
              >
                <Select
                  // bordered={false}
                  // className="CustomerForm-Select"
                  placeholder="Select parent account"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }

                  // style={{ margin: 4, textTransform: "uppercase" }}
                >
                  {parentAcc.map((option) => (
                    <Select.Option
                      key={option.account_no}
                      value={option.account_no}
                    >
                      {option.account_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {/* <Select
              labelInValue
              defaultValue={{
                value: "P",
                label: "Parant",
              }}
              style={{
                width: 120,
              }}
              // onChange={handleChange}
              options={[
                {
                  value: "P",
                  label: "Parant",
                },
                {
                  value: "C",
                  label: "Chiled",
                },
              ]}
            /> */}
            <Form.Item
              rules={[{ required: true, message: "required" }]}
              key="acc_type"
              name="acc_type"
              label="Type"
            >
              <Select
                showSearch
                // style={{
                //   width: 200,
                // }}
                placeholder="Search  acc type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "P",
                    label: "Parant",
                  },
                  {
                    value: "C",
                    label: "Child",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="account_name"
              label="Account Name"
              rules={[{ required: true, message: "required" }]}
            >
              <Input placeholder="Enter account name" />
            </Form.Item>
            {auto_account === "Auto" ? null : (
              <Form.Item
                name="account_no"
                label="Account No"
                rules={[{ required: false, message: "required" }]}
              >
                <Input placeholder="Enter account no" />
              </Form.Item>
            )}
            <Form.Item
              name="opening_balance"
              label="Opening Balance"
              rules={[{ required: true, message: "required" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Enter opening_balance"
              />
            </Form.Item>
            <Form.Item
              label="Enabled"
              name="enabled"
              // rules={[{ required: true, message: "required" }]}
              valuePropName={props.item.enabled ? "checked" : "uncheked"}
            >
              <Switch />
            </Form.Item>
          </Row>
          <Row>
            <Col sm="6" xs="12"></Col>
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  );
}
export default FormModal;
