import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import styles from "../styles";
import { Container } from "react-bootstrap";
import * as XLSX from "xlsx";
import Excel from "../../../Assets/Images/excel-icons-animation.gif";
import { API, Headers, CurrancyRate } from "../../../Config";
import axios from "axios";
import { GrDocumentCsv } from "react-icons/gr";
import { AiOutlineCloseCircle } from "react-icons/ai";
import RATES from "../../../Config/exchangeRates";
import moment from "moment";
import { GET, POST } from "../../../Config/ApiRequests";
import { Space, Table, Spin, Pagination } from "antd";

function PrintManifestModalC(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [exchangeData, setexchangeData] = useState([]);
  const [USD, setUsd] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showExcel, setShowExcel] = useState(false);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [isLoading2, setIsLoading2] = useState(false);
  const [selectedRate, setSelectedRate] = useState({});
  const [exchangeRates, setexchangeRates] = useState([]);

  useEffect(() => {
    getExchangeRates(1, 10);
  }, []);

  const getExchangeRates = async (_page, _take) => {
    try {
      setIsLoading2(true);
      let api = API.EXCHANGE_RATE_LIST;
      let obj = {
        page: _page,
        take: _take,
      };
      const excData = await POST(api, obj);
      if (excData?.status) {
        setexchangeRates(excData?.data);
        setMeta(excData?.meta);
        setIsLoading2(false);
      } else {
        message.error("No data found");
        setIsLoading2(false);
      }
    } catch (err) {
      message.error("Oops.Something went");
      setIsLoading2(false);
    }
  };

  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    console.log(newSelectedRowKeys);
    console.log(rowDetails[0]);
    setSelectedRate(rowDetails[0]);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
      render: (text) => <a>{moment(text).format("ll")}</a>,
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      key: "to_date",
      render: (text) => <a>{moment(text).format("ll")}</a>,
    },
    {
      title: "SAR - USD",
      dataIndex: "sar_usd",
      key: "sar_usd",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "SAR - INR",
      dataIndex: "sar_inr",
      key: "sar_inr",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "INR - SAR",
      dataIndex: "inr_sar",
      key: "inr_sar",
      render: (text) => <a>{text}</a>,
    },
  ];

  const fetchData = async () => {
    try {
      const USD = await GetCurrancy();
      const boxes = await loadBoxesViaAwb(USD?.result);
      setShowExcel(true);
    } catch (error) {
      console.error(error);
      message.error("Select a rate");
    }
  };

  const GetCurrancy = async () => {
    if (selectedRate?.id) {
      let val = selectedRate["sar_usd"];
      console.log(val);
      return val;
    } else {
      message.error("Select a rate");
    }
  };

  const loadBoxesViaAwb = async (USD) => {
    const AWBID = props.selected?.[0]?.id;
    const URL = API.BASR_URL + API.BOXES_VIA_AWB + AWBID;
    try {
      const response = await axios.get(URL, {
        headers: Headers(),
      });
      if (response.status === 200) {
        const data = response.data;
        Generatedata(data, USD);
        // use USD here - it will now be available to this function
        return data;
      } else {
        setIsLoading(false);
        message.error("Oops.Something went");
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const Generatedata = async (data, USD) => {
    try {
      const newData = await Promise.all(
        data
          .sort((a, b) => a.box_number - b.box_number)
          .map(async (item) => {
            let desc = await CreateDescription(item.boxe_item, USD);
            return {
              "HAWB NO": item?.box_number && item?.box_number,
              "NO OF PCs": "1",
              WEIGHT: item.chargable_weight && item?.chargable_weight,
              "SHIPPER ADDRESS": `${item.iqama_name?.toUpperCase()}  ${
                item.sender_mobile
              } ID : ${item?.iqama_number}`,
              "CONSIGNEE ADDRESS": `${item.adhar_name?.toUpperCase()}  ${item.adhar_address?.toUpperCase()}  ${item.district?.toUpperCase()}  ${item.state?.toUpperCase()} ${
                item.reciver_mobile1
              }`,
              "CONSIGNEE PINCODE": item?.pin && item.pin,
              "DESCRIPTION OF GOODS": desc,
              "INVOICE VALUE": item?.total_value_usd && item.total_value_usd,
              "Aadhaar Number": "Aadhaar Number",
              "GSTIN NO": item.adhar_no && item.adhar_no?.toString(),
              AD_CODE: "0240829",
              "GOV, NOC GOV TYPE": "P",
            };
          })
      );
      setData(newData);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const CreateDescription = async (item, USD) => {
    try {
      let desc = " ";
      for (let ite of item) {
        if (ite.item != null) {
          desc +=
            ite.item?.toUpperCase() +
            "-" +
            ite.quantity +
            "-" +
            (Number(ite.value) * USD).toFixed(2) +
            ",";
        }
      }
      return desc;
    } catch (err) {
      console.log(err);
    }
  };

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `${props.selected[0].airline}-${props.selected[0].awb_no}manifest-x.xls`
    );
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      width={800}
      footer={false}
    >
      <div>
        <Container>
          <br />
          {!showExcel ? (
            <>
              <div className="modal-table-header">Select Rate</div>
              <div
                style={{
                  height: "300px",
                  width: "100%",
                  overflow: "scroll",
                }}
              >
                <>
                  <Table
                    rowKey="id"
                    size="small"
                    pagination={false}
                    columns={columns}
                    rowSelection={{ type: "radio", ...rowSelection }}
                    dataSource={exchangeRates}
                  />
                  <br />
                  <Pagination
                    total={meta?.total_count}
                    showSizeChanger
                    defaultCurrent={meta?.page}
                    showTotal={(total) => `Total ${meta?.total_count}`}
                    onChange={(page, pageSize) => {
                      setPage(page);
                      setTake(pageSize);
                      getExchangeRates(page, pageSize);
                    }}
                  />
                </>
              </div>
              <br />
              {selectedRate?.id ? (
                <Button
                  style={{ width: 200 }}
                  type="primary"
                  onClick={() => fetchData()}
                >
                  Submit
                </Button>
              ) : null}
            </>
          ) : null}

          {isLoading2 ? (
            <Spin />
          ) : (
            <>
              <div>
                {showExcel ? (
                  <div className="print-manifest-box1">
                    <Button onClick={() => setShowExcel(!showExcel)}>
                      Change Rate
                    </Button>
                    <br />
                    {isLoading ? (
                      <>
                        <img src={Excel} className="print-manifest-img" />
                        <div>Fetching data....</div>
                        <div>Downloading Manifest ....</div>
                      </>
                    ) : (
                      <>
                        {data && data.length ? (
                          <>
                            <img src={Excel} className="print-manifest-img" />
                            <div>Manifest is ready For download</div>
                            <br />
                            <Button
                              icon={
                                <GrDocumentCsv style={styles.buttonsIcon} />
                              }
                              onClick={() => downloadExcel(data)}
                            >
                              Download Manifest
                            </Button>
                          </>
                        ) : (
                          <>
                            <AiOutlineCloseCircle size={50} color={"red"} />
                            <div style={{ margin: 20 }} />
                            <div>No data found.</div>
                          </>
                        )}
                      </>
                    )}
                    <br /> <br />
                    <Button type="primary" onClick={props.onClose}>
                      Close
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </Container>
      </div>
    </Modal>
  );
}

export default PrintManifestModalC;
