import React, { useRef } from "react";
import { Button, Modal } from "antd";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import { BlobProvider } from "@react-pdf/renderer";
import { Col, Row } from "react-bootstrap";
import "./styles.css";
import PdfRefNumModal from "./PdfRefNumModal";
import { MdFileDownload } from "react-icons/md";
import { POST } from "../../Config/ApiRequests";
import { message } from "antd";

function PrintRefNumberModal(props) {
  const ref = useRef();
  const download = (url) => {
    window.open(url);
  };

  const markAndDownload = async (url) => {
    console.log("--- ", props?.item2);
    try {
      let api = "referance/print";
      let obj = {
        id: props?.item2?.id,
      };
      const result = await POST(api, obj);
      if (result?.status) {
        window.open(url);
        props.onCancel();
        props.reload();
      } else {
        message.error("Something went wrong. Unable to download");
        console.log("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("print ===. ", props?.item);
  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        footer={false}
        width={700}
      >
        <br />
        <br />

        <div style={{ display: "flex", gap: "10px" }}>
          {/* <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => ref.current}
          /> */}
          <BlobProvider
            filename="hello"
            document={<PdfRefNumModal data={props?.item} />}
          >
            {({ blob, url, loading, error }) => {
              return loading ? null : (
                <Button
                  type="primary"
                  onClick={() => {
                    markAndDownload(url);
                  }}
                >
                  <MdFileDownload size={20} />
                  &nbsp; Download
                </Button>
              );
            }}
          </BlobProvider>
        </div>

        <br />
        <div ref={ref}>
          {props.item &&
            props.item?.map((item, index) => {
              return (
                <div>
                  <div className="PrintRefNumberModal-container">
                    <table className="PrintRefNumberModal-table">
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            <div className="PrintRefNumberModal-box1">
                              <Barcode
                                value={item}
                                width="5"
                                displayValue={false}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <div className="PrintRefNumberModal-box1">
                              <div className="PrintRefNumberModal-txt1">
                                {item}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <div className="PrintRefNumberModal-box2"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
        </div>

        <div>
          <Row>
            <Col sm="9" xs="9"></Col>
            <Col sm="3" xs="6">
              <Button block onClick={() => props.onCancel()}>
                Cancel
              </Button>
            </Col>
            {/* <Col sm="3" xs="6">
              <ReactToPrint
                trigger={() => (
                  <Button type="primary" block>
                    Print
                  </Button>
                )}
                content={() => ref.current}
              />
            </Col> */}
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default PrintRefNumberModal;
