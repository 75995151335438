import { Table, Pagination, Button, Popconfirm, Tag } from "antd";
import moment from "moment";
import {
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
function DataTable(props) {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Button type="dashed" shape="circle" size="small">
          {id}
        </Button>
      ),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <Tag color={active ? "green" : "red"}>
          {active ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    { title: "Bill Number", dataIndex: "bill_number", key: "bill_number" },
    { title: "shipper", dataIndex: "shipper", key: "shipper" },
    {
      title: "Airline",
      dataIndex: "airline",
      key: "airline",
      render: (airline) => <div>{airline}</div>
      // render: (airline) => <div>{airline && airline.name}</div>,
    },
    {
      title: "Arival port",
      dataIndex: "destination_airport",
      key: "destination_airport",
      render: (destination_airport) => <div>{destination_airport}</div>
      // render: (arival_port) => <div>{arival_port && arival_port.name}</div>,
    },
    {
      title: "Departure port",
      dataIndex: "orijin_airport",
      key: "orijin_airport",
      render: (orijin_airport) => <div>{orijin_airport}</div>
      // render: (departure_port_id) => (
      //   <div>{departure_port_id && departure_port_id.name}</div>
      // ),
    },
    {
      title: "Place",
      dataIndex: "place_of_generate",
      key: "place_of_generate",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <div>{date && moment(date).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small" type="link">
              <MdOutlineDelete size={25} />
            </Button>
          </Popconfirm>
          <Button size="small" onClick={() => props.update(item)} type="link">
            <MdOutlineEdit size={25} color={"#ffa726"} />
          </Button>
          <Button size="small" onClick={() => props.details(item)} type="link">
            <MdOutlineRemoveRedEye size={25} color={"#78909c"} />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;
