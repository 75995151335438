import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
function AirwayBillScreen() {
	const [loading, setLoading] = useState(true);
	const [indicator, setIndicator] = useState(false);
	const [form, setform] = useState(false);
	const [mode, setMode] = useState("post");
	const [page, setPage] = useState(1);
	const [take, setTake] = useState(10);
	const [data, setData] = useState([]);
	const [meta, setMeta] = useState({});
	const [item, setItem] = useState({});

	useEffect(() => {
		loaddata(page, take);
	}, []);

	const loaddata = (p, t) => {
		axios
			.get(
				API.BASR_URL + API.AIRWAY_BILLS + `?order=DESC&page=${p}&take=${t}`,
				{
					headers: Headers(),
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					let data = response.data;
					setData(data.data);
					setMeta(data.meta);
					message.success("Success");
				} else {
					message.error("Oops.Something went wrong");
				}
				setLoading(false);
				setIndicator(false);
			})
			.catch(function (error) {
				message.error("Oops.Something went wrong");
				setIndicator(false);
				setLoading(false);
			});
	};

	const pagination = (page, take) => {
		window.scrollTo(0, 0);
		setIndicator(true);
		setPage(page);
		setTake(take);
		loaddata(page, take);
	};

	const relaod = () => {
		loaddata(page, take);
		setMode("post");
		setItem({});
	};

	const AddItem = () => {
		setMode("post");
		setItem({});
		setform(true);
	};

	const UpdateItem = (item) => {
		setMode("put");
		setItem(item);
		setform(true);
	};

	const DeleteItem = (item) => {
		setIndicator(true);
		axios
			.delete(API.BASR_URL + API.AIRWAY_BILLS_ACTION + item.id, {
				headers: Headers(),
			})
			.then(function (response) {
				setIndicator(false);
				message.success("Deleted Successfully");
				relaod();
			})
			.catch(function (error) {
				setIndicator(false);
				message.error("Oops.Something went wrong");
				console.log(error);
			});
	};

	return (
		<div>
			<PageHeader
				PageName={"Airway Bill Series"}
				breadScrum={"Admin / Airway Bill Series"}
				RightButton={"New Series "}
				RightClcik={() => AddItem()}
				loader={indicator}
			/>
			{loading ? (
				<LoadingBox />
			) : (
				<div>
					<DataTable
						meta={meta}
						data={data}
						update={(item) => UpdateItem(item)}
						delete={(item) => DeleteItem(item)}
						PageOnchange={(page, take) => pagination(page, take)}
					/>
					{form ? (
						<FormModal
							item={item}
							mode={mode}
							visible={form}
							onchange={() => relaod()}
							onClose={() => setform(false)}
						/>
					) : null}
				</div>
			)}
		</div>
	);
}
export default AirwayBillScreen;
