import React, { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import {
  Table,
  Pagination,
  Button,
  Popconfirm,
  Tooltip,
  Input,
  Form,
} from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineFilter, AiOutlinePrinter } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";

function DataTable(props) {
  const [searchText, setSearchText] = useState("");
  const [slNumber, setSlNumber] = useState(0);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [userFilter, setUserFilter] = useState(null);
  // const [thirdPartySenderFilter, setThirdPartySenderFilter] = useState(null);
  const [filteredData, setFilteredData] = useState();
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          size="small"
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ backgroundColor: "transparent" }}>
        <AiOutlineFilter />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleFilterClick = () => {
    const filteredData = props.data.filter((item) => {
      const userMatch =
        !userFilter ||
        item.user.name.toLowerCase().includes(userFilter.toLowerCase());

      return userMatch;
    });
    setFilteredData(filteredData);
  };
  const calculateIndex = (pageIndex, pageSize, index) => {
    return pageIndex * pageSize + index + 1;
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) =>
        calculateIndex(props.meta.page - 1, props.meta.take, index),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),

      render: (text, record, index) => (
        <Tooltip title={record.name && record.name}>
          <div
            className=""
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="">
              {record.user && record.user.name && record.user.name}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "3rd Party Sender Name",
      dataIndex: "name",
      render: (text, record) => (
        <div>
          {record.thirdPartySender?.name && record.thirdPartySender?.name}
        </div>
      ),
    },

    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <div>{moment(text).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "Time",
      dataIndex: "date",
      render: (text) => <div>{moment(text).format("HH:MM A")}</div>,
    },
    {
      title: "Pieces",
      dataIndex: "total_pcs",
    },
    {
      title: "weight",
      dataIndex: "total_weight",
      render: (text, record) => {
        const totalWeight = record.boxes.reduce((acc, curr) => {
          return (Number(acc) + Number(curr.weight)).toFixed(1);
        }, 0);
        return `${totalWeight} `;
      },
    },
    {
      title: "Action",
      width: 50,
      render: (item) => (
        <div className="tableAction">
          <div>
            <Button
              size="small"
              type="primary"
              style={{ borderRadius: 8, fontSize: 12 }}
              onClick={() => {
                props.printItem(item);
              }}
            >
              Print
            </Button>
          </div>
          <Button
            size="small"
            onClick={() => props.update(item)}
            type="primary"
          >
            Edit
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <AiOutlineDelete size={20} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div style={{ minHeight: "60vh" }}>
      <Form className="Form" layout="inline">
        <Form.Item className="formitem" label="User">
          <Input
            placeholder="Enter user"
            onChange={(e) => setUserFilter(e.target.value)}
          />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          icon={<FaFilter />}
          onClick={handleFilterClick}
        ></Button>
        <br></br>
        <br></br>
      </Form>

      <Table
        pagination={false}
        columns={columns}
        dataSource={filteredData ? filteredData : props.data}
        //dataSource={props.data}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={(page, take) => {
            props.PageOnchange(page, take);
          }}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}

export default DataTable;
