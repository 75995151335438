import { useState, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Table,
  Button,
  Space,
  DatePicker,
  Tooltip,
  Checkbox,
  Radio,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  Modal,
  Tabs,
  Badge,
} from "antd";
import "./style.css";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { API, Headers } from "../../Config";
import { useSelector } from "react-redux";
const { Option } = Select;
const { TabPane } = Tabs;
const FormModal = (props) => {
  const [showWarning, setShowWarning] = useState(false);

  console.log("sadsadsadsadsadasdasdsa", props.awbbill);
  const Auth = useSelector((state) => state.Auth.user);
  const formRef = useRef();
  // const [form] = Form.useForm();
  const [form] = Form.useForm();

  const dateFormat = "DD/MM/YYYY";
  let data = [];
  if (props.mode === "post") {
    data = [
      {
        key: 1,
        account_no: null,
        description: "",
        amount: 0,
      },
    ];
  } else {
    if (props.item.id) {
      const updatedPurchaseItems = props.item.awbPaymentDetails.map((item) => {
        return {
          key: item.key,
          account_no: item.account_no,
          description: item.description,
          amount: item.amount,
        };
      });
      data = updatedPurchaseItems;
      console.log("updatedPurchaseItems", data);
    }
    // handleTotal();
  }

  const [dataSource, setDataSource] = useState(data);
  const [filterdataSource, setFilterDataSource] = useState(data);

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(
    props.mode == "put" ? props.item.amount : 0
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAwbNo, setSelectedAwbNo] = useState(null);
  const [type, setType] = useState(
    props.mode === "post" ? "inbound" : props.item.bound
  );
  const [placeholders, setPlaceholder] = useState([]);
  const [list, setList] = useState([]);
  const tableRef = useRef(null);
  useEffect(() => {
    if (props.mode == "put") {
      AwbBills(props.item.awb_no);
    }
    handleRadioChange(type);
  }, []);

  //
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const onFinish = (values) => {
    console.log("dataSource", dataSource);

    setLoading(true);
    let des = props.awbbill.find((e) => e.id === values.awb_no);
    let obj = {
      date: values.date,
      bound: type,
      description:
        props.mode === "post"
          ? `${values.description || ""} ${des.airline} - ${des.awb_no}`
          : values.description,
      userid: Number(Auth.id),
      amount: Number(total),
      awb_no: Number(values.awb_no),
      referenceno: des.awb_no,
      details: dataSource,
      // dr_total: props.page_mode == "PV" ? 0 : Number(values.amount),
    };

    console.log("obj", obj);
    let id = props.item.id;
    let URL =
      props.mode === "post"
        ? API.AWB_PAYMENT_ACTIONS + props.page_mode
        : API.AWB_PAYMENT_ACTIONS + id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong save");
      });
  };

  const handleTotal = (_, values) => {
    console.log("dataSource", dataSource);
    const totalCr = dataSource.reduce((acc, item) => {
      return acc + item.amount;
    }, 0);

    // Update the state or perform actions with the total credit and debit amounts
    setTotal(totalCr);
  };

  const handleAddProduct = () => {
    const newDataSource = [...dataSource];
    newDataSource.push({
      slno: newDataSource.length + 1,
      key: newDataSource.length + 1,
      account_no: null,
      description: "",
      amount: 0,
    });
    setDataSource(newDataSource);
  };

  const handleRemoveProduct = (key) => {
    let deleteList = [...dataSource];
    let keys = deleteList.findIndex((value) => value.key === key);
    deleteList.splice(keys, 1);
    setDataSource(deleteList);
  };

  const handleChangeValueSet = (value, key, field) => {
    const newDataSource = [...dataSource];
    newDataSource.forEach((val) => {
      if (val.key === key) {
        val[field] = value;
      }
    });
    setDataSource(newDataSource);
    // console.log("newDataSource", newDataSource);
  };
  let placeH = [];
  const handleRadioChange = (val) => {
    setType(val);
    if (type === "inbound") {
      placeH = [
        "",
        "AWB SUPPLIER",
        "FORWARDING AGENT",
        "EXPORT BROKER",
        "CLEARING AGENT AT DESTINATION",
        "DELIVERY AGENT AT DESTINATION",
      ];
    } else {
      placeH = [
        "",
        "DELIVERY ORDER",
        "TSP",
        "DOMURAGE",
        "DUTY",
        "HANDLING CHARGE",
        "SERVICE CHARGE",
        "LOADING AT AIRPORT",
        "CHA CHARGE",
        "EXTRA",
      ];
    }

    setPlaceholder(placeH);
  };

  const place = (key) => {
    console.log("key", key);
    console.log("placeholders.length + 1", placeholders.length + 1);
    let pl;
    if (key < placeholders.length) {
      pl = placeholders[key];
    } else {
      pl = "SELECT ACCOUNT";
    }

    return pl;
  };
  const handleConfirm = () => {
    // Perform the action here
    setShowWarning(false);
  };

  const handleCancel = () => {
    setShowWarning(false);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "slno",
      key: "slno",
      width: "30px",
      align: "center",
      visible: true,
      fixed: "left",
      render: (text, record, index) => index + 1, // Renders the row number
    },
    {
      title: "Account",
      dataIndex: "account_no",
      key: "account_no",
      visible: true,
      width: "300px",
      fixed: "left",
      render: (text, record) => (
        <Form.Item
          style={{ padding: 0, margin: 0 }}
          name={["list", record.key, "account_no"]}
          initialValue={text}
          rules={[{ required: true, message: "required" }]}
        >
          <Select
            bordered={false}
            className="CustomerForm-Select"
            showSearch
            allowClear
            placeholder={place(record.key)}
            filterOption={(input, option) => {
              const accountNo = option.value.toLowerCase();
              const accountName = option.children.toString().toLowerCase();
              return (
                accountNo.includes(input.toLowerCase()) ||
                accountName.includes(input.toLowerCase())
              );
            }}
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: "0px",
            }}
            value={record.account_no}
            onChange={(value) => {
              handleChangeValueSet(value, record.key, "account_no");
              handleChangeValueSet(
                place(record.key),
                record.key,
                "description"
              );
              console.log(value);
            }}
          >
            {props.accounts.map((item) => (
              <Select.Option key={item.account_no} value={item.account_no}>
                {item.account_no}- {item.account_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      visible: true,
      width: "250px",
      render: (text, record) => (
        <Input.TextArea
          style={{ width: "100%" }}
          autoSize={{ minRows: 1, maxRows: 6 }}
          bordered={false}
          value={record.description}
          onChange={(e) =>
            handleChangeValueSet(e.target.value, record.key, "description")
          }
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      visible: true,
      width: "150px",
      render: (text, record) => {
        return (
          <InputNumber
            style={{ width: "100%" }}
            bordered={false}
            value={record.amount}
            onChange={(e) => {
              handleChangeValueSet(e, record.key, "amount");
              handleTotal();
            }}
          />
        );
      },
    },

    {
      title: " ",
      key: "action",
      visible: true,
      align: "center",
      width: "30px",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              if (dataSource.length > 1) {
                handleRemoveProduct(record.key);
              }
            }}
          />
        </Space>
      ),
    },
  ];

  const renderSummary = (form) => {
    return (
      <>
        <Table.Summary.Row
          className="my-summary-row"
          style={{ height: "50px", alignItems: "left" }}
        >
          <Table.Summary.Cell
            className="cell"
            fixed="left"
            scroll={{ x: 0, y: 0 }}
          ></Table.Summary.Cell>
          <Table.Summary.Cell
            className="cell"
            fixed="left"
            scroll={{ x: 0, y: 0 }}
          >
            <Button
              style={{
                textDecoration: "underline",
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              size="small"
              bordered={false}
              onClick={handleAddProduct}
            >
              Add New
            </Button>
          </Table.Summary.Cell>

          <Table.Summary.Cell className="cell">
            {" "}
            <b>Total</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell align="center">
            <Form.Item
              form={form}
              name="dr_total"
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              {/* <InputNumber
                bordered={false}
                readOnly
                size="small"
                className="bold-input"
                style={{
                  width: "100%",
                  textAlign: "right",
                  display: "block",
                  height: "100%",
                }}

              /> */}

              <b style={{ paddingLeft: "9px", textAlign: "left" }}>
                {Number(total).toFixed(2)}
              </b>
            </Form.Item>
          </Table.Summary.Cell>
          <Table.Summary.Cell
            className={`cell ${!props.columnsVisible.amount_cr && "hidden"}`}
            align="center"
          >
            <Form.Item
              form={form}
              name="cr_total"
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              {/* <InputNumber
                readOnly
                size="small"
                bordered={false}
                className="bold-input"
                style={{
                  width: "100%",
                  textAlign: "right",
                  display: "block",
                  height: "100%",
                }}
              /> */}

              <b style={{ paddingLeft: "9px", textAlign: "left" }}>
                {Number(total).toFixed(2)}
              </b>
            </Form.Item>
          </Table.Summary.Cell>

          <Table.Summary.Cell
            className="cell"
            fixed="right"
          ></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
  const handleSelectAwbNo = (awbNo) => {
    setSelectedAwbNo(awbNo);
    setModalVisible(false);
  };
  const handleSelectOposite = (awbNo) => {
    axios
      .get(
        API.BASR_URL +
          API.AWB_PAYMENT_LIST +
          `/awb/${awbNo}/${type == "inbound" ? "outbound" : "inbound"}`,
        {
          headers: Headers(),
        }
      )
      .then(function (res) {
        if (res.status === 200) {
          console.log("123212321321312321321321", res.data);
          setList(res.data);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 404) {
          // Handle 404 error and return empty array
          setList([]);
        } else {
          // Handle other errors
          message.error("Customers dropdown failed");
        }
      });
  };
  const ifExist = (awbNo) => {
    axios
      .get(API.BASR_URL + API.AWB_PAYMENT_LIST + `/awb/${awbNo}/${type}`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setShowWarning(true);
          }
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 404) {
          // Handle 404 error and return empty array
          setList([]);
        } else {
          // Handle other errors
          message.error("Customers dropdown failed");
        }
      });
  };
  const AwbBills = (e) => {
    handleSelectOposite(e);
    ifExist(e);
  };

  return (
    <>
      <div>
        <Modal
          title={"Genarate new Awb Payment"}
          centered
          open={props.visible}
          onOk={() => props.onClose()}
          onCancel={() => props.onClose()}
          width={1000}
          footer={false}
          style={{ borderRadius: 10 }}
          headerStyle={{ backgroundColor: "red" }}
        >
          <Badge.Ribbon
            color="cyan"
            text={
              type === "inbound" ? (
                <>
                  Inbound Payments Register
                  {/* <Badge
                    className="site-badge-count-109"
                    count={list && list[0]?.awbPaymentDetails?.length}
                    style={{ backgroundColor: "#52c41a" }}
                  /> */}
                  {/* <Badge count={list && list[0]?.awbPaymentDetails?.length} /> */}
                </>
              ) : (
                <>
                  Outbound Payments Register
                  {/* <Badge count={list && list[0]?.awbPaymentDetails?.length} /> */}
                  {/* <Badge
                    className="site-badge-count-109"
                    count={list && list[0]?.awbPaymentDetails?.length}
                    style={{ backgroundColor: "#52c41a" }}
                  /> */}
                </>
              )
            }
          >
            <Tabs defaultActiveKey="1">
              <TabPane tab="Generate New" key="1">
                <Form
                  form={form}
                  ref={formRef}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onValuesChange={handleTotal}
                  initialValues={
                    props.mode === "post"
                      ? {
                          date: moment(),
                          amount: 0.0,
                        }
                      : {
                          date: props.item && moment(props.item.date),
                          account_no: props.item && Number(props.item.awb_no),
                          description: props.item && props.item.description,
                          amount: props.item && props.item.amount,
                          awb_no: props.item && props.item.awb_no,
                          bound: props.item && props.item.bound,
                        }
                  }
                  hideRequiredMark
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        labelCol={{
                          span: 7,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        name="bound" // Make sure this matches the field name in your form's data structure
                        label="Bound"
                      >
                        <Radio.Group
                          onChange={(e) => {
                            handleRadioChange(e.target.value);
                            form.setFieldValue("awb_no", "");
                          }}
                          defaultValue="inbound"
                        >
                          <Radio.Button value="inbound">In Bound</Radio.Button>
                          <Radio.Button value="outbound">
                            Out Bound
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        labelCol={{
                          span: 7,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        name="awb_no"
                        label="Awb Bill No"
                        rules={[{ required: true, message: "required" }]}
                      >
                        <Select
                          size="small"
                          placeholder="Select awb no"
                          showSearch
                          allowClear
                          style={{ borderRadius: "0px" }}
                          onChange={AwbBills}
                          filterOption={(input, option) => {
                            const accountNo = option.value.toLowerCase();
                            const accountName = option.children
                              .toString()
                              .toLowerCase();
                            return (
                              accountNo.includes(input.toLowerCase()) ||
                              accountName.includes(input.toLowerCase())
                            );
                          }}
                        >
                          {props.awbbill.map((item, index) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.airline}
                              {" - "}
                              {item.awb_no}
                              {" - "}
                              {item.orijin_airport_name &&
                                item.orijin_airport_name}{" "}
                              /{" "}
                              {item.destination_airport_name &&
                                item.destination_airport_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        labelCol={{
                          span: 7,
                        }}
                        wrapperCol={{
                          span: 7,
                        }}
                        name="date"
                        label="Date"
                        rules={[{ required: true, message: "required" }]}
                      >
                        <DatePicker
                          size="small"
                          format={dateFormat}
                          placeholder="Pick a date"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ marginBottom: "12px" }}
                        labelCol={{
                          span: 7,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        name="description"
                        label="Description"
                        // style={styles.FormItem}
                        rules={[{ required: false, message: "required" }]}
                      >
                        <Input.TextArea
                          rows={2}
                          size="small"
                          placeholder="Enter Remarks"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Table
                    initialValue={{}}
                    className="my-table"
                    dataSource={dataSource}
                    columns={columns.filter((column) => column.visible)}
                    pagination={false}
                    size="small"
                    bordered
                    summary={() => renderSummary(form)}
                    rowKey="key"
                    ref={tableRef}
                  />

                  <Row justify="end">
                    <Col sm="6" xs="12" style={{ padding: "10px" }}>
                      <Button width="30%" onClick={() => props.onClose()} block>
                        Cancel
                      </Button>
                    </Col>
                    <Col sm="6" xs="12" style={{ padding: "10px" }}>
                      <Button
                        width="30%"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        block
                      >
                        {props.mode === "post" ? "Save" : "Update"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </TabPane>

              <TabPane
                tab={
                  type === "inbound" ? (
                    <>
                      Outbound List
                      <Badge
                        count={list && list[0]?.awbPaymentDetails?.length}
                      />
                    </>
                  ) : (
                    <>
                      Inbound List
                      <Badge
                        count={list && list[0]?.awbPaymentDetails?.length}
                      />
                    </>
                  )
                }
                key="2"
              >
                {list &&
                list.length > 0 &&
                list[0].awbPaymentDetails &&
                list[0].awbPaymentDetails.length > 0 ? (
                  <Table
                    dataSource={list[0].awbPaymentDetails}
                    size="small"
                    pagination={false}
                    columns={[
                      {
                        title: "#",
                        dataIndex: "slno",
                        key: "slno",
                        width: "30px",
                        align: "center",
                        visible: true,
                        fixed: "left",
                        render: (text, record, index) => index + 1, // Renders the row number
                      },
                      {
                        title: "Account Number",
                        dataIndex: "account_no",
                        key: "account_no",
                        render: (text, record) => record.accounts.account_name,
                      },
                      {
                        title: "Description",
                        dataIndex: "description",
                        key: "description",
                      },
                      {
                        title: "Amount",
                        dataIndex: "amount",
                        key: "amount",
                      },
                    ]}
                    footer={() => {
                      const totalAmount = list[0].awbPaymentDetails.reduce(
                        (sum, item) => sum + item.amount,
                        0
                      );
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            paddingRight: "100px",
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            Total Amount:
                          </span>
                          <span>
                            <b>{totalAmount.toFixed(2)}</b>
                          </span>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <p>No awbPaymentDetails available</p>
                )}
              </TabPane>
            </Tabs>
          </Badge.Ribbon>
        </Modal>
        <div>
          {/* Your content here */}

          <Modal
            title="Warning"
            visible={showWarning}
            onCancel={handleCancel}
            onOk={handleConfirm}
          >
            <p>Alredy have transaction ! Are you sure you want to continue?</p>
          </Modal>
        </div>
        {/* {modalVisible && (
          <Modal
            title="Select Airway Bill No"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            footer={null}
          >
            <Table
              columns={column.filter((column) => column)}
              dataSource={props.awbbill}
              pagination={false}
              size="small"
            />
          </Modal>
        )} */}
      </div>
    </>
  );
};

export default FormModal;
