import React, { useState, useEffect, useRef } from "react";
import { Modal, message, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import { API, Headers, CurrancyRate } from "../../Config";
import axios from "axios";
import moment from "moment";
import Barcode from "react-barcode";
import "./style.css";
import Companyseal from "../../Assets/Images/seal.JPG";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import RATES from "../../Config/exchangeRates";
import { POST } from "../../Config/ApiRequests";

function DownloadTiffModal(props) {
  const componentRef = useRef();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [boxItems, setBoxItems] = useState([]);
  const [USD, setUsd] = useState(0);
  const [exchangeData, setexchangeData] = useState([]);

  const [isLoading2, setIsLoading2] = useState(false);
  const [exchangeRates, setexchangeRates] = useState([]);
  const [meta, setMeta] = useState({});

  useEffect(() => {
    loadDetails();
    // GetCurrancy();
  }, []);

  useEffect(() => {
    getExchangeRates(1, 10);
  }, []);

  const getExchangeRates = async (_page, _take) => {
    try {
      setIsLoading2(true);
      let api = API.EXCHANGE_RATE_LIST;
      let obj = {
        page: _page,
        take: _take,
      };
      const excData = await POST(api, obj);
      if (excData?.status) {
        setexchangeRates(excData?.data);
        setUsd(excData?.data[0]?.sar_usd);
        setMeta(excData?.meta);
        setIsLoading2(false);
      } else {
        message.error("No data found");
        setIsLoading2(false);
      }
    } catch (err) {
      message.error("Oops.Something went");
      setIsLoading2(false);
    }
  };

  const loadDetails = () => {
    setBoxItems(props.item.boxe_item);
  };

  //Changes by JIS -- 7/4/2024
  const GetCurrancy = async () => {
    // const USD = await CurrancyRate("SAR", "USD");
    // let USD = RATES.SAR_USD;
    // setUsd(USD);
    const URL = API.BASR_URL + API.EXCHANGE_RATE + "SAR_USD";
    try {
      const response = await axios.get(URL, {});
      console.log(response);
      if (response?.status) {
        const data = response?.data?.data?.rate;
        setexchangeData(data);
        setUsd(data);
        return Number(data);
      } else {
        setIsLoading(false);
        message.error("Oops.Something went");
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const medianBoxNum = Math.floor(boxItems.length / 2);

  let requestData = {
    is_for_customer: true,
  };

  const isPrinted = () => {
    console.log("IS printed");
    axios({
      method: "put",
      url:
        API.BASR_URL +
        API.BOXES_ACTIONS +
        "print/" +
        props.item.box_number +
        "/is_for_customer",
      headers: Headers(),
      data: requestData,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onClose();
          props.reload();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  // let totalValue = props.item && Number(props.item.total_value_usd).toFixed(2);
  // let basicValue = Number(totalValue / 1.21125).toFixed(2);
  // let frieghtValue = Number(basicValue * 0.2).toFixed(2);
  // let InsuranceValue = Number(basicValue * 0.01125).toFixed(2);

  let basicValue = Number(
    boxItems.reduce(function (tot, arr) {
      return (
        Number(tot) +
        Number(arr.value * arr.quantity * exchangeRates[0]?.sar_usd)
      );
    }, 0)
  ).toFixed(2);
  let frieghtValue = Number(basicValue * 0.2).toFixed(2);
  let InsuranceValue = Number(basicValue * 0.01125).toFixed(2);
  let totalValue = Number(
    Number(basicValue) + Number(frieghtValue) + Number(InsuranceValue)
  ).toFixed(2);

  return (
    <Modal
      visible={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      width={900}
      footer={null}
    >
      <div ref={componentRef}>
        <div className="print-invoice-page" style={{ height: "max-content" }}>
          <table className="print-invoice-table">
            <tr>
              <td colSpan={2}>
                <div className="print-customsInvoice-txt1">
                  <div> SAHARI INTERNATIONAL</div>
                  <div>COURIER SERVICES</div>
                </div>
              </td>
              <td colSpan={2} className="print-customsInvoice-header">
                <div>AIRWAY BILL NO</div>
                <Barcode
                  value={props.item.box_number}
                  height="40"
                  font="Montserrat-Regular"
                  fontSize="12px"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table
                  style={{ fontSize: "10px" }}
                  className="print-invoice-table"
                >
                  <tr>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">DATE</div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">ORIGIN</div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        DESTINATION
                      </div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        REFERENCE NO
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        {moment(props.item.createdAt).format("DD-MM-YYYY")}
                      </div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        SAUDI ARABIA
                      </div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        {props.item?.country?.length > 2
                          ? props.item.country
                          : props.item?.collection?.recivercountries?.name ||
                            ""}

                        {/* {props.item?.country?.length > 2
                          ? props.item?.country
                          : props.item?.collection &&
                            props.item.collection.recivercountries &&
                            props.item.collection.recivercountries.name} */}
                      </div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        {props.item.box_ref_no
                          ? props.item.box_ref_no
                          : props.item.referance_details
                          ? props.item.referance_details
                          : ""}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2} className="print-invoice-table-td">
                <div className="print-customsInvoice-txt2">DECLARATION</div>
                <div className="print-invoice-txt5">
                  shipper certifies that the nature of goods is as AIRWAY BILL
                  the shipper agree to and accept the contract conditions on the
                  reverse side here of and acknowledges that the goods described
                  herein is accepted by SAHARI INTERNATIONAL COURIER SERVICE for
                  clearance to said contract condition. shipper declares that
                  goods carried under this consignmet contain no DANGEROUS
                  GOODS, as described under the IATA dangerous goods regulation.
                </div>
              </td>
              <td colSpan={2} className="print-invoice-table-td">
                <table className="print-invoice-table">
                  <tr>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">Pieces</div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">Weight</div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        Invoice Value
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">1</div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        {props.item.chargable_weight}
                      </div>
                    </td>
                    <td className="print-invoice-table-td">
                      <div className="print-customsInvoice-txt3">
                        <b>${totalValue}</b>
                      </div>
                    </td>
                  </tr>
                </table>
                <div className="print-invoice-txt5">
                  I{" "}
                  <b className="print-customsInvoice-txt7">
                    {props.item.iqama_name}
                  </b>{" "}
                  ID: <b>{props.item.iqama_number}</b>
                  holder of Indian Passport No/Aadhaar No: this.props Do here by
                  appointed SAHARI INTERNAIONAL COURIER SERVICES as my
                  authorised agent to do the courier clearance in india for me
                  and sending to <b> {props.item.adhar_name} </b>
                  as a gift consignment
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <div className="print-invoice-txt4">INVOICE/PACKING LIST</div>
              </td>
            </tr>
            <tr>
              <th colSpan={2} className="print-invoice-table-td">
                <div className="print-customsInvoice-txt4">
                  Sender Name & Address
                </div>
              </th>
              <th colSpan={2} className="print-invoice-table-td">
                <div className="print-customsInvoice-txt4">
                  Gifts For Personal Use Only
                </div>
              </th>
            </tr>
            <tr>
              <td colSpan={2} className="print-invoice-table-td">
                <div className="print-customs-txt2">
                  {props.item.iqama_name} ID :{props.item.iqama_number}
                </div>
                <div className="print-customs-txt2">
                  {props.item.sender_mobile}
                </div>
                <div className="print-customs-txt2">{props.item.address}</div>
                <div className="print-customsInvoice-txt4">
                  <b>Consignee Name & Address</b>
                </div>

                <div className="print-customs-txt2">
                  {props.item.adhar_name}
                </div>
                <div className="print-customs-txt2">
                  {props.item.reciver_mobile1}
                </div>
                <div className="print-customs-txt2">
                  {/* {props.item.adhar_address},{props.item.district},
                  {props.item.state},{" "}
                  {props.item.country.length > 2
                    ? props.item.country
                    : props.item.collection &&
                      props.item.collection.recivercountries &&
                      props.item.collection.recivercountries.name} */}
                  {props.item.adhar_address}, {props.item.district},{" "}
                  {props.item.state},{" "}
                  {props.item.country && props.item.country.length > 2
                    ? props.item.country
                    : props.item.collection?.recivercountries?.name || ""}
                </div>
              </td>
              <td colSpan={2} className="print-invoice-table-td">
                <div style={{ display: "flex" }}>
                  <div className="print-customsInvoice-pod-box">
                    <div>P</div>
                    <div>O</div>
                    <div>D</div>
                  </div>
                  <div>
                    <div className="print-invoice-txt5">
                      I, the undersigned on behalf of the above sender/shipper
                      acknowledgement the receipt of goods in good condition
                    </div>
                    <div className="print-customs-txt2">
                      <b>Reciever Name :</b>
                      {props.item.adhar_name}
                    </div>
                    <div className="print-customs-txt2">
                      <b>Date : </b>
                      {/* {moment(props.item.createdAt).format("DD-MM-YYYY")} */}
                      ...
                    </div>
                    <div className="print-customs-txt2">
                      <b>Time : </b>
                      {/* {moment(props.item.createdAt).format("HH:MM:SS")} */}
                      ...
                    </div>
                    <div className="print-customs-txt2">
                      <b>Signature :</b>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <br />
                <div className="print-invoice-table-row">
                  <div className="print-invoice-table-column">
                    <table className="print-invoice-table">
                      <tr>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">Sl No</div>
                        </th>
                        <th className="print-customs-td" colSpan={2}>
                          <div className="print-customsInvoice-txt5">
                            Description
                          </div>
                        </th>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">Qty</div>
                        </th>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">Value</div>
                        </th>
                      </tr>
                      {boxItems &&
                        boxItems.slice(0, 15).map((items, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {index + 1}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={2}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {items.item}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {items.quantity}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {Number(
                                    Number(items.value) * items.quantity * USD
                                  ).toFixed(2)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  <div className="print-invoice-table-column">
                    <table className="print-invoice-table">
                      <tr>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">
                            {" "}
                            Sl No
                          </div>
                        </th>
                        <th className="print-customs-td" colSpan={2}>
                          <div className="print-customsInvoice-txt5">
                            Description
                          </div>
                        </th>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">Qty</div>
                        </th>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">Value</div>
                        </th>
                      </tr>
                      {boxItems &&
                        boxItems.slice(15).map((items, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {index + 16}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={2}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {items.item}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {items.quantity}
                                </div>
                              </td>
                              <td
                                className="print-invoice-table-td"
                                colSpan={1}
                              >
                                <div className="print-customsInvoice-txt6">
                                  {Number(
                                    Number(items.value) * items.quantity * USD
                                  ).toFixed(2)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <th colSpan={2}></th>
                        <th className="print-customs-td" colSpan={2}>
                          <div className="print-customsInvoice-txt5">
                            Basic Value:
                          </div>
                        </th>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">
                            {basicValue}
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={2}></th>
                        <th className="print-customs-td" colSpan={2}>
                          <div className="print-customsInvoice-txt5">
                            Freight @ 20%:
                          </div>
                        </th>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">
                            {frieghtValue}
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={2}></th>
                        <th className="print-customs-td" colSpan={2}>
                          <div className="print-customsInvoice-txt5">
                            Insurance @ 1.125%:
                          </div>
                        </th>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">
                            {InsuranceValue}
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={2}></th>
                        <th className="print-customs-td" colSpan={2}>
                          <div className="print-customsInvoice-txt5">
                            Total Value:
                          </div>
                        </th>
                        <th className="print-customs-td" colSpan={1}>
                          <div className="print-customsInvoice-txt5">
                            ${totalValue}
                          </div>
                        </th>
                      </tr>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <div className="print-invoice-txt5">
                  1. I.Mr/Mrs{" "}
                  <b className="print-customsInvoice-txt7">
                    {props.item.iqama_name}
                  </b>
                  ID:<b>{props.item.iqama_number}</b>
                  for my family/friends, personal use as gift.not for sale.
                  Shipper also declare that he is liable of any customs
                  duty/fine / penalites against this shipment at origin and
                  destination
                </div>
                <div className="print-invoice-txt5">
                  2. Shipper certifies that the particulars on the face here of
                  are correct and that insofar as any part of the consignemt
                  contains Dangerous Goods if any surch part is property by name
                  and is in proper condition for carriage by air according to
                  the Internation Transport Association's applicable dangerous
                  goods regulations and civil Aviation Regulation of the Saudi
                  Arabia.
                </div>
                <div className="print-invoice-txt5">
                  3. I/We agree that standard terms and conditions apply for
                  this shipment and liability to terms and conditions. Warsaw
                  Convention amy apply. I/We Service Liability shall not exceed
                  US$100 for any shipment.
                </div>
                <div className="print-invoice-txt5">
                  4. /The Shipper also here by authorize forward the shipment in
                  full or part their convenience
                </div>
                <div className="print-invoice-txt5">
                  5. The Shipper also here by authorize and their Counterpart
                  {"  "}
                  {props.item.country} to clear the parcel on behalf of me and
                  same should be delivered to the Consignee address.
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>
                <div className="print-customsInvoice-txt4">
                  Customer Signature
                </div>
              </th>
              <th colSpan={1}></th>
              <th colSpan={1}>
                <div className="print-customsInvoice-txt4">Company SEAL</div>
              </th>
            </tr>
            <tr>
              <td colSpan={2} style={{ height: "14vh" }}>
                {props.item.signature ? (
                  <div>
                    <img
                      src={props.item.signature}
                      className="print-invoice-signature"
                      style={{ width: "150px" }}
                    />
                  </div>
                ) : null}
              </td>
              <th colSpan={1}></th>
              <td colSpan={1}>
                <div
                  className="print-customs-seal"
                  style={{ width: "50%", display: "flex" }}
                >
                  <img src={Companyseal} height="100%" width="100%" />
                  {props.item.collection &&
                  props.item.collection.driver_signature ? (
                    <img
                      src={
                        props.item.collection &&
                        props.item.collection.driver_signature
                      }
                      className="print-invoice-signature"
                    />
                  ) : null}
                </div>
              </td>
            </tr>
          </table>
        </div>

        {/* {medianBoxNum >= 11 ? (
          <div style={{ padding: "20px" }}>
            <br />
            <div className="print-invoice-table-row">
              <div className="print-invoice-table-column">
                <table className="print-invoice-table">
                  <tr>
                    <th className="print-customs-td" colSpan={1}>
                      <div className="print-customsInvoice-txt5"> Sl No</div>
                    </th>
                    <th className="print-customs-td" colSpan={2}>
                      <div className="print-customsInvoice-txt5">
                        Description
                      </div>
                    </th>
                    <th className="print-customs-td" colSpan={1}>
                      <div className="print-customsInvoice-txt5">Qty</div>
                    </th>
                    <th className="print-customs-td" colSpan={1}>
                      <div className="print-customsInvoice-txt5">Value</div>
                    </th>
                  </tr>
                  {boxItems &&
                    boxItems.slice(15, 30).map((items, index) => {
                      return (
                        <tr key={index}>
                          <td className="print-invoice-table-td" colSpan={1}>
                            <div className="print-customsInvoice-txt6">
                              {index + 16}
                            </div>
                          </td>
                          <td className="print-invoice-table-td" colSpan={2}>
                            <div className="print-customsInvoice-txt6">
                              {items.item}
                            </div>
                          </td>
                          <td className="print-invoice-table-td" colSpan={1}>
                            <div className="print-customsInvoice-txt6">
                              {items.quantity}
                            </div>
                          </td>
                          <td className="print-invoice-table-td" colSpan={1}>
                            <div className="print-customsInvoice-txt6">
                              {Number(
                                Number(items.value) * items.quantity * USD
                              ).toFixed(2)}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div className="print-invoice-table-column">
                <table className="print-invoice-table">
                  <tr>
                    <th className="print-customs-td" colSpan={1}>
                      <div className="print-customsInvoice-txt5"> Sl No</div>
                    </th>
                    <th className="print-customs-td" colSpan={2}>
                      <div className="print-customsInvoice-txt5">
                        Description
                      </div>
                    </th>
                    <th className="print-customs-td" colSpan={1}>
                      <div className="print-customsInvoice-txt5">Qty</div>
                    </th>
                    <th className="print-customs-td" colSpan={1}>
                      <div className="print-customsInvoice-txt5">Value</div>
                    </th>
                  </tr>
                  {boxItems &&
                    boxItems.slice(30, 40).map((items, index) => {
                      return (
                        <tr key={index}>
                          <td className="print-invoice-table-td" colSpan={1}>
                            <div className="print-customsInvoice-txt6">
                              {index + 31}
                            </div>
                          </td>
                          <td className="print-invoice-table-td" colSpan={2}>
                            <div className="print-customsInvoice-txt6">
                              {items.item}
                            </div>
                          </td>
                          <td className="print-invoice-table-td" colSpan={1}>
                            <div className="print-customsInvoice-txt6">
                              {items.quantity}
                            </div>
                          </td>
                          <td className="print-invoice-table-td" colSpan={1}>
                            <div className="print-customsInvoice-txt6">
                              {Number(
                                Number(items.value) * items.quantity * USD
                              ).toFixed(2)}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
            <br />
          </div>
        ) : null} */}
      </div>

      <Row>
        <Col sm="3"></Col>

        <Col sm="3" xs="3">
          <Button onClick={() => props.onClose()} block size="large">
            Cancel
          </Button>
        </Col>
        <Col sm="3" xs="3">
          <Button
            type="primary"
            size="large"
            block
            onClick={() => {
              const content = componentRef.current;
              var w = content.offsetWidth;
              var h = content.offsetHeight;
              html2canvas(content, {
                useCORS: true,
                allowTaint: true,
                dpi: 300, // Set to 300 DPI
                scale: 3, // Adjusts your resolution
              }).then((canvas) => {
                var img = canvas.toDataURL("image/jpeg", 1);
                var doc = new jsPDF();
                // var width = doc.internal.pageSize.getWidth();
                var width = 225;
                var height = doc.internal.pageSize.getHeight();
                console.log("width", width);
                doc.addImage(img, "JPEG", 0, 0, width, height);
                doc.save(`${props.item.box_number}.pdf`);
              });
            }}
          >
            Download PDF
          </Button>
        </Col>
        <Col sm="3" xs="3">
          <Button
            type="primary"
            size="large"
            block
            onClickCapture={() => {
              const content = componentRef.current;
              html2canvas(content, {
                useCORS: true,
                allowTaint: true,
                dpi: 300, // Set to 300 DPI
                scale: 3, // Adjusts your resolution
              }).then((canvas) => {
                canvas.toBlob((blob) => {
                  saveAs(blob, `${props.item.box_number}.tif`);
                }, "image/tiff");
              });
            }}
          >
            Download TIFF
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
export default DownloadTiffModal;
