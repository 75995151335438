const styles = {
  FormItem: {
    margin: -3,
    padding: 0,
    maginLeft: 0,
    marginTop: -3,
    marginBottom: -5,
    textTransform: "uppercase",
  },

  FormItem2: {
    margin: -3,
    marginTop: -5,
    marginBottom: -10,
    padding: 0,
    maginLeft: 20,
    marginRight: 2,
    textTransform: "uppercase",
  },
  FormItem3: {
    margin: -3,
    padding: 0,
    maginLeft: 0,
    marginTop: -5,
    marginBottom: -5,
    fontSize: "9px",
  },
  FormInput: {
    border: 0,
    padding: 2,
    borderRdius: 0,
    width: "100%",
    textTransform: "uppercase",
  },
  FormInput2: {
    textTransform: "uppercase",
    backgroundColor: "transparent",
    color: "#fff",
    border: 0,
    marginTop: -10,
  },
  FormInput3: {
    border: 0,
    padding: 2,
    borderRdius: 0,
    width: "100%",
  },
  FormItemInput: {
    borderRadius: 6,
    border: 0,
    background: "#f5f5f5",
    padding: 8,
    width: "100%",
    textTransform: "uppercase",
    fontFamily: "Montserrat-Regular",
  },
  FormItemInputPhoneno: {
    borderRadius: 6,
    border: 0,
    background: "#f5f5f5",
    padding: 10,
    width: "50%",
  },
  FormItemOtherDetails: {
    borderRadius: 5,
    border: 0,
    padding: 6,
    background: "#f5f5f5",
    textTransform: "uppercase",
  },
  FormItemInputAdress: {
    borderRadius: 4,
    border: 0,
    background: "#f5f5f5",
    width: "100%",
    textTransform: "uppercase",
    fontFamily: "Montserrat-Regular",
  },
  InputGroups: { borderRadius: "6px", fontFamily: "Montserrat-Regular" },
  RecieverFormRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  RecieverFormRowItem: {
    flex: 1,
    width: "auto",
  },
  NextButton: {
    background: "#1e88e5",
    borderColor: "#1e88e5",
    borderRadius: "6px",
    color: "#fff",
  },
  BackButton: {
    background: "#B5B5B5",
    borderColor: "#B5B5B5",
    borderRadius: "6px",
    color: "#fff",
  },
  FormItemNew: {
    margin: -3,
    marginTop: -5,
    marginBottom: -5,
    padding: 0,
    maginLeft: 0,
    marginRight: -2,
    textTransform: "uppercase",
  },
  FormItem2New: {
    margin: -3,
    marginTop: -5,
    marginBottom: -5,
    padding: 0,
    maginLeft: 20,
    marginRight: 2,
    textTransform: "uppercase",
  },
};
export default styles;
