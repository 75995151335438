import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tabs,
} from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../Config";
import axios from "axios";
import { useSelector } from "react-redux";

const SalespaymentModal = (props) => {
  const taxGoDatas = useSelector((state) => state.TaxgoSlice);
  const Auth = useSelector((state) => state.Auth.user);

  const [loading, setLoading] = useState(false);
  const [ifBank, setIfBank] = useState(true);

  const { TabPane } = Tabs;
  const formRef = useRef();

  const onValuesChange = (val) => {
    if (val.mode === "Bank") {
      setIfBank(false);
    } else {
      setIfBank(true);
    }
  };

  const selectedRows = props?.selectedRows?.data || [];
  const ledgerTransCollns = selectedRows?.flatMap(
    (row) => row?.collection?.ledgerTransColln || []
  );

  const totalAmount = ledgerTransCollns
    ?.reduce((total, e) => {
      if (e?.note === "COLLN") {
        return total + parseFloat(e.amount || 0);
      }
      return total;
    }, 0)
    .toFixed(2);

  const paidAmount = ledgerTransCollns
    ?.reduce((total, e) => {
      if (e?.cr_or_dr === "PAY" && !isNaN(parseFloat(e.amount))) {
        return total + (parseFloat(e.amount) + parseInt(e.round_off || 0));
      }
      return total;
    }, 0)
    .toFixed(2);

  const balance = ledgerTransCollns
    ?.reduce((balance, e) => {
      const amount = parseFloat(e?.amount || 0);
      if (e.cr_or_dr === "Dr") {
        return balance + amount;
      } else if (e.cr_or_dr === "Cr") {
        return balance - amount;
      }
      return balance;
    }, 0)
    .toFixed(2);

  const onFinish = async () => {
    try {
      setLoading(true);
      const { userID, companyID, staff_id, counterid, token } =
        taxGoDatas?.data || {};
      const staffTransactions = selectedRows?.map((box) => {
        const totalCharge = Number(box?.total_charge);
        return {
          id: box?.taxgo_transaction_id,
          adminid: userID,
          companyid: companyID,
          ledger: "47",
          ledgercategory: "3",
          type: "Customer Receipt",
          usertype: "staff",
          paid_amount: totalCharge,
          total: totalCharge,
          outstanding: "0.00",
          saleid: Number(box?.taxgo_new_saleid),
          customerid: staff_id,
          shiftid: 318,
          invoiceno: box?.taxgo_sale_invoicenumber,
          paymethod: "cash",
          staffid: staff_id,
          status: "open",
          paid_status: 2,
          sdate: new Date(),
          counterid: counterid,
        };
      });

      const obj = {
        staffTransactions,
        bankid: 24410,
        paidmethod: "cash",
        date: new Date(),
        reference: "ref",
      };

      const response = await axios.post(
        `${API.TAXGO_BASE_URL}${API.CLOSE_TRANSACTION_PAYMENT_TAXGO}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        await updateAmountField();
        message.success("Payment Success");
        props.onCancel();
      } else {
        message.error("Payment Failed!");
      }
    } catch (error) {
      message.error("Oops. Something went wrong");
      console.error("Payment error:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateAmountField = async () => {
    const { token } = taxGoDatas?.data || {};
    try {
      const obj = selectedRows?.map((box) => ({
        box_number: box?.box_number,
        staff_paid: true,
        adminId: Auth?.id,
      }));

      const response = await axios.put(
        `${API.BASR_URL}${API.BOXUPDATEPAYMENT}`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);
    } catch (error) {
      message.error("Oops. Something went wrong");
      console.error("Update amount field error:", error);
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      width={650}
      footer={false}
      className="rounded"
      centered
    >
      <div className="PaymentFormModal-box1">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Make Reciept" key="1">
            <br />
            <div className="PaymentFormModal-txt1">Make Reciept </div>
            <br />
            <div className="d-flex justify-content-between align-items-center">
              <div className="sales-report-box2 blue">
                <p className="sales-report-txt1">TOTAL:</p>
                <p className="sales-report-txt2">{totalAmount}</p>
              </div>

              <div className="sales-report-box2 green">
                <p className="sales-report-txt1">PAID: </p>
                <p className="sales-report-txt2">{paidAmount}</p>
              </div>

              <div className="sales-report-box2 red">
                <p className="sales-report-txt1">BALANCE: </p>
                <p className="sales-report-txt2">{balance}</p>
              </div>
            </div>

            <br />
            <div>
              <Form
                ref={formRef}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
              >
                <div className="PaymentFormModal-txt2">Reciept Date</div>
                <Form.Item
                  name="payment_date"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker placeholder="Pick a date" className="w-100" />
                </Form.Item>

                <div className="PaymentFormModal-txt2">Account</div>
                <Form.Item
                  name="account_no"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      const accountNo = option.value.toLowerCase();
                      const accountName = option.children
                        .toString()
                        .toLowerCase();
                      return (
                        accountNo.includes(input.toLowerCase()) ||
                        accountName.includes(input.toLowerCase())
                      );
                    }}
                    className="w-100"
                    placeholder="Select Account"
                  >
                    {props?.accounts?.map((item) => (
                      <Select.Option
                        key={item.account_no}
                        value={item.account_no}
                      >
                        {item.account_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <div className="PaymentFormModal-txt2">Amount</div>
                <Form.Item
                  name="amount"
                  initialValue={totalAmount}
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input disabled />
                </Form.Item>

                <div className="PaymentFormModal-txt2">Remarks</div>
                <Form.Item name="note">
                  <Input />
                </Form.Item>
                <Row>
                  <Col sm="6" xs="12">
                    <Button block onClick={() => props.onCancel()} size="large">
                      Close
                    </Button>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item>
                      <Button
                        type="primary"
                        block
                        size="large"
                        htmlType="submit"
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};

export default SalespaymentModal;
