// import React, { useState } from "react";
// import {
//   Drawer,
//   Form,
//   Button,
//   Input,
//   Switch,
//   message,
//   Select,
//   notification,
// } from "antd";
// import { Container, Row, Col } from "react-bootstrap";
// import { API, Headers } from "../../Config";
// import axios from "axios";
// var width = window.innerWidth;
// var innerwidth = window.innerWidth <= 760;
// const { Option } = Select;
// function FormModal(props) {
//   const [loading, setLoading] = useState(false);
//   const onFinish = (values) => {
//     setLoading(true);
//     let obj = {
//       name: values.name,
//       email: values.email,
//       user_name: values.user_name,
//       password: values.password,
//       mobile: values.mobile,
//       branch_id: parseInt(values.branch_id),
//       country: Number(values.country),
//       address: values.address,
//       role: values.role,
//       active: values.active,
//     };
//     let URL =
//       // props.mode === "post" ? API.REGISTER : API.USERS_ACTIONS + props.item.id;
//       props.mode === "post"
//         ? API.REGISTER
//         : API.NEW_USER_UPDATE + props.item.id;

//     axios({
//       method: props.mode,
//       url: API.BASR_URL + URL,
//       headers: Headers(),
//       data: obj,
//     })
//       .then(function (response) {
//         setLoading(false);
//         if ([200, 201].includes(response.status)) {
//           message.success("Success");
//           props.onchange();
//         }
//       })
//       .catch(function (error) {
//         setLoading(false);
//         message.error("Oops.Something went wrong");
//         console.log(error);
//         error.response.data.message &&
//           error?.response?.data?.message.map((item) => {
//             notification.open({
//               message: "ERROR",
//               description: `${item}`,
//             });
//           });
//       });
//   };

//   return (
//     <Drawer
//       placement={props.mode === "post" ? "right" : "left"}
//       title={
//         <span className="txt4">
//           {props.mode === "post" ? "New" : "Update"} Users
//         </span>
//       }
//       width={innerwidth ? width : width / 2.5}
//       onClose={() => props.onClose()}
//       visible={props.visible}
//       bodyStyle={{ paddingBottom: 80 }}
//     >
//       <Form
//         layout="vertical"
//         hideRequiredMark
//         onFinish={onFinish}
//         initialValues={{
//           name: props.item && props.item.name,
//           user_name: props.item && props.item.user_name,
//           // password: props.item && props.item.password,
//           email: props.item && props.item.email,
//           mobile: props.item && props.item.mobile,
//           branch_id: props.item && props.item.branch_id,
//           role:
//             props.item &&
//             props.item?.user_roles &&
//             props.item.user_roles?.map((item) => {
//               return item?.roleId;
//             }),
//           country: props.item && props.item.country?.toString(),
//           place: props.item && props.item.place,
//           address: props.item && props.item.address,

//           active:
//             props.item && props.item.active == false
//               ? false
//               : props.item.active,
//         }}
//       >
//         <Container>
//           <Row>
//             <Col sm="6" xs="12">
//               <Form.Item
//                 name="name"
//                 label="Name"
//                 rules={[{ required: true, message: "required" }]}
//               >
//                 <Input placeholder="Enter Name" />
//               </Form.Item>
//             </Col>

//             <Col sm="6" xs="12">
//               <Form.Item
//                 name="email"
//                 label="Email"
//                 rules={[{ required: true, message: "required" }]}
//               >
//                 <Input placeholder="Enter email" />
//               </Form.Item>
//             </Col>
//             <Col sm="6" xs="12">
//               <Form.Item
//                 name="mobile"
//                 label="Mobile"
//                 rules={[{ required: true, message: "required" }]}
//               >
//                 <Input type="number" placeholder="Enter mobile" />
//               </Form.Item>
//             </Col>
//             <Col sm="6" xs="12">
//               <Form.Item
//                 name="country"
//                 label="Countrys"
//                 rules={[{ required: true, message: "required" }]}
//               >
//                 <Select
//                   placeholder="Select Country"
//                   showSearch
//                   filterOption={(input, option) =>
//                     option.children
//                       .toLowerCase()
//                       .indexOf(input.toLowerCase()) >= 0
//                   }
//                 >
//                   {props.countries.map((option) => (
//                     <Option key={option.code}>{option.name}</Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>

//             <Col sm="12" xs="12">
//               <Form.Item
//                 name="address"
//                 label="Address"
//                 rules={[{ required: true, message: "required" }]}
//               >
//                 <Input.TextArea rows={2} placeholder="Enter Address" />
//               </Form.Item>
//             </Col>
//             <Col sm="6" xs="12">
//               <Form.Item
//                 name="branch_id"
//                 label="Branch"
//                 rules={[{ required: true, message: "required" }]}
//               >
//                 <Select
//                   placeholder=""
//                   showSearch
//                   filterOption={(input, option) =>
//                     option.children
//                       .toLowerCase()
//                       .indexOf(input.toLowerCase()) >= 0
//                   }
//                 >
//                   {props.branches.map((option) => (
//                     <Option key={option.id} value={option.id}>
//                       {option.name}
//                     </Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col sm="6" xs="12">
//               <Form.Item
//                 name="user_name"
//                 label="User Name"
//                 rules={[{ required: true, message: "required" }]}
//               >
//                 <Input placeholder="Enter user name  " />
//               </Form.Item>
//             </Col>
//             <Col
//               sm="6"
//               xs="12"
//               style={{ display: props.mode === "post" ? "" : "none" }}
//             >
//               <Form.Item
//                 name="password"
//                 label="Password"
//                 rules={[
//                   { required: true, message: "required" },
//                   {
//                     min: 6,
//                     message: "Password must be at least 6 characters!",
//                   },
//                 ]}
//               >
//                 <Input.Password placeholder="Enter password" />
//               </Form.Item>
//             </Col>
//             <Col
//               sm="6"
//               xs="12"
//               style={{ display: props.mode === "post" ? "" : "none" }}
//             >
//               <Form.Item
//                 name="con_password"
//                 label="Confirm password"
//                 rules={[
//                   { required: true, message: "required" },
//                   {
//                     min: 6,
//                     message: "Password must be at least 6 characters!",
//                   },
//                   ({ getFieldValue }) => ({
//                     validator(_, value) {
//                       if (!value || getFieldValue("password") === value) {
//                         return Promise.resolve();
//                       }
//                       return Promise.reject(
//                         new Error(
//                           "The two passwords that you entered do not match!"
//                         )
//                       );
//                     },
//                   }),
//                 ]}
//               >
//                 <Input.Password placeholder="Enter password" />
//               </Form.Item>
//             </Col>

//             <Col sm="6" xs="12">
//               <Form.Item
//                 name="role"
//                 label="Role"
//                 rules={[
//                   {
//                     required: props.item && props.item.role ? false : true,
//                     message: "required",
//                   },
//                 ]}
//               >
//                 <Select
//                   mode="tags"
//                   placeholder=""
//                   showSearch
//                   filterOption={(input, option) =>
//                     option.children
//                       .toLowerCase()
//                       .indexOf(input.toLowerCase()) >= 0
//                   }
//                 >
//                   {props.role.map((option) => (
//                     <Option key={option.id} value={option.id}>
//                       {option.name}
//                     </Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>

//             <Col sm="6" xs="12">
//               <Form.Item
//                 label="Active"
//                 name="active"
//                 rules={[{ required: true, message: "required" }]}
//                 valuePropName={
//                   props.item.active == true ? "checked" : "uncheked"
//                 }
//               >
//                 <Switch />
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row>
//             {props.mode === "post" ? null : <Col sm="6" xs="12"></Col>}
//             <Col sm="6" xs="12">
//               <Row>
//                 <Col sm="6" xs="12">
//                   <Button onClick={() => props.onClose()} block>
//                     Cancel
//                   </Button>
//                 </Col>
//                 <Col sm="6" xs="12">
//                   <Button
//                     type="primary"
//                     htmlType="submit"
//                     loading={loading}
//                     block
//                   >
//                     Submit
//                   </Button>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//         </Container>
//       </Form>
//     </Drawer>
//   );
// }
// export default FormModal;

import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  Switch,
  message,
  Select,
  notification,
} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";

const { Option } = Select;

function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    let obj = {
      name: values.name,
      email: values.email,
      user_name: String(values.user_name),
      password: values.password,
      mobile: values.mobile,
      branch_id: parseInt(values.branch_id),
      country: Number(values.country),
      address: values.address,
      role: values.role,
      active: values.active,
    };

    let URL =
      props.mode === "post"
        ? API.REGISTER
        : API.NEW_USER_UPDATE + props.item.id;

    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          props.onchange();
          notification.success({
            message: props.mode === "put" ? "User Updated" : "User Created",
            description:
              props.mode === "put"
                ? "The user details have been successfully updated."
                : "The new user has been successfully created.",
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops. Something went wrong");
        console.error("Error: ", error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          error.response.data.message.forEach((item) => {
            notification.error({
              message: "ERROR",
              description: item,
            });
          });
        } else {
          notification.error({
            message: props.mode === "put" ? "Update Failed" : "Creation Failed",
            description:
              props.mode === "put"
                ? "The user details could not be updated."
                : "The new user could not be created.",
          });
        }
      });
  };

  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Users
        </span>
      }
      width={
        window.innerWidth <= 760 ? window.innerWidth : window.innerWidth / 2.5
      }
      onClose={props.onClose}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          name: props.item?.name,
          user_name: props.item?.user_name,
          email: props.item?.email,
          mobile: props.item?.mobile,
          branch_id: props.item?.branch_id,
          role: props.item?.user_roles?.map((item) => item?.roleId),
          country: props.item?.country?.toString(),
          place: props.item?.place,
          address: props.item?.address,
          active: props.item?.active === false ? false : props.item?.active,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[{ required: true, message: "required" }]}
              >
                <Input type="number" placeholder="Enter mobile" />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="country"
                label="Country"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  placeholder="Select Country"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.countries.map((option) => (
                    <Option key={option.code}>{option.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm="12" xs="12">
              <Form.Item
                name="address"
                label="Address"
                rules={[{ required: true, message: "required" }]}
              >
                <Input.TextArea rows={2} placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="branch_id"
                label="Branch"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  placeholder=""
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.branches.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  mode="tags"
                  placeholder=""
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {props.role.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {props.mode === "post" && (
              <>
                <Col sm="6" xs="12">
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      { required: true, message: "required" },
                      {
                        min: 6,
                        message: "Password must be at least 6 characters!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter password" />
                  </Form.Item>
                </Col>
                <Col sm="6" xs="12">
                  <Form.Item
                    name="con_password"
                    label="Confirm password"
                    rules={[
                      { required: true, message: "required" },
                      {
                        min: 6,
                        message: "Password must be at least 6 characters!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Enter password" />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col sm="6" xs="12">
              <Form.Item
                label="Active"
                name="active"
                rules={[{ required: true, message: "required" }]}
                valuePropName={
                  props.item?.active === true ? "checked" : "unchecked"
                }
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {props.mode !== "post" && <Col sm="6" xs="12"></Col>}
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={props.onClose} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}

export default FormModal;
