import React, { useState } from "react";
import { Drawer, Form, Button, Input, Switch, message, Select, DatePicker } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
const { Option } = Select;
var width = window.innerWidth;
var innerwidth = window.innerWidth <= 760;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      pickup_collections_request_id: values.pickup_collections_request_id,
      customer_id: values.customer_id,
      box_id: values.box_id,
      item_id: values.item_id,
      item_quantity: values.item_quantity,
      status: values.status,
      active: values.active,

    };
    //console.log ('data array '+obj)
    let URL =
      props.mode === "post"
        ? API.BOXE_ITEMS_ACTIONS
        : API.BOXE_ITEMS_ACTIONS + props.item.code;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,

    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Drawer
      placement={props.mode === "post" ? "right" : "left"}
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Boxe Items
        </span>
      }
      width={innerwidth ? width : width / 2.7}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        initialValues={{
          pickup_collections_request_id: props.item && props.item.pickup_collections_request_id,
          customer_id: props.item && props.item.customer_id,
          box_id: props.item && props.item.box_id,
          item_id: props.item && props.item.item_id,
          item_quantity: props.item && props.item.item_quantity, 
          status: props.item && props.item.status,
          active: props.item && props.item.active ? props.item.active : false,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="pickup_collections_request_id"
                label="Pickup Colln ID"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Select  Pickup Colln Request ID  " />
              </Form.Item>
              <Form.Item
                name="box_id"
                label="Box Id"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Box Id" />
              </Form.Item>
              <Form.Item
                name="item_quantity"
                label="Item Quantity"
                rules={[{ required: true, message: "required" }]}
              >
                  <Input placeholder="Enter item quantity" />
              </Form.Item>
           
              <Form.Item
                label="Active"
                name="active"
                rules={[{ required: true, message: "required" }]}
                valuePropName={props.item.active ? "checked" : "uncheked"}
              >
                <Switch />
              </Form.Item>
            </Col>


            <Col sm="6" xs="12">
              <Form.Item
                name="customer_id"
                label="customer ID"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Contact Number" />
              </Form.Item>
              <Form.Item
                name="item_id"
                label="Item Id"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter item_id" />
              </Form.Item>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: false, message: "required" }]}
              >
                <Input placeholder="Select Status" />

              </Form.Item>
            </Col>
          </Row>
          <Row>
            {props.mode === "post" ? null : <Col sm="6" xs="12"></Col>}
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;
