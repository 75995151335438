import { Button, Dropdown, Menu } from "antd";
import { MdAddCircleOutline } from "react-icons/md";
import { RiArrowLeftCircleLine } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
import { CgSortAz } from "react-icons/cg";
import { GoPackage } from "react-icons/go";
import { ImBoxAdd } from "react-icons/im";
import { BsSortAlphaDownAlt, BsSortAlphaUpAlt } from "react-icons/bs";
import { withRouter, useHistory } from "react-router-dom";
import { Input } from "antd";
import LinearProgress from "@mui/material/LinearProgress";
// let width = window.innerWidth;
function PageHeader(props) {
  let history = useHistory();
  const { Search } = Input;

  return (
    <>
      <div className="PageHeader">
        <div className="PageHeader-box1">
          <div onClick={() => history.goBack()}>
            <div className="arrowBack">
              <RiArrowLeftCircleLine style={{ fontSize: 25, margin: 8 }} />
            </div>
          </div>
          <div className="nameScrum">
            <div className="pageName">{props.PageName}</div>
            <div className="devider-ver"></div>
            <div className="PageHeader-txt2">{props.breadScrum}</div>
          </div>
        </div>
        <div style={{ flex: 1 }} />
        <div
          className={
            (props.RightButton || props.FilterButton) && props.SearchBar
              ? "PageHeader-box3"
              : "PageHeader-box2"
          }
        >
          {props.SearchBar ? (
            <div>
              <Input
                placeholder=""
                autoFocus
                className="PageHeader-Search"
                onPressEnter={(val) => props.SearchBar(val.target.value)}
                suffix={<AiOutlineSearch style={{ color: "#1e88e5" }} />}
              />
              {/* <Search
                placeholder=""
                onSearch={(val) => props.SearchBar(val)}
                enterButton={props.RightButton ? false : true}
                bordered={false}
                className="PageHeader-Search"
                autoFocus
              /> */}
            </div>
          ) : null}
          <div style={{ flex: 1, marginInline: 5 }} />
          {props.RightButton ? (
            <Button
              type="primary"
              onClick={() => props.RightClcik()}
              icon={
                props.RightButton === "Assign" ? null : (
                  <MdAddCircleOutline size={20} style={{ marginRight: 10 }} />
                )
              }
            >
              {props.RightButton}
            </Button>
          ) : null}

          {props.FilterButton ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={(val) => props.FilterButton("Latest", val)}
                    key={1}
                    icon={<BsSortAlphaDownAlt />}
                  >
                    Latest First
                  </Menu.Item>
                  <Menu.Item
                    onClick={(val) => props.FilterButton("Updated", val)}
                    key={2}
                    icon={<BsSortAlphaUpAlt />}
                  >
                    Updated First
                  </Menu.Item>
                  <Menu.Item
                    onClick={(val) => props.FilterButton("Packed", val)}
                    key={2}
                    icon={<GoPackage />}
                  >
                    Packed
                  </Menu.Item>
                  <Menu.Item
                    onClick={(val) => props.FilterButton("Recieved", val)}
                    key={2}
                    icon={<ImBoxAdd />}
                  >
                    Received
                  </Menu.Item>
                </Menu>
              }
            >
              <Button style={{ borderRadius: "8px" }}>
                Sort By <CgSortAz />
              </Button>
            </Dropdown>
          ) : null}
        </div>
      </div>
      {props.loader ? (
        <div style={{ marginTop: -18, marginBottom: 15 }}>
          <LinearProgress color={"primary"} style={{ height: 2 }} />
        </div>
      ) : null}
    </>
  );
}
export default withRouter(PageHeader);
