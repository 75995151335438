import { Table, Pagination, Button, Popconfirm } from "antd";
const data = [];

function DataTable(props) {
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Branch", dataIndex: "branch", key: "branch" },
    {
      title: "Action",
      render: (item) => (
        <div>
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small">Delete</Button>
          </Popconfirm>
          <Button size="small" onClick={() => props.update(item)}>
            Edit
          </Button>
          <Button size="small" type="primary">
            More
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ backgroundColor: "#fff" }}>
              <p style={{ margin: 0 }}>{record.description}</p>
            </div>
          ),
        }}
        dataSource={data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={1}
          total={10}
          onChange={props.PageOnchange}
        />
      </div>
    </div>
  );
}
export default DataTable;
