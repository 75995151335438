import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import PageHeader from "../../Routes/PageHeader";
import DataTable from "./Datatable";
import PrintModal from "./PrintModal";

function AwbtoXrayTransactions() {
  const [loading, setLoading] = useState(false);
  const [indicator, setIndicator] = useState(false);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});
  const [print, setPrint] = useState(false);

  useEffect(() => {
    loaddata(page, take);
  }, []); 

  const loaddata = (p, t) => {
    setLoading(true);
    axios
      .get(
        API.BASR_URL + `XrayAgentTransaction?order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const printItem = (items) => {
    setItem(items);
    setPrint(true);
  };

  return (
    <div>
      <PageHeader
        PageName={"Awb to Xray Trans"}
        breadScrum={"Admin /Awb to Xray Transactions"}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <DataTable
          meta={meta}
          data={data}
          printItem={(items) => printItem(items)}
          PageOnchange={(page, take) => pagination(page, take)}
        />
      )}
      {print ? (
        <PrintModal
          item={item}
          visible={print}
          onCancel={() => setPrint(false)}
        />
      ) : null}
    </div>
  );
}

export default AwbtoXrayTransactions;
