import React, { useState } from "react";
import { Drawer, Form, Button, Input, Select, Radio, Space, message} from "antd";
import { Container, Row, Col } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { API, Headers } from "../../Config";
import { Store } from "../../Redux/Store";
import axios from "axios";

const { Option } = Select;
function FormModal(props) {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    console.log("Store.getState().Auth.user:", Store.getState().Auth.user);
    
    setLoading(true);
    console.log("Success:", values);
    let payload = (({ vehicle_number, license_number, branch }) => ({ vehicle_number, license_number, branch }))(values);
    payload['active'] = true;
    payload['userid'] = Store.getState().Auth.user.id;
    payload['branch'] = parseInt(values.branch);
    let URL = props.mode === "post"
        ? API.DRIVERS_ACTION
        : API.DRIVERS_ACTION + props.item.id;
    
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: payload,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Drawer
      placement="right"
      title={<span className="txt4">{props.mode} Driver</span>}
      width={720}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form layout="vertical" 
        hideRequiredMark 
        onFinish={onFinish}
        initialValues={{
          name: props.item && props.item.name,
          contact: props.item && props.item.contact,
          branch: props.item && props.item.branch,
          license_number: props.item && props.item.license_number,
          username: props.item && props.item.username,
          password: props.item && props.item.password,
          confirm_password: props.item && props.item.confirm_password,
          vehicle_number: props.item && props.item.vehicle_number,

        }}>
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>

              <Form.Item name="contact" label="contact Number">
                <Input placeholder="Enter Number" />
              </Form.Item>
              <Form.Item
              name="branch_id"
              label="Branch"
              rules={[{ required: true, message: "required" }]}
            >
              <Select placeholder="Select Branch">
                {props.branches.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
              <Form.Item name="license_number" label="Liscense Number">
                <Input placeholder="Enter Number" />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item name="username" label="Username">
                <Input placeholder="Enter username" />
              </Form.Item>
              <Form.Item name="password" label="Password">
                <Input.Password placeholder="Enter Password" />
              </Form.Item>
              <Form.Item name="confirm_password" label="Confirm Password">
                <Input.Password placeholder="Enter Password" />
              </Form.Item>
              <Form.Item name="vehicle_number" label="Vehicle Number">
                <Input placeholder="Enter Number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Type">
            <Radio.Group>
              <Radio value="a">Pickup</Radio>
              <Radio value="b">Delivery</Radio>
              <Radio value="c">Process</Radio>
            </Radio.Group>
          </Form.Item>
          <Space>
            <Button onClick={() => props.onClose()}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              {loading ? (
                <CircularProgress size={20} style={{ color: "#fff" }} />
              ) : (
                "Submit"
              )}
            </Button>
          </Space>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;
