import React, { useState, useEffect } from "react";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
const AirwayBillPayment = () => {
  const Auth = useSelector((state) => state.Auth.user);
  const { page_mode } = useParams();
  console.log(page_mode);
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [mode, setMode] = useState("post");
  const [head, setHead] = useState("");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [awbbill, setAwbbill] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [columnsVisible, setColumnsVisible] = useState({});

  const [inbound, setInbound] = useState([]);

  useEffect(() => {
    loaddata(page, take);
    AccountsDropdown();
    AwbBillsDropdown();
  }, []);

  const loaddata = (p, t) => {
    axios
      .get(
        API.BASR_URL + API.AWB_PAYMENT_LIST + `?order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };
  const Search = (obj) => {
    console.log("values", obj);

    let URL =
      API.BASR_URL +
      API.AWB_PAYMENT_ACTIONS +
      `awb/search?order=DESC&page=${page}&take=${take}`;
    axios({
      method: "post",
      url: URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          let data = response.data;
          message.success("Success");
          setData(data.data);
          setMeta(data.meta);
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong save");
      });
  };
  const AccountsDropdown = () => {
    axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/child_accounts`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setAccounts(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };
  const AwbBillsDropdown = () => {
    axios
      .get(API.BASR_URL + API.AIRWAY_BILLS_GEN + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) {
          console.log("123212321321312321321321", res.data);
          setAwbbill(res.data);
        }
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };
  const filterAwb = (value) => {
    console.log("selectedValue-------", value);
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
    AwbBillsDropdown();
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    setform(true);
  };

  const DeleteItem = (item) => {
    setIndicator(true);
    axios
      .delete(API.BASR_URL + API.AWB_PAYMENT_ACTIONS + item.id, {
        headers: Headers(),
      })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };
  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;

    console.log("Selected Value111111111111:", selectedValue);
    // if (selectedValue === "inbound") {
    //   setInbound([]);

    //   const InboundTrip = awbbill.filter(
    //     (data) =>
    //       data.received_users &&
    //       data?.received_users?.branch?.id === Auth.branch_id
    //   );

    //   console.log("Selected Value111111111111:", InboundTrip);
    //   setInbound(InboundTrip);
    // } else if (selectedValue === "outbound") {
    //   setInbound([]);
    //   const OutBoundTrip = awbbill.filter(
    //     (data) => data.users && data.users?.branch?.id === Auth.branch_id
    //   );
    //   console.log("Selected Value222222222222:", OutBoundTrip);
    //   setInbound(OutBoundTrip);
    // } else if (selectedValue === "pending") {
    //   setInbound([]);
    //   const PendingTrip = awbbill.filter((data) => data.awb_status === "start");
    //   console.log("Selected Value33333333333:", PendingTrip);
    //   setInbound(PendingTrip);
    // }
    // Perform any additional logic or update state based on the selected value
  };

  return (
    <div>
      <PageHeader
        PageName={head}
        breadScrum={`Admin / Payment`}
        RightButton={`New / Payment`}
        RightClcik={() => AddItem()}
        loader={indicator}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            Search={(obj) => Search(obj)}
            columnsVisible={columnsVisible}
            meta={meta}
            data={data}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
          {form ? (
            <FormModal
              handleRadioChange={(val) => handleRadioChange(val)}
              accounts={accounts}
              awbbill={awbbill}
              item={item}
              mode={mode}
              visible={form}
              columnsVisible={columnsVisible}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
export default AirwayBillPayment;
