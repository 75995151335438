import { Pagination, Table, Tooltip, Input, Button } from "antd";
import React, { useState } from "react";
import { AiOutlineFilter, AiOutlinePrinter } from "react-icons/ai";
import moment from "moment";
const DataTable = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const onSelectChange = (newSelectedRowKeys, rowDetails) => {
    props.onSelect(rowDetails);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          size="small"
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ backgroundColor: "transparent" }}>
        <AiOutlineFilter />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Created on",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-table-item">
              {moment(text).format("DD-M-YYYY")}
            </div>
          </div>
        </Tooltip>
      ),
      width: 100,
    },
    {
      title: "Owner",
      dataIndex: "users",
      key: "users",
      ...getColumnSearchProps("user"),

      render: (text, record, index) => {
        return (
          <Tooltip title={record.name && record.name}>
            <div
              className="airwaybill-table-colomn"
              onClick={() => onSelectChange([record.id], [record])}
            >
              <div className="airwaybill-table-item">
                {record.users && record.users.name && record.users.name}
              </div>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Document Date",
      dataIndex: "document_date",
      key: "document_date",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-table-item">
              {moment(text).format("DD-M-YYYY")}
            </div>
          </div>
        </Tooltip>
      ),
      width: 100,
    },
    {
      title: "Pcs K/L",
      dataIndex: "psc",
      key: "psc",
      render: (text, record, index) => (
        <Tooltip
          title={`Pieces: ${record.psc && record.psc} Chargeable Weight: ${
            record.chargable_weight && record.chargable_weight
          } K`}
        >
          <div
            className="airwaybill-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-table-item">{`${
              record.psc && record.psc
            } - ${record.chargable_weight && record.chargable_weight} K`}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Print",
      dataIndex: "is_print",
      key: "is_print",
      render: (text, record, index) => (
        <div onClick={() => onSelectChange([record.id], [record])}>
          {text ? <AiOutlinePrinter /> : null}
        </div>
      ),
    },
    {
      title: "Number",
      dataIndex: "awb_no",
      key: "awb_no",
      ...getColumnSearchProps("awb_no"),
      sorter: (a, b) => a.awb_no.length - b.awb_no.length,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-table-item">
              {`${record.airline && record.airline}-${
                record.awb_no && record.awb_no
              }`}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Referance",
      dataIndex: "reference",
      key: "reference",
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Origin/Destination",
      dataIndex: "destination_airport_name",
      key: "destination_airport_name",
      ...getColumnSearchProps("destination_airport_name"),
      render: (text, record, index) => (
        <Tooltip
          title={`${
            record.orijin_airport_name && record.orijin_airport_name
          } /  ${
            record.destination_airport_name && record.destination_airport_name
          }`}
        >
          <div
            className="airwaybill-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div
              className="airwaybill-table-item"
              style={{ textTransform: "uppercase" }}
            >
              {record.orijin_airport_name && record.orijin_airport_name} /{" "}
              {record.destination_airport_name &&
                record.destination_airport_name}
            </div>
          </div>
        </Tooltip>
      ),
      width: 250,
    },
    {
      title: "Shipper",
      dataIndex: "shipper",
      key: "shipper",
      ...getColumnSearchProps("shipper"),
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Consignee",
      dataIndex: "cnee",
      key: "cnee",
      ...getColumnSearchProps("cnee"),
      render: (text, record, index) => (
        <Tooltip title={text}>
          <div
            className="airwaybill-table-colomn"
            onClick={() => onSelectChange([record.id], [record])}
          >
            <div className="airwaybill-table-item">{text}</div>
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        size="small"
        rowSelection={{ type: "radio", ...rowSelection }}
        columns={columns}
        dataSource={props.data}
        pagination={false}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
      />
      <br />
      <div align="right">
        <Pagination
          size="small"
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
};

export default DataTable;
