import React from "react";
import { Table, Tag, Button } from "antd";
import { GoVerified } from "react-icons/go";
function DataTable(props) {
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="data-table-items">
          {record.name && record.active === false && record.name}
        </div>
      ),
    },
    {
      title: "BRANCH",
      dataIndex: "branch_id",
      key: "branch_id",
      render: (text, record) => (
        <div className="data-table-items">
          {record.branch && record.active === false && record.branch.name}
        </div>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <div className="data-table-items">
          {record.email && record.active === false && record.email}
        </div>
      ),
    },
    {
      title: "PHONE",
      dataIndex: "mobile",
      key: "mobile",
      render: (text, record) => (
        <div className="data-table-items">
          {record.mobile && record.active === false && record.mobile}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "active",
      key: "active",
      render: (text, record) => (
        <div className="data-table-items">
          <Tag color={record.active ? "green" : "red"}>NOT verified</Tag>
        </div>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="data-table-items">
          <Button
            type="primary"
            size="small"
            icon={<GoVerified style={{ fontSize: "12px", marginRight: 5 }} />}
            onClick={() => props.verify(record.id)}
          >
            Verify
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={props.data} />
    </div>
  );
}

export default DataTable;
