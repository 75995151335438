// import axios from "axios";
// export default function CurrancyRate(from, to) {
//   const options = {
//     method: "GET",
//     url: "https://currency-exchange.p.rapidapi.com/exchange",
//     params: { from: from, to: to, q: "1.0" },
//     headers: {
//       "X-RapidAPI-Key": "a0c15e989cmsh8900771a112fce8p1f8a80jsn4deacf6db233",
//       "X-RapidAPI-Host": "currency-exchange.p.rapidapi.com",
//     },
//   };
//   return axios
//     .request(options)
//     .then(function (response) {
//       return response.data;
//     })
//     .catch(function (error) {
//       console.error(error);
//     });
// }

const axios = require("axios");

export default async function CurrancyRate(from, to) {
  const options = {
    method: "GET",
    url: "https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert",
    params: {
      from: from,
      to: to,
      amount: "1.0",
    },
    headers: {
      "X-RapidAPI-Key": "8393c2e9famsh80b3bc835fb48dap1eed69jsn03e40ab1594f",
      "X-RapidAPI-Host":
        "currency-conversion-and-exchange-rates.p.rapidapi.com",
    },
  };

  try {
    const response = await axios.request(options);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
