import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { Image } from "antd";

const TrackBoxDetails = (props) => {
  return (
    <div>
      <div>
        <b>{props?.data?.box_number} - Box Details</b>
      </div>
      <hr />
      <Row>
        <Col md="5">
          <div className="track-txt1">
            Chargable Weight : <b>{props?.data?.chargable_weight} KG</b>
          </div>

          <div className="track-txt1">
            Created Date : <b>{moment(props?.data?.createdAt).format("lll")}</b>
          </div>

          <div className="track-txt1">
            Created At : <b>{props?.data?.created_user?.branch?.name}</b>
          </div>

          <div className="track-txt1">
            Created By : <b>{props?.data?.created_user?.name}</b>
          </div>

          <div className="track-txt1">
            Total Charge : <b>{props?.data?.total_charge}</b>
          </div>

          <div className="track-txt1">
            Dimension :
            <b>
              {props?.data?.length} * {props?.data?.breadth} *{" "}
              {props?.data?.height}
            </b>
          </div>

          <div className="track-txt1">
            Total Items : <b>{props?.data?.boxe_item?.length}</b>
          </div>
        </Col>

        <Col md="7">
          <div className="track-txt1">
            <b>Delivery Address</b>
          </div>
          <div className="track-txt1">{props?.data?.adhar_address}</div>
          <div className="track-txt1">
            {props?.data?.district}, {props?.data?.state}
          </div>
          <div className="track-txt1">{props?.data?.country}</div>
          <div className="track-txt1">{props?.data?.pin}</div>
          <div className="track-txt1">
            Phone: <b>{props?.data?.reciver_mobile1}</b>
          </div>
          <div className="track-txt1">
            Alt. Phone: <b>{props?.data?.reciver_mobile2}</b>
          </div>
        </Col>
      </Row>
      <hr />
      <br />
      <div>
        <b>- Box Images</b>
      </div>
      <Row>
        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
          {props?.data?.image_1 && (
            <Image width={100} src={props?.data?.image_1} />
          )}
          {props?.data?.image_2 && (
            <Image width={100} src={props?.data?.image_2} />
          )}
          {props?.data?.image_3 && (
            <Image width={100} src={props?.data?.image_3} />
          )}

          {props?.data?.image_4 && (
            <Image width={100} src={props?.data?.image_4} />
          )}
          {props?.data?.image_5 && (
            <Image width={100} src={props?.data?.image_5} />
          )}
          {props?.data?.image_6 && (
            <Image width={100} src={props?.data?.image_6} />
          )}
        </div>
      </Row>
      <hr />
      <br />
      <div>
        <b>- KYC Data</b>
      </div>
      <Row>
        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
          {props?.data?.image_1 && (
            <Image width={100} src={props?.data?.adhar} />
          )}
          {props?.data?.image_2 && (
            <Image width={100} src={props?.data?.adhar_back} />
          )}
          {props?.data?.image_3 && (
            <Image width={100} src={props?.data?.iqama} />
          )}
        </div>
      </Row>

      <hr />
      <br />
    </div>
  );
};

export default TrackBoxDetails;
