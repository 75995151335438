import { useState } from "react";
import {
  Table,
  Pagination,
  Button,
  Popconfirm,
  Popover,
  Input,
  Progress,
} from "antd";
import moment from "moment";
import { MdOutlineDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import { AiOutlineFilter, AiOutlineIdcard } from "react-icons/ai";
import { AiOutlineMobile } from "react-icons/ai";
import { FaInternetExplorer } from "react-icons/fa";

import { Row } from "react-bootstrap";
import CashRcptModal from "./CashRcptModal";

import done from "../../Assets/Images/done.svg";
import error from "../../Assets/Images/error.svg";
import print from "../../Assets/Images/print.svg";

import barcode from "../../Assets/Images/barcode.png";
import boxbill from "../../Assets/Images/box.png";
import customer from "../../Assets/Images/customer.png";
import customs from "../../Assets/Images/customs.png";
import invoice from "../../Assets/Images/invoice.png";
import tax from "../../Assets/Images/tax.png";

import styles from "./styles";

window.Buffer = window.Buffer || require("buffer").Buffer;

function DataTable(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  let getPercentage = (obj) => {
    let BoxObj = {
      iqama_name: obj.iqama_name,
      iqama_number: obj.iqama_number,
      address: obj.address,
      sender_mobile: obj.sender_mobile,
      reciver_mobile1: obj.reciver_mobile1,
      adhar_no: obj.adhar_no,
      adhar_name: obj.adhar_name,
      adhar_address: obj.adhar_address,
      pin: obj.pin,
      state: obj.state,
      district: obj.district,
      country: obj.country,
      adhar: obj.adhar,
      adhar_back: obj.adhar_back,
      iqama: obj.iqama,
      signature: obj.signature,
    };
    let Keys = Object.keys(BoxObj);
    let valuesArr = Keys.map((key) => {
      return BoxObj[key];
    });
    let lengthArr = valuesArr.length;
    let nonFilledCount = 0;
    valuesArr.forEach((value) => {
      if (value == null || value == "NULL") nonFilledCount++;
    });
    let Totalpercentage = 100 - (nonFilledCount / lengthArr) * 100;
    return Math.round(Totalpercentage);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="middle"
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <div style={{ margin: 5 }} />
        <Button onClick={() => handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          type="primary"
          size="small"
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <div style={{ backgroundColor: "transparent" }}>
        <AiOutlineFilter />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <div
          className={
            getPercentage(record) === 100
              ? "boxes-datatable-column updated"
              : "boxes-datatable-column"
          }
        >
          <div>{index + 1}</div>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "created_from",
      render: (text, record, index) => (
        <div>
          {record.collection && record.collection.created_from === "web" ? (
            <FaInternetExplorer
              color={getPercentage(record) === 100 ? "green" : ""}
            />
          ) : (
            <AiOutlineMobile
              color={getPercentage(record) === 100 ? "green" : ""}
            />
          )}{" "}
        </div>
      ),
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div
          className={
            getPercentage(record) === 100
              ? "boxes-datatable-column updated "
              : "boxes-datatable-column"
          }
        >
          {moment(text).format("l")}
        </div>
      ),
    },
    {
      title: <div style={{ fontSize: "11.8px" }}>Box Number</div>,
      dataIndex: "box_number",
      key: "box_number",
      width: "10%",
      ...getColumnSearchProps("box_number"),
      sorter: (a, b) => a.box_number.length - b.box_number.length,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => (
        <div
          className={
            getPercentage(record) === 100
              ? "boxes-datatable-column updated"
              : "boxes-datatable-column"
          }
        >
          <div>{text}</div>
        </div>
      ),
    },

    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text, record, index) => (
        <div
          className={
            getPercentage(record) === 100
              ? "boxes-datatable-column updated "
              : "boxes-datatable-column"
          }
          style={{ maxWidth: "80px" }}
        >
          <div className="boxes-table-item">
            {record.created_user && record.created_user.name}
          </div>
        </div>
      ),
    },

    {
      title: "Service",
      dataIndex: "service_type",
      key: "service_type",
      ...getColumnSearchProps("service_type"),
      sorter: (a, b) =>
        a.service_type &&
        a.service_type.length - b.service_type &&
        b.service_type.length,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => (
        <div
          className={
            getPercentage(record) === 100
              ? "boxes-datatable-column updated "
              : "boxes-datatable-column"
          }
          style={{ maxWidth: "80px" }}
        >
          <div className="boxes-table-item">
            {record.service && record.service.name}
          </div>
        </div>
      ),
    },
    {
      title: "Weight",
      dataIndex: "chargable_weight",
      key: "chargable_weight",
      render: (text, record, index) => (
        <div
          className={
            getPercentage(record) === 100
              ? "boxes-datatable-column updated "
              : "boxes-datatable-column"
          }
        >
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "total_charge",
      key: "total_charge",
      render: (text, record, index) => (
        <div
          className={
            getPercentage(record) === 100
              ? "boxes-datatable-column updated "
              : "boxes-datatable-column"
          }
        >
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: "KYC upload",
      render: (index, record) => {
        return (
          <div className="boxes-datatable-column ">
            <Button
              loading={
                props.uploading &&
                props.currentId === record.id &&
                props.fileType === "adhar"
                  ? true
                  : false
              }
              onClick={() => props.uploadKYC(record, "adhar")}
              size="small"
              style={
                record.is_adhar_uploded
                  ? styles.SucessButton
                  : styles.ErrorButton
              }
              icon={
                record.is_adhar_uploded ? (
                  <img src={done} width={15} />
                ) : (
                  <img src={error} width={15} />
                )
              }
            >
              <span className="uploadBtnSpan">Adhar</span>
            </Button>
            <Button
              loading={
                props.uploading &&
                props.currentId === record.id &&
                props.fileType === "adhar_back"
                  ? true
                  : false
              }
              onClick={() => props.uploadKYC(record, "adhar_back")}
              size="small"
              style={
                record.is_adhar_back_uploded
                  ? styles.SucessButtonMiddle
                  : styles.ErrorButtonMiddle
              }
              icon={
                record.is_adhar_back_uploded ? (
                  <img src={done} width={15} />
                ) : (
                  <img src={error} width={15} />
                )
              }
            >
              Adhar Back
            </Button>
            <Button
              loading={
                props.uploading &&
                props.currentId === record.id &&
                props.fileType === "iqama"
                  ? true
                  : false
              }
              onClick={() => props.uploadKYC(record, "iqama")}
              size="small"
              style={
                record.is_iqama_uploded
                  ? styles.SucessButton
                  : styles.ErrorButton
              }
              icon={
                record.is_iqama_uploded ? (
                  <img src={done} width={15} />
                ) : (
                  <img src={error} width={15} />
                )
              }
            >
              iqama
            </Button>
            <Button
              loading={
                props.uploading &&
                props.currentId === record.id &&
                props.fileType === "signature"
                  ? true
                  : false
              }
              onClick={() => props.uploadKYC(record, "signature")}
              size="small"
              style={
                record.signature ? styles.SucessButton : styles.ErrorButton
              }
              icon={
                record.signature ? (
                  <img src={done} width={15} />
                ) : (
                  <img src={error} width={15} />
                )
              }
            >
              Sign
            </Button>
          </div>
        );
      },
      dataIndex: "",
      key: "",
    },

    {
      title: "KYC data update",
      render: (index, record) => (
        <div>
          <Button
            size="small"
            onClick={() => props.updateKYC(index)}
            style={styles.UpdateKYCButton}
            className="boxes-table-item"
          >
            <span style={{ fontSize: "11px" }}>update</span>
          </Button>
          <Progress
            type="circle"
            percent={getPercentage(record)}
            width={20}
            className="boxes-table-item"
          />
        </div>
      ),
      dataIndex: "",
      key: "",
    },
    // {
    //   title: "Payment Transaction",
    //   render: (item) => (
    //     <div className="boxes-datatable-column ">
    //       <Button
    //         size="small"
    //         style={styles.TransactionButtons}
    //         onClick={() => props.cashRcpt(item)}
    //       >
    //         <span style={{ fontSize: "11px" }}>Cash Reciept</span>
    //       </Button>
    //       <Button
    //         size="small"
    //         style={styles.TransactionButtons}
    //         onClick={() => props.payRcpt(item)}
    //       >
    //         <span style={{ fontSize: "11px" }}>Payment Reciept</span>
    //       </Button>
    //     </div>
    //   ),
    //   dataIndex: "",
    //   key: "",
    // },
    {
      title: "Print",
      render: (item, index) => (
        <div>
          <Popover
            trigger="click"
            style={{ width: 5000 }}
            placement="bottom"
            title={
              <div style={{ display: "flex" }}>
                <div>Print</div>
              </div>
            }
            content={
              <div className="" style={{ width: 190 }}>
                <Row>
                  <Button
                    onClick={() => props.print(item)}
                    icon={<img src={barcode} className="printIcons" />}
                    style={styles.OddPrintButtons}
                  >
                    <span className="printBtnSpan">Barcode Label</span>
                    {item.is_barcode_label_print ? (
                      <img src={done} width={13} />
                    ) : (
                      ""
                    )}
                  </Button>
                </Row>
                <Row>
                  <Button
                    onClick={() => props.invoice(item)}
                    icon={<img src={invoice} className="printIcons" />}
                    style={styles.EvenPrintButtons}
                  >
                    <span className="printBtnSpan">Invoice </span>
                    {item.is_invoice_print ? <img src={done} width={15} /> : ""}
                  </Button>
                </Row>
                <Row>
                  <Button
                    onClick={() => props.custinvoice(item)}
                    style={styles.OddPrintButtons}
                    icon={<img src={customer} className="printIcons" />}
                  >
                    {" "}
                    <span className="printBtnSpan">Customer Invoice </span>
                    {item.is_customer_invoice_print ? (
                      <img src={done} width={15} />
                    ) : (
                      ""
                    )}
                  </Button>
                </Row>

                <Row>
                  <Button
                    onClick={() => props.custinvoice2(item)}
                    style={styles.OddPrintButtons}
                    icon={<img src={customer} className="printIcons" />}
                  >
                    {" "}
                    <span className="printBtnSpan">Customer Invoice - 2</span>
                    {item.is_customer_invoice_print ? (
                      <img src={done} width={15} />
                    ) : (
                      ""
                    )}
                  </Button>
                </Row>

                <Row>
                  <Button
                    onClick={() => props.taxinvoice(item)}
                    icon={<img src={tax} className="printIcons" />}
                    style={styles.EvenPrintButtons}
                  >
                    <span className="printBtnSpan">Tax Invoice </span>
                    {item.is_tax_invoice_print ? (
                      <img src={done} width={15} />
                    ) : (
                      ""
                    )}
                  </Button>
                </Row>
                <Row>
                  <Button
                    onClick={() => props.customs(item)}
                    style={styles.OddPrintButtons}
                    icon={<img src={customs} className="printIcons" />}
                  >
                    <span className="printBtnSpan">For Customs </span>
                    {item.is_for_customer ? <img src={done} width={15} /> : ""}
                  </Button>
                </Row>
                <Row>
                  <Button
                    onClick={() => props.customsB(item)}
                    style={styles.OddPrintButtons}
                    icon={<img src={customs} className="printIcons" />}
                  >
                    <span className="printBtnSpan">For Customs - B</span>
                    {/* {item.is_for_customer ? <img src={done} width={15} /> : ""} */}
                  </Button>
                </Row>
                <Row>
                  <Button
                    onClick={() => props.billp(item)}
                    icon={<img src={boxbill} className="printIcons" />}
                    style={styles.EvenPrintButtons}
                  >
                    <span className="printBtnSpan">Box/Bill Number </span>
                    {item.is_box_bill_number ? (
                      <img src={done} width={15} />
                    ) : (
                      ""
                    )}
                  </Button>
                </Row>
                <Row>
                  <Button
                    onClick={() => props.printKyc(item)}
                    style={styles.OddPrintButtons}
                    icon={
                      <AiOutlineIdcard
                        color="#000"
                        size={20}
                        className="printIcons"
                      />
                    }
                  >
                    KYC
                  </Button>
                </Row>
                <Row>
                  <Button
                    onClick={() => props.downoladtiff(item)}
                    style={styles.OddPrintButtons}
                    icon={<img src={customs} className="printIcons" />}
                  >
                    <span className="printBtnSpan">Download Tiff</span>
                    {item.is_for_customer ? <img src={done} width={15} /> : ""}
                  </Button>
                </Row>
              </div>
            }
          >
            <Button
              size="small"
              style={styles.PrintButton}
              icon={<img src={print} style={{ marginInline: 5, width: 15 }} />}
            >
              Print
            </Button>
          </Popover>
        </div>
      ),
      dataIndex: "",
      key: "",
    },
    {
      title: "Action",
      render: (item) => (
        <div className="tableAction">
          <Popconfirm
            placement="bottomRight"
            title={"Are you sure to delete?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => props.delete(item)}
          >
            <Button size="small" type="link">
              <MdOutlineDelete size={25} />
            </Button>
          </Popconfirm>
          <Button size="small" onClick={() => props.details(item)} type="link">
            <MdOutlineRemoveRedEye size={25} color={"#78909c"} />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
      />
      <br />
      <div align="right">
        <Pagination
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.itemCount}
          pageSize={props.meta && props.meta.take ? props.meta.take : 0}
          onChange={props.PageOnchange}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>
  );
}
export default DataTable;
