import React, { useRef } from "react";
import { Modal, Button } from "antd";
import { Col, Row, Container } from "react-bootstrap";
import logo from "../../Assets/Images/logoNew.jpg";
import ReactToPrint from "react-to-print";
import moment from "moment";
import "./style.css";

function PrintModal(props) {
  const componentRef = useRef();

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        width={900}
        footer={null}
      >
        <div className="print-page">
          <div className="print-table">
            <div ref={componentRef}>
              <div className="thirdparty-print-invoice">
                <table className="print-invoice-table">
                  <tr>
                    <td colSpan={2}>
                      <img src={logo} width="" className="InvoiceLogo" />
                    </td>
                    <td colSpan={2}></td>
                    <td colSpan={2}>
                      <div>
                        {" "}
                        LOGIN ID:{props.item.user && props.item.user.name}
                      </div>
                      <div>
                        {" "}
                        SENDER NAME :{props.item.thirdPartySender.name}
                      </div>
                      <div>
                        DATE : {moment(props.item.createdAt).format("l")}
                      </div>
                      <div>
                        TOTAL PCS :{" "}
                        {props.item.boxes && props.item.boxes.length}
                      </div>
                      <div>
                        TOTAL WEIGHT :
                        {props.item.boxes.reduce((acc, curr) => {
                          return (Number(acc) + Number(curr.weight)).toFixed(1);
                        }, 0)}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={6}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <table className="print-invoice-table">
                        <tr>
                          <th className="print-invoice-table-td" colSpan={2}>
                            <div className="print-customsInvoice-txt5">
                              Collection ID
                            </div>
                          </th>

                          <th className="print-invoice-table-td" colSpan={2}>
                            <div className="print-customsInvoice-txt5">
                              Box Number
                            </div>
                          </th>
                          <th className="print-invoice-table-td" colSpan={2}>
                            <div className="print-customsInvoice-txt5">
                              Kilo
                            </div>
                          </th>
                          <th className="print-invoice-table-td" colSpan={2}>
                            <div className="print-customsInvoice-txt5">
                              Reference Number
                            </div>
                          </th>
                        </tr>

                        {props.item.boxes &&
                          props.item.boxes
                            .slice() // Create a copy of the array to avoid mutating the original data
                            .reverse() // R
                            .map((item) => {
                              return (
                                <tr>
                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={2}
                                  >
                                    <div className="print-customsInvoice-txt5">
                                      {item?.collection_id}
                                    </div>
                                  </td>

                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={2}
                                  >
                                    <div className="print-customsInvoice-txt5">
                                      {item?.box_number}
                                    </div>
                                  </td>

                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={2}
                                  >
                                    <div className="print-customsInvoice-txt5">
                                      {item?.weight}
                                    </div>
                                  </td>

                                  <td
                                    className="print-invoice-table-td"
                                    colSpan={2}
                                  >
                                    <div className="print-customsInvoice-txt5">
                                      {item?.box_ref_no}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col sm="6"></Col>
          <Col sm="3">
            <Button onClick={() => props.onCancel()} block size="large">
              Cancel
            </Button>
          </Col>
          <Col sm="3">
            <ReactToPrint
              trigger={() => (
                <Button type="primary" size="large" block>
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default PrintModal;
